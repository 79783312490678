import { ButtonBase } from '@material-ui/core';
import { MultipleSearchableInput } from 'components/Shared/MultipleSearchableInput';
import ScreenTitle from 'components/Shared/ScreenTitle';
import SearchableModal from 'components/Shared/SearchableModal';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { IonIcon } from 'components/UI/IonIcon';
import Layout from 'components/UI/Layout';
import TouchableInput from 'components/UI/TouchableInput';
import { getConfig } from 'config/config';
import { useLanguage } from 'languages/languageContext';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import KreiseSubscriber from 'scenes/KreisePosts/components/KreiseSubscriber';
import { useAppDispatch, useAppSelector } from 'store';
import { createKreise } from 'store/reducers/kreise';
import { searchRequest } from 'store/reducers/searchSlice';
import { debounce } from 'utilities';

const { theme } = getConfig();

export const CreateKreise: FC = () => {
  const { screenTitles, kreiseSettings: LkreiseSettings } = useLanguage();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { data, isLoading, searchKey, count } = useAppSelector((state) => state.search.name);
  const { user } = useAppSelector((state) => state.users);
  const [pageNumber, setPage] = useState(1);

  const getUsers = ({
    clear = false,
    searchkey,
    page,
  }: { clear?: boolean; searchkey?: string; page?: number } = {}) => {
    let finalSearchkey = searchkey !== undefined ? searchkey : input;
    console.log('🚀 ~ file: CreateKreise.tsx:32 ~ getUsers ~ finalSearchkey:', finalSearchkey);
    if (!finalSearchkey.trim().length) finalSearchkey = '';
    dispatch(searchRequest({ filter: 'name', page: page || pageNumber, clear, limit: 10, searchkey: finalSearchkey }));
    setPage((c) => c + 1);
  };

  const { searchModal } = useLanguage();

  const [state, setState] = useState({
    name: {
      value: '',
      isFocused: false,
    },
    description: {
      value: '',
      isFocused: false,
    },
    members: [] as { _id: string; name: string }[],
  });

  const [showMembersModal, setShowMembersModal] = useState(false);

  const [input, setInput] = useState('');

  useEffect(() => {
    getUsers();
    return () => {
      setPage(1);
    };
  }, []);

  useEffect(() => {
    if (!state.members.length && user._id) setState((c) => ({ ...c, members: [{ ...user, firstname: user.name }] }));
  }, [user]);

  const focusHandler = (name: string): void =>
    setState((c) => ({ ...c, [name]: { ...c[name], isFocused: !c[name].isFocused } }));

  const addMember: (value: any, IName?: string) => void = (value) => {
    setState((c) => ({ ...c, members: [...c.members, value] }));
  };

  const removeMember: (value: any) => void = (value) => {
    setState((c) => ({
      ...c,
      members: c.members.filter((el) => el._id !== value._id),
    }));
  };

  const createKreiseHandler = async () => {
    const kreise = {
      name: state.name.value,
      description: state.description.value,
      members: state.members.map((item) => item._id),
    };
    const { payload } = await dispatch(createKreise({ kreise }));
    // @ts-ignore
    history.push(`/kreise-posts/${payload._id}`);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setInput(value);
    setPage(1);
    onChangeDebounce(value);
  };

  const onChangeDebounce = useCallback(
    debounce((value: string) => {
      getUsers({ searchkey: value, clear: true, page: 1 });
    }, 500),
    [],
  );

  return (
    <>
      <Layout>
        <ScreenTitle title={screenTitles.createKreiseTitle} />
        <div style={{ padding: 20 }}>
          <Input
            placeholder={LkreiseSettings.addKreiseName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setState((c) => ({ ...c, name: { ...c.name, value: e.target.value } }))
            }
            isFocused={state.name.isFocused}
            onBlur={() => focusHandler('name')}
            onFocus={() => focusHandler('name')}
            value={state.name.value}
          />
          <Input
            placeholder={LkreiseSettings.addKreiseDescription}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setState((c) => ({
                ...c,
                description: {
                  ...c.description,
                  value: e.target.value,
                },
              }))
            }
            isFocused={state.description.isFocused}
            onBlur={() => focusHandler('description')}
            onFocus={() => focusHandler('description')}
            value={state.description.value}
          />
          <TouchableInput placeholder={LkreiseSettings.addMembers} onClick={() => setShowMembersModal(true)} />
          {state.members.map((item: any, idx) => (
            <div key={item._id + idx} style={{ display: 'flex', flexDirection: 'row' }}>
              <KreiseSubscriber subscriber={{ ...item, name: item.firstname }} openInNewWindow={true} />
              {user._id !== item._id && (
                <ButtonBase onClick={() => removeMember(item)}>
                  <IonIcon name="trash-outline" size={32} color={theme.ERROR_PRIMARY} />
                </ButtonBase>
              )}
            </div>
          ))}
          <div style={{ height: 20 }} />
          <Button
            onClick={createKreiseHandler}
            label={LkreiseSettings.createKreise}
            disabled={state.name.value.trim().length}
          />
        </div>
      </Layout>
      <SearchableModal
        onClick={() => setShowMembersModal(false)}
        modalTitle={searchModal.pleaseSelect}
        setValueHandler={() => setShowMembersModal(false)}
        modalOpen={showMembersModal}
        value={input}
      >
        <MultipleSearchableInput
          data={data.filter((el) => el._id !== user._id)}
          //@ts-ignore
          values={state.members}
          value={input}
          onChange={onChangeInput}
          onSelect={addMember}
          deleteItemHandler={removeMember}
          currentName=""
          paginated={true}
          loading={false}
          loadMore={getUsers}
          hasMore={data.length < count && !isLoading}
          renderItem={(item) => (
            <KreiseSubscriber subscriber={{ ...item, name: item.firstname }} openInNewWindow={true} />
          )}
        />
      </SearchableModal>
    </>
  );
};
