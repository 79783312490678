import React, { FC, useEffect, useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { getUserContactsRequest, clearUserContacts } from '../../store/actions/contacts';
import Layout from '../../components/UI/Layout';
import ScreenTitle from '../../components/Shared/ScreenTitle';
import UserItem from './components/UserItem';
import InfiniteScroll from 'react-infinite-scroller';
import EmptyList from '../../components/Shared/EmptyList';
import SearchBar from '../../components/Shared/SearchBar';
import { useLanguage } from 'languages/languageContext';
import { useAppDispatch, useAppSelector } from 'store';
import { getConfig } from 'config/config';
import { useHistory } from 'react-router';

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: '30px 0 30px 0',
    overflow: 'auto',
  },
  title: {
    textAlign: 'center',
  },
  inputWrapper: {
    padding: '10px 20px 0',
  },
});

const Contacts: FC = () => {
  const dispatch = useAppDispatch();
  const { contacts, contactsIsLoading, contactsCount } = useAppSelector((state) => state.contacts);
  const { screenTitles } = useLanguage();
  const classes = useStyles({});
  const history = useHistory();
  const [state, setState] = useState({
    query: '',
    pageNumber: 1,
  });

  useEffect(() => {
    if (history.action === 'PUSH') {
      dispatch(clearUserContacts());
    }
  }, []);

  const getMoreUsersHandler = () => {
    const { pageNumber, query } = state;
    if (contactsIsLoading) return;
    if (contacts?.length === contactsCount) return;

    const userId = localStorage.getItem('userId');

    setState((c) => ({
      ...c,
      pageNumber: c.pageNumber + 1,
    }));

    if (userId) {
      dispatch(getUserContactsRequest(false, userId, pageNumber, 20, query));
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const userId = localStorage.getItem('userId');

    if (value.trim().length === 0) {
      clearSearchHandler();
    } else {
      setState({ pageNumber: 2, query: value });

      if (userId) {
        dispatch(getUserContactsRequest(true, userId, 1, 20, value));
      }
    }
  };

  const clearSearchHandler = () => {
    const userId = localStorage.getItem('userId');
    dispatch(clearUserContacts());
    setState({
      query: '',
      pageNumber: 2,
    });

    if (userId) {
      dispatch(getUserContactsRequest(true, userId, 1, 20, ''));
    }
  };

  const mappedItems = (
    <div>
      {contacts?.length > 0
        ? contacts.map((item, i) => <UserItem user={item} key={i} />)
        : !contactsIsLoading && (
            <EmptyList title="Hier sind noch keine Einträge. Gehe über das Hauptmenü auf “Suche” → “Name”, um neue Kontakte zu finden. " />
          )}
    </div>
  );

  return (
    <Layout>
      <ScreenTitle title={screenTitles.contactsTitle} />
      <div className={classes.container}>
        <div className={classes.inputWrapper}>
          <SearchBar query={state.query} onChange={onChangeHandler} clearSearch={clearSearchHandler} />
        </div>
        <InfiniteScroll
          threshold={50}
          initialLoad={true}
          pageStart={1}
          loadMore={getMoreUsersHandler}
          // hasMore={!(contacts.length >= contactsCount || query)}
          // this must work, but would be better if 'total' will be fixed on backend :)
          hasMore={contactsCount === null ? true : contacts.length % 20 === 0}
          useWindow={true}
        >
          {mappedItems}
          {contactsIsLoading && (
            <div style={{ textAlign: 'center', padding: '10px 0' }} key={52}>
              <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
            </div>
          )}
        </InfiniteScroll>
      </div>
    </Layout>
  );
};

export default Contacts;
