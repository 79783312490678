import React, { useEffect } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';

import { useAppDispatch } from 'store';
import { putAdsRequest } from 'store/actions/advertisement';
import { withConfig, Theme } from 'config/';
import { postImagePath } from 'utilities/';
import { useLanguage } from 'languages/languageContext';

const styles = createStyles({
  link: {
    display: 'block',
    outline: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  container: {
    width: '100%',
    borderRadius: 10,
    marginBottom: 10,
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  titleWrapper: {
    borderRadius: '10px 0 10px 0',
    padding: '3px 15px',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 10,
  },
  addWrapper: {
    padding: '5px 0 10px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    maxWidth: 400,
  },
  '@media(max-width: 650px)': {
    img: {
      maxWidth: 300,
    },
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  item: any;
  putAdsRequest: (adsId: string, activity: string) => void;
}

const AddBlock: React.FC<Props> = ({ theme, classes, item }) => {
  const dispatch = useAppDispatch();
  const { post } = useLanguage();
  let image;
  if (item && item.photo) {
    image = postImagePath('full', item.photo);
  }

  const onAdsClicked = () => {
    dispatch(putAdsRequest(item._id, 'click'));
  };

  useEffect(() => {
    if (item && item._id) {
      dispatch(putAdsRequest(item._id, 'display'));
    }
  }, []);

  return item ? (
    <a className={classes.link} href={item.url} target="_blank" onClick={onAdsClicked}>
      <div className={classes.container} style={{ background: theme.BACKGROUND }}>
        <div className={classes.wrapper}>
          <div className={classes.titleWrapper} style={{ background: theme.ADD_BACKGROUND }}>
            <span className={classes.title} style={{ color: theme.BACKGROUND }}>
              {post.adTitle}
            </span>
          </div>
        </div>
        <div className={classes.addWrapper}>
          <img src={image} className={classes.img} alt="Ads..." />
        </div>
      </div>
    </a>
  ) : null;
};

export default withStyles(styles)(withConfig(AddBlock));
