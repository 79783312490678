import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signOutRequest } from 'store/actions/auth';
import { clearChatUsers, clearUnreadMessages, getChatUsersRequest } from 'store/actions/chat';
import { clearUserContacts } from 'store/actions/contacts';
import { clearUserRequests } from 'store/actions/requests';
import { clearUserRequest } from 'store/actions/users';
import { clearKreise, clearUserKreise } from 'store/reducers/kreise';
import { clearAds } from 'store/reducers/news';

export const withAuth: any = (Component) => (props) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  if (token) {
    return <Component {...props} />;
  } else if (!token) {
    dispatch(clearChatUsers());
    dispatch(getChatUsersRequest(false));
    dispatch(clearUnreadMessages());
    dispatch(signOutRequest());
    dispatch(clearUserRequest());
    dispatch(clearKreise());
    dispatch(clearUserContacts());
    dispatch(clearUserRequests());
    dispatch(clearUserKreise());
    dispatch(clearAds());

    return <Redirect to="/sign-up" />;
  }
};
