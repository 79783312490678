import React from 'react';
import { setConfig } from './setConfig';

export const withConfig = (Component: React.ElementType) => (props: any) => {
  const { theme, config } = setConfig();

  return <Component {...props} theme={theme} config={config} />;
};

export const getConfig = () => {
  const { theme, config } = setConfig();
  return { theme, config };
};
