import {
  CLEAR_BIRTHDAY_EVENTS,
  GET_BIRTHDAY_EVENTS_REQUEST,
  GET_BIRTHDAY_EVENTS_SUCCESS,
  GET_BIRTHDAY_EVENTS_FAILURE,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_MY_EVENTS_REQUEST,
  GET_MY_EVENTS_SUCCESS,
  GET_MY_EVENTS_FAILURE,
  GET_CORPS_EVENTS_REQUEST,
  GET_CORPS_EVENTS_SUCCESS,
  GET_CORPS_EVENTS_FAILURE,
  CLEAR_EVENTS,
  GET_EVENT_BY_ID_REQUEST,
  GET_EVENT_BY_ID_SUCCESS,
  GET_EVENT_BY_ID_FAILURE,
  CLEAR_CURRENT_EVENT,
  GET_EVENT_MEMBERS_REQUEST,
  GET_EVENT_MEMBERS_SUCCESS,
  GET_EVENT_MEMBERS_FAILURE,
  CLEAR_EVENT_MEMBERS,
  ADD_EVENT_MEMBER_REQUEST,
  ADD_EVENT_MEMBER_SUCCESS,
  ADD_EVENT_MEMBER_FAILURE,
  REMOVE_EVENT_MEMBER_REQUEST,
  REMOVE_EVENT_MEMBER_SUCCESS,
  REMOVE_EVENT_MEMBER_FAILURE,
  GET_AUTOCOMPLETE_EVENTS_REQUEST,
  GET_AUTOCOMPLETE_EVENTS_SUCCESS,
  GET_AUTOCOMPLETE_EVENTS_FAILURE,
  GET_CORPS_CONTACTS_REQUEST,
  GET_CORPS_CONTACTS_SUCCESS,
  GET_CORPS_CONTACTS_FAILURE,
  CLEAR_CORPS_CONTACTS,
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  REMOVE_EVENT_REQUEST,
  REMOVE_EVENT_SUCCESS,
  REMOVE_EVENT_FAILURE,
} from '../../constants/';

const initialState = {
  eventMembersIsLoading: false,
  getEventsIsLoading: false,
  getBirthdayIsLoading: false,
  addMemberIsLoading: false,
  eventIsLoading: false,
  eventSubsIsLoading: false,
  eventSubs: [],
  currentEvent: null,
  events: [],
  eventList: [],
  birthdayEvents: [],
  eventMembers: [],
  eventMembersTotal: 0,
  corpsContacts: [],
  corpsContactsIsLoading: false,
};

export const events = (state = initialState, action) => {
  switch (action.type) {
    // GET BIRTHDAY EVENTS
    case CLEAR_BIRTHDAY_EVENTS:
      return {
        ...state,
        birthdayEvents: [],
      };
    case GET_BIRTHDAY_EVENTS_REQUEST:
      return {
        ...state,
        // separating logic for general event loading and birthday
        getBirthdayIsLoading: true,
        getEventsIsLoading: true,
      };
    case GET_BIRTHDAY_EVENTS_SUCCESS:
      return {
        ...state,
        getBirthdayIsLoading: false,
        getEventsIsLoading: false,
        birthdayEvents: action.events,
      };
    case GET_BIRTHDAY_EVENTS_FAILURE:
      return {
        ...state,
        getBirthdayIsLoading: false,
        getEventsIsLoading: false,
      };

    // GET ALL EVENTS
    case GET_EVENTS_REQUEST:
      return {
        ...state,
        getEventsIsLoading: true,
      };
    case GET_EVENTS_SUCCESS:
      const type = action.name;
      return {
        ...state,
        getEventsIsLoading: false,
        [type]: action.events,
      };
    case GET_EVENTS_FAILURE:
      return {
        ...state,
        getEventsIsLoading: false,
      };
    // GET MY EVENTS
    case GET_MY_EVENTS_REQUEST:
      return {
        ...state,
        events: [],
        getEventsIsLoading: true,
      };
    case GET_MY_EVENTS_SUCCESS:
      return {
        ...state,
        getEventsIsLoading: false,
        events: action.events,
      };
    case GET_MY_EVENTS_FAILURE:
      return {
        ...state,
        getEventsIsLoading: false,
        error: action.error,
      };
    // GET CORPS EVENTS
    case GET_CORPS_EVENTS_REQUEST:
      return {
        ...state,
        events: [],
        getEventsIsLoading: true,
      };
    case GET_CORPS_EVENTS_SUCCESS:
      return {
        ...state,
        getEventsIsLoading: false,
        events: action.events,
      };
    case GET_CORPS_EVENTS_FAILURE:
      return {
        ...state,
        getEventsIsLoading: false,
        error: action.error,
      };
    case CLEAR_EVENTS:
      return {
        ...state,
        events: [],
      };
    // GET EVENT BY ID
    case GET_EVENT_BY_ID_REQUEST:
      return {
        ...state,
        eventIsLoading: true,
      };
    case GET_EVENT_BY_ID_SUCCESS:
      return {
        ...state,
        eventIsLoading: false,
        currentEvent: action.event,
      };
    case GET_EVENT_BY_ID_FAILURE:
      return {
        ...state,
        eventIsLoading: false,
      };
    case CLEAR_CURRENT_EVENT:
      return {
        ...state,
        currentEvent: null,
      };
    // GET EVENT MEMBERS
    case GET_EVENT_MEMBERS_REQUEST:
      return {
        ...state,
        eventMembersIsLoading: true,
      };
    case GET_EVENT_MEMBERS_SUCCESS:
      return {
        ...state,
        eventMembersIsLoading: false,
        eventMembers: state.eventMembers.concat(action.eventMembers),
        eventMembersTotal: action.total,
      };
    case GET_EVENT_MEMBERS_FAILURE:
      return {
        ...state,
        eventMembersIsLoading: false,
      };
    case CLEAR_EVENT_MEMBERS:
      return {
        ...state,
        eventMembers: [],
        eventMembersTotal: 0,
      };
    // ADD MEMBER
    case ADD_EVENT_MEMBER_REQUEST:
      return {
        ...state,
        addMemberIsLoading: true,
      };
    case ADD_EVENT_MEMBER_SUCCESS:
      return {
        ...state,
        addMemberIsLoading: false,
      };
    case ADD_EVENT_MEMBER_FAILURE:
      return {
        ...state,
        addMemberIsLoading: false,
      };
    // REMOVE MEMBER
    case REMOVE_EVENT_MEMBER_REQUEST:
      return {
        ...state,
        addMemberIsLoading: true,
      };
    case REMOVE_EVENT_MEMBER_SUCCESS:
      return {
        ...state,
        addMemberIsLoading: false,
      };
    case REMOVE_EVENT_MEMBER_FAILURE:
      return {
        ...state,
        addMemberIsLoading: false,
      };
    // GET EVENT SUBS
    case GET_AUTOCOMPLETE_EVENTS_REQUEST:
      return {
        ...state,
        eventSubsIsLoading: true,
      };
    case GET_AUTOCOMPLETE_EVENTS_SUCCESS:
      return {
        ...state,
        eventSubsIsLoading: false,
        eventSubs: action.autocompletes,
      };
    case GET_AUTOCOMPLETE_EVENTS_FAILURE:
      return {
        ...state,
        eventSubsIsLoading: false,
      };
    // GET CORPS CONTACTS
    case GET_CORPS_CONTACTS_REQUEST:
      return {
        ...state,
        corpsContactsIsLoading: true,
      };
    case GET_CORPS_CONTACTS_SUCCESS:
      return {
        ...state,
        corpsContactsIsLoading: false,
        corpsContacts: action.contacts,
      };
    case GET_CORPS_CONTACTS_FAILURE:
      return {
        ...state,
        corpsContactsIsLoading: false,
      };
    case CLEAR_CORPS_CONTACTS:
      return {
        ...state,
        corpsContacts: [],
      };
    // ADD EVENT
    case ADD_EVENT_REQUEST:
      return {
        ...state,
        eventIsLoading: true,
      };
    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        eventIsLoading: false,
      };
    case ADD_EVENT_FAILURE:
      return {
        ...state,
        eventIsLoading: false,
      };
    // UPDATE EVENT
    case UPDATE_EVENT_REQUEST:
      return {
        ...state,
        eventIsLoading: true,
      };
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        eventIsLoading: false,
      };
    case UPDATE_EVENT_FAILURE:
      return {
        ...state,
        eventIsLoading: false,
      };
    // REMOVE EVENT
    case REMOVE_EVENT_REQUEST:
      return {
        ...state,
        eventIsLoading: true,
      };
    case REMOVE_EVENT_SUCCESS:
      return {
        ...state,
        eventIsLoading: false,
        events: state.events.filter((el) => el._id !== action.eventId),
      };
    case REMOVE_EVENT_FAILURE:
      return {
        ...state,
        eventIsLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
