import { Box } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { mentoringGetInfoPage } from '../../api/mentoring';
import EmptyList from '../../components/Shared/EmptyList';
import Loader from '../../components/UI/Loader';
import { getConfig } from '../../config/config';

export const MentoringInfoScreen: FC = () => {
  const [info, setInfo] = useState({} as any);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    setLoading(true);
    const link = await mentoringGetInfoPage();
    setInfo(link);
    setLoading(false);
  };

  return (
    <>
      {!info?.content?.length && !loading && (
        <Box style={styles.container}>
          <EmptyList />
        </Box>
      )}
      {info?.content?.length && (
        <Box style={styles.container}>
          <div dangerouslySetInnerHTML={{ __html: info.content }} />
        </Box>
      )}
      <Loader isLoading={loading} />
    </>
  );
};

const { theme } = getConfig();
const styles = {
  container: {
    flex: 1,
    marginHorizontal: 2,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: theme.BACKGROUND,
    padding: '30px 70px',
  },
};
