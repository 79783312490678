import React from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { SignUpProps, SignUpState } from './model';
import { withConfig } from '../../config/';
import { connect } from 'react-redux';
import { signUpRequest } from '../../store/actions/auth';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import Logo from '../../components/UI/Logo';
import Button from '../../components/UI/Button';
import Heading from '../../components/Shared/Heading';
import BackToLogin from '../../components/Shared/BackToLogin';
import Loader from '../../components/UI/Loader';
import { withLanguage } from 'languages';
import Conditions from 'scenes/SignIn/components/Conditions';
import LanguageSwitch from 'components/UI/LanguageSwitch';
import { RootState } from 'store';
import { CircularProgress } from '@material-ui/core';
import { getConfig } from 'config/config';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 0 70px 0',
  },
  wrapper: {
    width: 320,
  },
  buttonWrapper: {
    width: '100%',
    marginBottom: 50,
  },
  contentWrapper: {
    textAlign: 'center',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, SignUpProps {
  common: RootState['common'];
}

class ResetPassword extends React.Component<Props, SignUpState> {
  state = {
    checked: false,
    isValid: false,
    invitationCode: '',
    isFocused: false,
  };

  render() {
    const { classes, signedUp, authIsLoading, language, common, history } = this.props;
    const {
      disable_code_registration: disableCodeRegistration,
      loading: commonIsLoading,
      disable_guest_function,
    } = common;
    const { signUpDefault } = language;
    return signedUp ? (
      <Redirect
        push
        to={{
          pathname: '/profile/basis',
          state: { signedUp: true },
        }}
      />
    ) : (
      <React.Fragment>
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <LanguageSwitch offlineMode />
            <Logo />
            <Heading title={language.signUpDefault.createAccount} />
            {!commonIsLoading ? (
              <>
                <div className={classes.buttonWrapper}>
                  {!disableCodeRegistration && (
                    <Button
                      label={
                        !disable_guest_function && !disableCodeRegistration
                          ? language.signUpDefault.withCodeWithDescr
                          : language.signUpDefault.withCode
                      }
                      description={!disable_guest_function && !disableCodeRegistration && signUpDefault.withCode}
                      onClick={() => history.push('/sign-up-screen?withCode=true')}
                    />
                  )}
                  <Button
                    label={
                      !disable_guest_function && !disableCodeRegistration
                        ? language.signUpDefault.withCodeWithDescr
                        : disableCodeRegistration
                        ? language.signUpDefault.withCodeWithDescr
                        : language.signUpDefault.simpleRegister
                    }
                    onClick={() => history.push('/sign-up-screen')}
                    description={!disable_guest_function && !disableCodeRegistration && signUpDefault.withoutCode}
                  />
                  {!disable_guest_function && (
                    <Button
                      label={language.signUpDefault.guest}
                      onClick={() => history.push('/sign-up-screen?isGuest=true')}
                    />
                  )}
                </div>
                <BackToLogin
                  label={language.signUpDefault.existingAccount}
                  buttonText="Login"
                  onClick={() => history.push('/sign-in')}
                  type="button"
                />
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 230,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={35} />
              </div>
            )}
            <div className={classes.contentWrapper}></div>
            <Conditions language={language} type="info&email" />
          </div>
        </div>
        <Loader showLoader={authIsLoading} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  authIsLoading: state.auth.authIsLoading,
  signedUp: state.auth.signedUp,
  common: state.common,
});

const mapDispatchToProps = {
  signUpRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withLanguage(withConfig(ResetPassword))));
