import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { withConfig } from '../../config/';
import { ButtonBase } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import nextId from 'react-id-generator';

import Corps from './components/Corps';
import Button from '../../components/UI/Button';
import AlertModal from '../../components/Shared/AlertModal';
import ProfileScale from '../../components/UI/ProfileScale';
import Loader from '../../components/UI/Loader';
import { debounce } from '../../utilities';
import { withLanguage } from 'languages';
import { translationsType } from 'languages/languageContext';
import { RootState } from 'store';
import { getConfig } from 'config/config';
import { updateUserRequest } from 'store/actions/users';
import { showResponse } from 'store/actions/response';
import { getCorpsNamesRequest } from 'store/actions/corps';

const { theme } = getConfig();

const styles = createStyles({
  container: {
    width: 420,
    boxSizing: 'border-box',
  },
  addButtonWrapper: {
    position: 'relative',
    width: '100%',
    borderTop: '1px solid',
    height: 10,
    paddingBottom: 20,
  },
  addButtonContainer: {
    position: 'absolute',
    top: -20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  updateButtonWraper: {
    marginTop: 20,
  },
  progressWrapper: {
    width: '100%',
    padding: '100px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      padding: '0 10px',
    },
  },
});

export interface CorpsModel {
  _id?: string;
  corpsname?: string;
  charge: string[];
  // rezeptionsdatum: object
  rezeptionsdatum: string;
  status: string;
  logincode?: string;
  verified?: number;
  createRequest?: boolean;
  type: 'create' | 'requestNew' | 'requestExisting' | 'exist';
  allowUnsubscribe?: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends WithStyles<typeof styles>, ReduxProps {
  language: translationsType;
}

class ProfileCorps extends React.Component<Props> {
  state = {
    corpsList: [] as CorpsModel[],
    duplicateCorpsModal: false,
    missingCorpsModal: false,
    duplicatedCorps: [],
    newCorpModal: false,
  };

  componentDidMount() {
    this.initCorpsHandler();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (prevProps.user !== user) {
      this.initCorpsHandler();
    }
  }

  initCorpsHandler = () => {
    const { user } = this.props;
    const currentCorps = localStorage.getItem('currentCorps');

    if (user && user.name) {
      this.setState({
        corpsList: user.corp,
      });
    } else if (currentCorps) {
      this.setState({
        corpsList: [
          {
            corpsname: currentCorps,
            charge: [],
            status: '',
            rezeptionsdatum: '',
            verified: 1,
          },
        ],
      });
    }
  };

  getCorpsHandler = (value?: string | undefined) => {
    const { getCorpsNamesRequest } = this.props;
    getCorpsNamesRequest(value || '');
  };
  getCorpsNamesDebounced = debounce(this.getCorpsHandler, 700);

  // FILTER STATE ARRAY
  updateStateHandler = (value: string, name: string, id: string) => {
    const { corpsList } = this.state;

    const updatedState = corpsList.map((corp: any) => {
      let newCorp = corp;

      if (id === corp._id) {
        newCorp = {
          ...corp,
          [name]: value,
        };
      }

      return newCorp;
    });

    this.setState({
      corpsList: updatedState,
    });
  };

  addCorpsHandler = () => {
    const { users_can_create_new_corps, users_can_request_new_corps } = this.props;

    const shouldShowModal = users_can_create_new_corps || users_can_request_new_corps;

    if (shouldShowModal) this.setState({ newCorpModal: true });
    else this.createCorpsHandler('requestExisting');
  };

  // ADD CORPS ITEM
  createCorpsHandler = (type: CorpsModel['type']) => {
    const { user } = this.props;
    const { corpsList } = this.state;
    const updatedCorpsList: CorpsModel[] = [...corpsList];

    if (user && user.name) {
      const newCorps: CorpsModel = {
        _id: nextId(),
        corpsname: '',
        charge: [],
        status: '',
        rezeptionsdatum: '',
        logincode: '',
        createRequest: true,
        type,
      };

      updatedCorpsList.push(newCorps);

      this.setState({
        corpsList: updatedCorpsList,
        newCorpModal: false,
      });
    }
  };

  // REMOVE CORPS HANDLER
  removeCorpsHandler = (id: string) => {
    const { user, updateUserRequest } = this.props;
    const { corpsList } = this.state;

    const updateUser = user.corp.filter((corps: any) => corps._id === id);
    const updatedCorps: Array<any> = corpsList.filter((corps: any) => corps._id !== id);

    this.setState({
      corpsList: updatedCorps,
    });

    if (updateUser.length > 0) {
      updateUserRequest({
        ...user,
        corp: updatedCorps,
        password: '',
      });
    }
  };

  // CHECK EXISTING CORPS
  checkCorps = () => {
    const { corpsList } = this.state;

    // DUPLICATE CORPS CHECK
    const corpsNamesArr: Array<any> = corpsList.map((corp) => {
      if (corp.corpsname.trim().length > 0) {
        return corp.corpsname;
      }
    });

    const unique = (value, index, self) => {
      if (value) {
        return self.indexOf(value) !== index;
      }
    };

    const duplicateCorpsNames = corpsNamesArr.filter(unique);
    this.setState({ duplicatedCorps: [...duplicateCorpsNames] });

    // CHECK CORPS NAMES
    const missingCorpsNames = corpsList.some((corp) => !corp.corpsname?.length);

    return {
      duplicateCorpsNames: duplicateCorpsNames.length > 0,
      missingCorpsNames: missingCorpsNames,
    };
  };

  // UPDATE CORPS FUNCTION
  updateProfileCorps = () => {
    const { user, updateUserRequest, showResponse } = this.props;
    const { corpsList } = this.state;
    const { duplicateCorpsNames, missingCorpsNames } = this.checkCorps();

    const updatedCorps = corpsList.map((corp) => {
      let filteredCorp = corp;

      if (corp.logincode) {
        filteredCorp = {
          corpsname: corp.corpsname,
          charge: corp.charge,
          status: corp.status,
          rezeptionsdatum: corp.rezeptionsdatum,
          logincode: corp.logincode,
          type: corp.type,
        };
      }
      return filteredCorp;
    });

    if (duplicateCorpsNames) {
      this.setState({ duplicateCorpsModal: true });
    } else if (missingCorpsNames) {
      this.setState({ missingCorpsModal: true });
    }

    if (!duplicateCorpsNames && !missingCorpsNames) {
      if (user && user.name) {
        updateUserRequest({
          ...user,
          corp: updatedCorps,
          password: '',
        });
      } else if (user && !user.name) {
        showResponse({
          message: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
        });
      }
    }
  };

  render(): JSX.Element {
    const { corpsNames, userIsLoading, classes, language, users_can_create_new_corps, users_can_request_new_corps } =
      this.props;
    const duplicatedCorpsMessage = `Das ${this.state.duplicatedCorps} ist bereits im Profil vorhanden, bitte einmal überprüfen.`;
    const missingCorpsNamesMessage = `Bitte den Namen des Corps angeben.`;

    return (
      <React.Fragment>
        <div className={classes.container}>
          <ProfileScale />
          <div>
            {this.state.corpsList.length > 0 &&
              this.state.corpsList.map((corp, i) => {
                const lastElem = i + 1 === this.state.corpsList.length;
                if (corp.charge && typeof corp.charge === 'string') corp.charge = [corp.charge];
                return (
                  <Corps
                    corpsNames={corpsNames}
                    corps={corp}
                    lastElem={lastElem}
                    elemIndex={i}
                    key={i}
                    // METHODS
                    getCorpsNamesDebounced={this.getCorpsNamesDebounced}
                    corpsStateHandler={this.updateStateHandler}
                    addCorpsHandler={this.addCorpsHandler}
                    removeCorpsHandler={this.removeCorpsHandler}
                  />
                );
              })}
            <div>
              <div className={classes.addButtonWrapper} style={{ borderTopColor: theme.BACKGROUND_SECONDARY }}>
                <div className={classes.addButtonContainer}>
                  <ButtonBase
                    style={{ borderRadius: 50, fontSize: 40, background: theme.BACKGROUND }}
                    onClick={this.addCorpsHandler}
                  >
                    <AddCircle style={{ color: theme.BUTTON_PRIMARY }} fontSize="inherit" />
                  </ButtonBase>
                </div>
              </div>
              <div className={classes.updateButtonWraper}>
                <Button label={language.btn.saveBtn} onClick={this.updateProfileCorps} />
              </div>
            </div>
          </div>
        </div>
        <AlertModal message={duplicatedCorpsMessage} showModal={this.state.duplicateCorpsModal}>
          <Button label="OK" onClick={() => this.setState({ duplicateCorpsModal: false })} />
        </AlertModal>
        <AlertModal message={missingCorpsNamesMessage} showModal={this.state.missingCorpsModal}>
          <Button label="OK" onClick={() => this.setState({ missingCorpsModal: false })} />
        </AlertModal>
        <AlertModal
          message={language.profileClubs.selectVariant}
          showModal={this.state.newCorpModal}
          showCloseBtn
          onClose={() => this.setState({ newCorpModal: false })}
        >
          <div>
            <Button
              width={250}
              label={language.profileClubs.requestExistingCorp}
              onClick={() => this.createCorpsHandler('requestExisting')}
            />

            {users_can_request_new_corps && (
              <Button
                width={250}
                label={language.profileClubs.requestNewCorps}
                onClick={() => this.createCorpsHandler('requestNew')}
              />
            )}
            {users_can_create_new_corps && (
              <Button
                width={250}
                label={language.profileClubs.createCorp}
                onClick={() => this.createCorpsHandler('create')}
              />
            )}
          </div>
        </AlertModal>
        <Loader showLoader={userIsLoading} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ corps, users, common }: RootState) => ({
  corpsNames: corps.corpsNames,
  user: users.user,
  userIsLoading: users.userIsLoading,
  users_can_request_new_corps: common.users_can_request_new_corps,
  users_can_create_new_corps: common.users_can_create_new_corps,
});

const mapDispatchToProps = {
  updateUserRequest,
  showResponse,
  getCorpsNamesRequest,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withStyles(styles)(withLanguage(withConfig(ProfileCorps))));
