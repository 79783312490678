import React, { FC } from 'react';
import { getConfig } from 'config/config';
import { CircularProgress } from '@material-ui/core';

type Props = { progress: number; loading: boolean };

const { theme } = getConfig();

export const ProgressLoader: FC<Props> = ({ progress = 0, loading = false }) => {
  if (!loading) return <></>;

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        style={{
          height: 36,
          width: 36,
          borderRadius: 36,
          backgroundColor: '#fff',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <span
          style={{
            position: 'absolute',
            fontWeight: 'bolder',
            color: theme.BUTTON_ICON,
            fontSize: 9,
          }}
        >
          {progress?.toFixed()}%
        </span>
        <CircularProgress style={{ color: theme.BUTTON_ICON }} size={34} />
      </div>
    </div>
  );
};
