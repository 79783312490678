import { IonIcon } from 'components/UI/IonIcon';
import { getConfig } from 'config/config';
import React, { FC } from 'react';

export const InfoSettingsIcon: FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 39,
        height: 40,
      }}
    >
      <IonIcon name="settings-outline" size={38} color={'#2B8FBB'} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 17,
          height: 17,
          borderRadius: 17,
          borderWidth: 2,
          borderColor: '#2B8FBB',
          backgroundColor: theme.BACKGROUND_SECONDARY,
          position: 'absolute',
        }}
      >
        <div
          style={{
            display: 'flex',
            backgroundColor: '#5FB4E660',
            borderRadius: 19,
            justifyContent: 'center',
            width: 17,
            height: 17,
            alignItems: 'center',
          }}
        >
          <IonIcon name="information-outline" size={15} color={'#2B8FBB'} />
        </div>
      </div>
    </div>
  );
};

const { theme } = getConfig();
