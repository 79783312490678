import { editCorps, getCorps } from 'api/corps';
import { UploadMedia } from 'components/Shared/UploadMedia';
import Loader from 'components/UI/Loader';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store';
import { mediaActions, trimMedia } from 'store/reducers/mediaSlice';
import { CorpType } from 'store/types/newsTypes';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { getConfig } from 'config/config';
import KreiseSubscriber from 'scenes/KreisePosts/components/KreiseSubscriber';
import { IonIcon } from 'components/UI/IonIcon';
import EditIcon from '@material-ui/icons/Edit';
import PostGallery from 'components/Shared/PostGallery';
import { RenderStateAttachments } from 'scenes/DialogPanel/components/RenderStateAttachments';
import toast from 'react-hot-toast';
import htmlToDraft from 'html-to-draftjs';
import { useLanguage } from 'languages/languageContext';
import { fileType } from 'store/reducers/attachments';

const { theme } = getConfig();

export const CorpsScreen: FC<{ groupId: string }> = ({ groupId }) => {
  const styles = useStyles({});

  const params = useParams<{ id: string }>();
  const corpId = groupId || params?.id;

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const [corps, setCorps] = useState<CorpType>();
  const [editMode, setEditMode] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { images } = useAppSelector((state) => state.media);

  const user = useAppSelector((state) => state.users.user);
  const isAdmin = !!corps?.admins?.find((el) => el.user._id === user._id);

  const { messages } = useLanguage();

  useEffect(() => {
    fetchCorps();
    return () => {
      dispatch(mediaActions.deleteAllAttachments());
    };
  }, []);

  useEffect(() => {
    if (corps?.corpsname) {
      if (corps.description) {
        const contentBlock = htmlToDraft(corps.description);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
      dispatch(
        mediaActions.setEditingAttachments({
          // @ts-ignore
          images: corps.photo.map((el: string | fileType) =>
            typeof el === 'object'
              ? el
              : {
                  url: el,
                  id: el,
                },
          ),
        }),
      );
    }
  }, [corps?.corpsname]);

  useEffect(() => {
    if (editMode && images?.every((el) => el.url)) uploadImages();
  }, [images.every((el) => el.url), images.length]);

  const fetchCorps = async () => {
    setLoading(true);
    const res = await getCorps(corpId);

    setCorps(res);
    setLoading(false);
  };

  const toEditMode = () => {
    if (!isAdmin) return;
    if (editMode) {
      uploadImages();
      setEditMode(false);
      return;
    }
    setEditMode(true);
  };

  const saveDescription = async () => {
    setLoading(true);
    const { description } = await editCorps(groupId, { description: getMarkup() });
    setCorps({ ...corps, description });
    setLoading(false);
    toast.success(messages.changesSaved);
  };

  const uploadImages = async () => {
    const { images: trimmed } = trimMedia({ images });
    const { photo } = await editCorps(groupId, { photo: trimmed });
    setCorps({ ...corps, photo });
    toast.success(messages.changesSaved);
  };

  const deleteImage = async (type: 'images', id: number) => {
    dispatch(mediaActions.deleteAttachment({ type, id }));
  };

  const onEditorStateChange = (editorState) => setEditorState(editorState);

  const getMarkup = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    return markup;
  };

  const EditBtn = () =>
    isAdmin &&
    (corps?.allowMembersChangePhoto || corps?.allowMembersEditDescription) && (
      <ButtonBase
        onClick={toEditMode}
        style={{
          color: theme.ACTIVE_INPUT,
          height: 30,
          width: 30,
          borderRadius: 30,
        }}
      >
        {!editMode ? (
          <div
            style={{
              color: theme.BACKGROUND_PRIMARY,
              backgroundColor: theme.ACTIVE_INPUT,
              width: 30,
              height: 30,
              borderRadius: 30,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <EditIcon color="inherit" />
          </div>
        ) : (
          <IonIcon
            name={'checkmark'}
            size={20}
            color={theme.BACKGROUND}
            style={{
              backgroundColor: theme.ACTIVE_INPUT,
              borderRadius: 40,
              padding: 5,
            }}
          />
        )}
      </ButtonBase>
    );

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px 20px' }}>
        <EditBtn />
      </div>
      <div style={{ overflow: 'hidden' }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            {editMode && corps?.allowMembersChangePhoto ? (
              <>
                <UploadMedia addPDFDisabled viewType="dropzone" multiple={false} />
                <div style={{ height: 20 }} />
                <RenderStateAttachments
                  currentAttachments={{ images, documents: [], videos: [] }}
                  deleteAttachment={deleteImage}
                  viewType="column"
                />
              </>
            ) : (
              <PostGallery images={corps?.photo.map((el) => (typeof el === 'object' ? el : { url: el }))} />
            )}
            <div style={{ padding: 15 }}>
              <div style={{ marginBottom: 15 }}>
                <span className={styles.header} style={{ fontSize: 22 }}>
                  {corps.corpsname}
                </span>
              </div>
              <div style={{ marginBottom: 15 }}>
                {(editMode || corps.description) && <span className={styles.header}>Info</span>}
                {editMode && corps?.allowMembersEditDescription ? (
                  <div style={{ border: 'solid', borderWidth: 1, borderRadius: 2, borderColor: theme.ICON_BORDER }}>
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange}
                      onBlur={saveDescription}
                    />
                  </div>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: corps.description }} />
                )}
              </div>
              {!!corps.admins?.length && (
                <>
                  <span className={styles.header}>Admins</span>
                  {corps.admins.map((subscriber, i: number) => {
                    const userObj = subscriber.user;

                    return (
                      <div
                        key={userObj._id + i}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <KreiseSubscriber subscriber={userObj} openInNewWindow={editMode} />
                      </div>
                    );
                  })}
                </>
              )}

              {corps?.website && (
                <div style={{ marginBottom: 15, display: 'flex', flexDirection: 'column' }}>
                  <span className={styles.header}>Website: </span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={corps.website}
                    style={{ textDecoration: 'none', fontSize: 18, fontWeight: 'bolder' }}
                  >
                    {corps.website}
                  </a>
                </div>
              )}
              {corps?.email && (
                <div style={{ marginBottom: 15, display: 'flex', flexDirection: 'column' }}>
                  <span className={styles.header}>E-Mail-Adresse: </span>
                  <a
                    href={`mailto:${corps.email}`}
                    style={{ textDecoration: 'none', fontSize: 18, fontWeight: 'bolder' }}
                  >
                    {corps.email}
                  </a>
                </div>
              )}
              <span className={styles.header}>Mitglieder</span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles({
  header: {
    fontSize: 20,
    color: theme.TEXT_PRIMARY,
    fontWeight: 'bolder',
    padding: '10px 0',
    alignSelf: 'flex-start',
  },
});
