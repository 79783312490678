import { ButtonBase, makeStyles } from '@material-ui/core';
import { getConfig } from 'config/config';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProfileItemRedirectTypes } from 'scenes/ProfileDetails/model';
import { useAppDispatch } from 'store';
import { globalSearchResponseType } from 'store/reducers/globalSearch';
import { ReactComponent as CorpsIcon } from '../../../../../assets/corpsIcon.svg';
import { checkIfImageExists } from 'utilities/checkIfImageExists';

type Props = {
  corp?: globalSearchResponseType['corps']['data'][0];
};

export const CorpItem: FC<Props> = (props) => {
  const { corp } = props;
  const { _id, name, members, photo } = corp;

  const styles = useStyles({});

  // @ts-ignore
  const image = photo?.[0]?.thumb || photo?.[0] || photo?.thumb || photo?.url || photo;
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    if (photo) checkIfImageExists(image, (exists) => setImageExists(exists));
  }, []);

  return (
    <Link className={styles.container} to={`/suche/corpsid/${_id}`}>
      <div className={styles.imageWrapper}>
        {imageExists ? (
          <img src={image} alt="" className="w-[56px] h-[56px] object-cover rounded-full" />
        ) : (
          <CorpsIcon />
        )}
      </div>
      <div className={styles.infoWrapper}>
        <span className={styles.name}>{name}</span>
        <span className={styles.name}>{members}</span>
      </div>
    </Link>
  );
};

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: 80,
    backgroundColor: theme.BACKGROUND_SECONDARY,
    padding: 10,
    margin: 7,
    alignItems: 'center',
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  imageWrapper: {
    width: 60,
    height: 60,
    borderRadius: 60,
    background: theme.ICON_BACKGROUND,
    borderColor: theme.ICON_BORDER,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 20px 0 20px',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flex: 1,
  },
  name: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.TEXT_PRIMARY,
  },
});
