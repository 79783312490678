import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';

import { withConfig, Theme } from 'config/';

const styles = createStyles({
  container: {
    width: '100%',
    padding: '10px 20px',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: 20,
    fontWeight: 600,
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  title: string;
}

const KreiseHeading: React.FC<Props> = ({ classes, theme, title }) => (
  <div className={classes.container}>
    <span className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
      {title}
    </span>
  </div>
);

export default withStyles(styles)(withConfig(KreiseHeading));
