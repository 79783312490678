import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { getConfig } from 'config/config';
import { Modal, ModalProps } from '../Modal/Modal';
import Input from 'components/UI/Input';
import toast from 'react-hot-toast';
import Button from 'components/UI/Button';
import { useLanguage } from 'languages/languageContext';
import { CheckboxIcon, BoxIcon } from '@radix-ui/react-icons';
import { cn } from 'utilities/utils';

type Props = ModalProps & {
  data: { _id: string; name: string }[];
  preselectedValues: { _id: string; name: string }[];
  // setSelectedValues: (v: { _id: string; name: string }[]) => void;
  maxSelectedCount?: number;
  onConfirm: (selectedValues: { _id: string; name: string }[]) => void;
  allowEmpty?: boolean;
};

export const SearchModal: FC<Props> = (props) => {
  const {
    isOpen,
    handleClose,
    data,
    preselectedValues = [],
    maxSelectedCount = 1,
    onConfirm,
    modalTitle,
    message,
    allowEmpty = false,
  } = props;
  const styles = useStyles({});
  const { btn, searchInput } = useLanguage();

  const [input, setInput] = useState('');

  const [selectedValues, setSelectedValues] = useState([]);

  const filteredArray = data?.filter((kreise) => kreise.name.toLowerCase().includes(input.toLowerCase()));

  const maxItemsAchieved = maxSelectedCount === selectedValues.length;

  const noSelecteedValues = allowEmpty === false && selectedValues.length === 0;

  useEffect(() => {
    if (!selectedValues.length) setSelectedValues((c) => [...preselectedValues, ...c]);
  }, [preselectedValues]);

  useEffect(() => {
    if (!isOpen) setInput('');
  }, [isOpen]);

  const onSelect = (value: { _id: string; name: string }) => {
    setSelectedValues([...selectedValues, value]);
  };

  const onDelete = (value: { _id: string; name: string }) => {
    const updatedArray = selectedValues?.filter((item) => item?._id !== value._id);
    setSelectedValues(updatedArray);
  };

  const handleConfirm = () => {
    if (noSelecteedValues) {
      toast.error('Bitte mindestens 1 Eintrag wählen.');
      return;
    }
    onConfirm(selectedValues);
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} modalTitle={modalTitle} message={message}>
      <div className={styles.wrapper}>
        <Input value={input} onChange={(e) => setInput(e.target.value)} placeholder={searchInput.title} />
        <div className={cn(styles.scrollableWrapper, ' max-h-[40vh] sm:max-h-96')}>
          {filteredArray.map((item) => {
            if (!item) return <></>;

            const isSelected = selectedValues?.find((val) => val?._id === item._id);

            const onClick = () => {
              if (maxItemsAchieved && !isSelected) {
                toast.error(`max items is ${maxSelectedCount}`);
                return;
              }
              isSelected ? onDelete(item) : onSelect(item);
            };

            return (
              <div className={styles.itemWrapper} onClick={onClick} key={item._id}>
                {isSelected ? (
                  <CheckboxIcon color={theme.ACTIVE_INPUT} className="w-5 h-5" />
                ) : (
                  <BoxIcon color={theme.ICON_BORDER} className="w-5 h-5 p-[2px]" />
                )}

                <span className={styles.name} style={{ color: maxItemsAchieved && !isSelected && theme.ICON_BORDER }}>
                  {item.name}
                </span>
              </div>
            );
          })}
        </div>
        <div style={{ height: 15 }} />
        <Button
          onClick={handleConfirm}
          label={btn.sendBtn}
          background={noSelecteedValues ? theme.ICON_BORDER : undefined}
        />
        <Button onClick={handleClose} label={btn.cancelBtn} background={theme.ICON_BORDER} />
      </div>
    </Modal>
  );
};

const { theme } = getConfig();

const useStyles = makeStyles({
  wrapper: {
    padding: 10,
  },
  itemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
  },
  name: {
    minWidth: 0,
    fontSize: 16,
    marginLeft: 5,
    color: theme.TEXT_PRIMARY,
    fontFamily: 'Poppins',
  },
  scrollableWrapper: {
    overflowY: 'auto',
    backgroundColor: theme.ICON_BACKGROUND,
    borderRadius: 10,
    border: `1px solid ${theme.ICON_BORDER}`,
    padding: '10px 5px',
    '&::-webkit-scrollbar': {
      width: 5,
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      margin: '5px 0',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
});
