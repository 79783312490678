import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withStyles, WithStyles, createStyles, ButtonBase } from '@material-ui/core';
import { withConfig, Theme, Config } from '../../../config/';
import { setTime, configDate, postImagePath } from '../../../utilities/';
import EventOrg from './EventOrg';
import EventToolbar from '../../../components/Shared/EventToolbar';
import { ReactComponent as ClockIcon } from '../../../assets/clock.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';
import Linkify from 'react-linkify';
import linkDecorator from '../../../components/UI/linkDecorator';
import PostGallery from '../../../components/Shared/PostGallery';
import PostDocuments from '../../../components/Shared/DocumentsGallery';
import { useLanguage } from 'languages/languageContext';
import { API } from 'constants/core';
import { configRRules } from 'utilities/configRRules';
import { useAppSelector } from 'store';
import EventPart from './EventPart';
import { IonIcon } from 'components/UI/IonIcon';

const styles = createStyles({
  container: {
    width: '100%',
    boxSizing: 'border-box',
  },
  eventInfo: {
    borderBottom: '1px solid',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Poppins',
    marginBottom: '0 0 10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '95%',
  },
  descItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 15,
  },
  descProp: {
    marginRight: 5,
    width: 115,
    minWidth: 115,
  },
  descVal: {
    fontWeight: 500,
    whiteSpace: 'pre-wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  dateWrapper: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  orgWrapper: {
    padding: '10px 15px',
    boxSizing: 'border-box',
  },
  organizers: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px 0',
    boxSizing: 'border-box',
  },
  heading: {
    padding: '10px 15px',
  },
  commentsTitle: {
    fontSize: 16,
    fontFamily: 'Poppins',
    fontWeight: 600,
  },
  'media(max-width: 1024px)': {
    organizers: {
      flexDirection: 'column',
    },
  },
  copyIcon: {
    height: 16,
    width: 16,
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  event: any;
  guest: boolean;
  stateEvent: any;
  config: Config;
}

const EventContent: React.FC<Props> = ({ theme, classes, event, guest, config }) => {
  const { eventLabels, rrEventLabels } = useLanguage();
  const [showZusagenModal, setShowZusagenModal] = useState<boolean>(false);
  const { eventCommentsCount } = useAppSelector((state) => state.comments);
  const { event_show_website_option, disable_guest_function } = useAppSelector((state) => state.common);

  const user = useSelector((state: any) => state.users.user);
  // const inviter = `=${user.name}%20${user.lastname}`;
  const eventLink = `${config.WEB_LINK}/calendar/${event?._id}`;

  const isCreator = event?.organizers?.some((org) => org?._id === user?._id);

  let startTime, endTime, startDate, endDate;

  if (event) {
    if (event.startDate) {
      startTime = setTime(event.startDate);
      startDate = configDate(new Date(event.startDate), 'day');
    }

    if (event.endDate) {
      endTime = setTime(event.endDate);
      endDate = configDate(new Date(event.endDate), 'day');
    }
  }

  const images = event?.images || [];
  const oldFormatFiles = event?.documents
    .filter((file) => file.path)
    .map((file) => ({
      fileName: file.name,
      url: postImagePath('full', file.path),
    }));
  const documents = oldFormatFiles?.length ? oldFormatFiles : event?.documents?.length ? event?.documents : [];

  const addEventToCalendar = async () => {
    const path = `${API}/events/${event._id}/export`;
    fetch(path, {
      method: 'GET',
    })
      .then((response) => {
        if (response.status >= 300) throw Error('returned status ===');
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${event.title}.ics`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((e) => {
        console.log('error on download member list', e);
      });
  };

  const joinedParts = event?.parts
    .map((part) => {
      if (part.members?.some((member) => member.id._id === user?._id)) {
        return part;
      }
    })
    .filter((item) => item);

  const summaryPrice = (
    joinedParts?.reduce((acc, item) => (acc += Number(item?.price.replace(',', '.'))), 0) || 0
  ).toFixed(2);

  const showInfoSection = !!joinedParts?.length;

  return (
    <div className={classes.container}>
      <div style={{ padding: 15, overflow: 'hidden' }}>
        {!!images.length && <PostGallery images={images} />}
        <div
          className={classes.eventInfo}
          style={{
            borderTop: images.length > 0 ? `1px solid ${theme.BACKGROUND_SECONDARY}` : 'none',
            borderBottomColor: theme.BACKGROUND_SECONDARY,
          }}
        >
          <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
            {event?.title}
          </h3>
          <div className={classes.descItem}>
            <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
              {eventLabels.eventLabel}:{' '}
            </span>
            <span className={classes.descVal} style={{ color: theme.TEXT_PRIMARY }}>
              {event?.organizerMsg}
            </span>
          </div>
          <div className={classes.descItem}>
            <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
              {eventLabels.eventStartLabel}:{' '}
            </span>
            <div className={classes.dateWrapper}>
              <CalendarIcon />
              <span
                className={classes.descVal}
                style={{
                  color: theme.TEXT_PRIMARY,
                  margin: '0 10px 0',
                }}
              >
                {startDate}
              </span>
              <ClockIcon />
              <span
                className={classes.descVal}
                style={{
                  color: theme.TEXT_PRIMARY,
                  marginLeft: 10,
                }}
              >
                {startTime}
              </span>
            </div>
          </div>
          {event?.hideEndDate && (
            <div className={classes.descItem}>
              <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
                {eventLabels.eventEndLabel}:{' '}
              </span>
              <div className={classes.dateWrapper}>
                <CalendarIcon />
                <span
                  className={classes.descVal}
                  style={{
                    color: theme.TEXT_PRIMARY,
                    margin: '0 10px 0',
                  }}
                >
                  {endDate}
                </span>
                <ClockIcon />
                <span
                  className={classes.descVal}
                  style={{
                    color: theme.TEXT_PRIMARY,
                    marginLeft: 10,
                  }}
                >
                  {endTime}
                </span>
              </div>
            </div>
          )}
          <div className={classes.descItem}>
            <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
              {rrEventLabels.title}:{' '}
            </span>
            <span
              className={classes.descVal}
              style={{
                color: theme.TEXT_PRIMARY,
                whiteSpace: 'initial',
              }}
            >
              {
                configRRules({
                  startDate: event?.startDate,
                  rules: event?.recurringConfig || {},
                  labels: rrEventLabels,
                }).recurringLabel
              }
            </span>
          </div>
          <Linkify componentDecorator={linkDecorator}>
            <div className={classes.descItem}>
              <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
                {eventLabels.locationLabel}:{' '}
              </span>
              <span className={classes.descVal} style={{ color: theme.TEXT_PRIMARY }}>
                {event?.location}
              </span>
            </div>
          </Linkify>
          {event?.description && (
            <Linkify componentDecorator={linkDecorator}>
              <div className={classes.descItem}>
                <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
                  {eventLabels.descLabel}:{' '}
                </span>
                <span className={classes.descVal} style={{ color: theme.TEXT_PRIMARY }}>
                  {event?.description}
                </span>
              </div>
            </Linkify>
          )}
          {!!documents.length && (
            <>
              <div className={classes.descItem}>
                <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
                  {eventLabels.attachmentLabel}:{' '}
                </span>
              </div>

              <PostDocuments files={documents} />
            </>
          )}
          {user._id && (
            <Linkify componentDecorator={linkDecorator}>
              <div className={classes.descItem}>
                <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
                  {eventLabels.shareLinkLabel}:{' '}
                </span>
                <span className={classes.descVal} style={{ color: theme.TEXT_PRIMARY }}>
                  <button
                    style={{ cursor: 'pointer', textDecoration: 'none', color: '#0000ee' }}
                    onClick={() => navigator.clipboard.writeText(eventLink)}
                  >
                    {eventLabels.copyLinkLabel}
                  </button>
                  {' | '}
                  <button
                    style={{ cursor: 'pointer', textDecoration: 'none', color: '#0000ee' }}
                    onClick={() => setShowZusagenModal(true)}
                  >
                    {eventLabels.linkLabel}
                  </button>
                </span>
              </div>
            </Linkify>
          )}
          {!!event?.invitedKreises?.length && (
            <div className={classes.descItem}>
              <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
                Einladung an:{' '}
              </span>
              <span className={classes.descVal} style={{ color: theme.TEXT_PRIMARY }}>
                {event?.invitedKreises.map((kreise) => kreise.name).join(`\n`)}
              </span>
            </div>
          )}
          {event?.visibility && disable_guest_function && (
            <div className={classes.descItem}>
              <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
                {eventLabels.visibility}:{' '}
              </span>
              <span className={classes.descVal} style={{ color: theme.TEXT_PRIMARY }}>
                {eventLabels.visibilityOptions[event?.visibility]}
              </span>
            </div>
          )}
        </div>
      </div>
      {showInfoSection && (
        <div className="bg-slate-100 rounded-md p-2 mx-4 mb-4">
          <div className="flex flex-row items-center justify-between">
            <h3 className="font-semibold">Info</h3>
            <IonIcon name="information-circle-outline" color={theme.BUTTON_SECONDARY} size={22} />
          </div>

          {!!joinedParts.length && (
            <>
              <div className="my-1">
                <span>{eventLabels.parts.youJoined}</span>
                <ul>
                  {joinedParts?.map(
                    (part) =>
                      part?.title && (
                        <li className="font-semibold" key={part._id}>
                          {part.title}
                        </li>
                      ),
                  )}
                </ul>
              </div>
              <div className="mt-4">
                <span>
                  {eventLabels.parts.totalToPay}{' '}
                  <span className="font-semibold">{summaryPrice.toString().replace('.', ',')} EUR</span>
                </span>
              </div>
            </>
          )}
        </div>
      )}
      <div className="flex flex-col gap-8 p-4">
        {event?.parts?.map((part) => (
          <EventPart {...part} isCreator={isCreator} key={part._id} />
        ))}
      </div>
      <div className={classes.orgWrapper}>
        <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
          {eventLabels.creatorLabel}:{' '}
        </span>{' '}
        <div className={classes.organizers}>
          {event?.organizers &&
            event?.organizers.map((organizer) => (
              <EventOrg key={organizer._id} organizer={organizer} disabled={guest} />
            ))}
        </div>
        <div className={classes.descItem}>
          <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
            {eventLabels.iCalDownload}:{' '}
          </span>
          <span className={classes.descVal} onClick={addEventToCalendar}>
            <a
              style={{ cursor: 'pointer', textDecoration: 'none', color: '#0000ee' }}
              onClick={() => navigator.clipboard.writeText(eventLink)}
              href="#"
            >
              {' '}
              iCal / ICS
            </a>
          </span>
        </div>
        <EventToolbar
          event={event}
          showComments={false}
          setShowZusagenModal={setShowZusagenModal}
          showZusagenModal={showZusagenModal}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(withConfig(EventContent));
