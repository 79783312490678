import React from 'react';
import { withStyles, WithStyles, createStyles, ButtonBase } from '@material-ui/core';
import { withConfig, Theme } from '../../config/';
import { connect } from 'react-redux';
import { showResponse } from '../../store/actions/response';
import { updateUserRequest } from '../../store/actions/users';
import SonstigenInput from './components/SonstigenInput';
import Button from '../../components/UI/Button';
import Loader from '../../components/UI/Loader';
import ProfileScale from '../../components/UI/ProfileScale';
import AddCircle from '@material-ui/icons/AddCircle';
import { withLanguage } from 'languages';
import { translationsType } from 'languages/languageContext';
import Input from '../../components/UI/Input';
import TouchableInput from '../../components/UI/TouchableInput';
import { HighlightOff } from '@material-ui/icons';
import SelectModal from 'components/Shared/SelectModal';
import { socialMedia, isValidUrl } from 'utilities';
import { IonIcon } from 'components/UI/IonIcon';

const styles = createStyles({
  container: {
    width: 420,
    boxSizing: 'border-box',
  },
  updateButtonWraper: {
    marginTop: 30,
  },
  titleWrapper: {
    width: '100%',
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 15,
    margin: '0 0 25px',
  },
  interessenWrapper: {
    width: '100%',
    marginBottom: 45,
  },
  kulturpatenWrapper: {
    width: '100%',
    marginBottom: 15,
  },
  addButtonWrapper: {
    position: 'relative',
    width: '100%',
    height: 10,
    paddingBottom: 20,
  },
  addButtonContainer: {
    position: 'absolute',
    top: -10,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      padding: '0 10px',
    },
  },
  removeButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

interface ISocialMedia {
  label: string;
  value: string;
  custom: string;
  error: string;
}

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  user: any;
  common: any;
  userIsLoading: boolean;
  updateUserRequest: (user) => void;
  showResponse: (message: object) => void;
  language: translationsType;
}

interface State {
  [key: string]: any;
  stateInterest: Array<any>;
  stateSocialMedia: ISocialMedia[];
  socialMediaIdx: number;
  // stateKulturpaten: Array<any>
}

class ProfileSonstiges extends React.Component<Props, State> {
  state = {
    stateInterest: [],
    stateSocialMedia: [],
    socialMediaModal: false,
    socialMediaIdx: 0,
    // stateKulturpaten: []
  };

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user !== prevProps.user) {
      this.initData();
    }
  }

  validateSocialMediaFields = (fields: ISocialMedia[]) => {
    const updatedSocialMedia = fields.map((field) => {
      if (!isValidUrl(field.value)) {
        return { ...field, error: this.props.language.profileOther.socialMediaError };
      }
      return { ...field, error: '' };
    });
    this.setState({ stateSocialMedia: updatedSocialMedia });
    return updatedSocialMedia.some((field) => !!field.error);
  };

  updateProfileHandler = () => {
    const { user, updateUserRequest, showResponse, language } = this.props;
    const { stateInterest, stateSocialMedia } = this.state;
    const isValid = this.validateSocialMediaFields(stateSocialMedia);
    if (isValid) {
      return;
    }

    const updatedUser = {
      ...user,
      password: '',
      interessen: [...stateInterest],
      socialMedia: stateSocialMedia.map(({ custom, label, value }) => ({ label: custom || label, value })),
      // kulturpaten: [...stateKulturpaten],
    };

    if (user && user.name) {
      updateUserRequest(updatedUser);
    } else if (user && !user.name) {
      showResponse({
        message: language.profileOther.noUserLabel,
      });
    }
  };

  initData = () => {
    const { user } = this.props;

    if (user && user.name) {
      this.setState({
        stateInterest: [...user.interessen],
        stateSocialMedia: [...user.socialMedia],
        // stateKulturpaten: [...user.kulturpaten]
      });
    }
  };

  onRemoveInterest = (index: number) => {
    const interests = [...this.state.stateInterest];

    const updatedInterests = interests.filter((item: any, i: number) => i !== index);

    this.setState({
      stateInterest: [...updatedInterests],
    });
  };

  // onRemoveKulturpaten = (index: number) => {
  //     const kulturpatens = [...this.state.stateKulturpaten];

  //     const updatedKulturpatens = kulturpatens.filter((item: any, i: number) => i !== index);

  //     this.setState({
  //         stateKulturpaten: [...updatedKulturpatens]
  //     });
  // };

  onAddItem = (type: string) => {
    const { stateInterest, stateSocialMedia } = this.state;
    let items: Array<any> = [];

    if (type === 'stateInterest') {
      items = [...stateInterest];
      items.push({ titel: '' });
    }
    // else if (type === 'stateKulturpaten') {
    //     items = [...stateKulturpaten];
    // }
    if (type === 'stateSocialMedia') {
      items = [...stateSocialMedia];
      items.push({
        custom: '',
        value: '',
        label: '',
        error: '',
      });
    }

    this.setState({
      [type]: [...items],
    });
  };

  onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, type: string, i: number) => {
    const { stateInterest } = this.state;
    const { value } = e.target;
    let items: Array<any> = [];

    if (type === 'stateInterest') {
      items = [...stateInterest];
    }
    // else if (type === 'stateKulturpaten') {
    //     items = [...stateKulturpaten];
    // }

    items[i].titel = value;

    this.setState({
      [type]: [...items],
    });
  };

  onChangeSocialMedia = (i: number, value: string, field: 'label' | 'value' | 'custom') => {
    const updatedSocialMedia = this.state.stateSocialMedia?.map((socialMedia: ISocialMedia, idx: number) => {
      if (i === idx) {
        return { ...socialMedia, [field]: value };
      }
      return socialMedia;
    });

    this.setState({ stateSocialMedia: updatedSocialMedia });
  };

  onRemoveSocialMedia = (id: number) => {
    const socialMedia = [...this.state.stateSocialMedia];

    const updatedSocialMedia = socialMedia.filter((_, index: number) => index !== id);

    this.setState({
      stateSocialMedia: updatedSocialMedia,
    });
  };

  render() {
    const { user, classes, userIsLoading, theme, language, common } = this.props;
    const { profileOther, btn } = language;
    const { stateInterest, stateSocialMedia, socialMediaIdx, socialMediaModal } = this.state;

    let lastInterest: any = stateInterest[stateInterest.length - 1];
    // let lastKulturpaten: any = stateKulturpaten[stateKulturpaten.length - 1];
    const lastSocialMedia = stateSocialMedia[stateSocialMedia.length - 1] as ISocialMedia;
    const isAddSocialMedia =
      (lastSocialMedia?.value?.trim() && lastSocialMedia?.label?.trim()) ||
      stateSocialMedia.length === 0 ||
      lastSocialMedia?.error;

    return (
      <React.Fragment>
        {stateInterest && (
          <div className={classes.container}>
            <ProfileScale />
            <div className={classes.interessenWrapper}>
              <div className={classes.titleWrapper}>
                <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
                  {profileOther.hobbiesLabel}
                </h3>
              </div>
              {stateInterest &&
                stateInterest.map((item: any, i: number) => (
                  <SonstigenInput
                    value={item.titel}
                    key={'inter' + i}
                    id={i}
                    onRemoveItem={() => this.onRemoveInterest(i)}
                    onChangeHandler={(e) => this.onChangeHandler(e, 'stateInterest', i)}
                  />
                ))}
              <div className={classes.addButtonWrapper}>
                <div className={classes.addButtonContainer}>
                  <ButtonBase
                    disabled={lastInterest && !lastInterest.titel}
                    style={{ borderRadius: 50, fontSize: 40, background: theme.BACKGROUND }}
                    onClick={() => this.onAddItem('stateInterest')}
                  >
                    <AddCircle
                      style={{
                        color:
                          (lastInterest && lastInterest.titel) || stateInterest.length === 0
                            ? theme.BUTTON_PRIMARY
                            : theme.BACKGROUND_SECONDARY,
                      }}
                      fontSize="inherit"
                    />
                  </ButtonBase>
                </div>
              </div>
            </div>
            {/* <div className={classes.kulturpatenWrapper}>
                            <div className={classes.titleWrapper}>
                                <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>Kulturpaten</h3>
                            </div>
                            {stateKulturpaten && stateKulturpaten.map((item: any, i: number) => (
                                <SonstigenInput
                                    value={item.titel}
                                    key={'kult' + i}
                                    id={i}
                                    onRemoveItem={() => this.onRemoveKulturpaten(i)}
                                    onChangeHandler={(e) => this.onChangeHandler(e, 'stateKulturpaten', i)}
                                />
                            ))}
                            <div className={classes.addButtonWrapper}>
                                <div className={classes.addButtonContainer}>
                                    <ButtonBase disabled={lastKulturpaten && !lastKulturpaten.titel} style={{ borderRadius: 50, fontSize: 40, background: theme.BACKGROUND }} onClick={() => this.onAddItem('stateKulturpaten')}>
                                        <AddCircle style={{ color: lastKulturpaten && lastKulturpaten.titel || stateKulturpaten.length === 0 ? theme.BUTTON_PRIMARY : theme.BACKGROUND_SECONDARY }} fontSize="inherit" />
                                    </ButtonBase>
                                </div>
                            </div>
                        </div> */}
            {common.social_media && (
              <div className={classes.interessenWrapper}>
                <div className={classes.titleWrapper}>
                  <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
                    {profileOther.socialMediaLabel}
                  </h3>
                </div>
                {stateSocialMedia.map((el, idx) => (
                  <div key={`${el.label}${idx}`}>
                    <div className={classes.removeButton}>
                      <ButtonBase onClick={() => this.onRemoveSocialMedia(idx)} disabled={false}>
                        <HighlightOff
                          style={{
                            color: theme.BUTTON_SECONDARY,
                            width: '30px',
                            height: '30px',
                            transform: 'rotate: 45deg',
                          }}
                        />
                      </ButtonBase>
                    </div>

                    {el.label === 'Sonstige (Eingeben)' ? (
                      <Input
                        placeholder={profileOther.socialMediaPlattform}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.onChangeSocialMedia(idx, e.target.value, 'custom')
                        }
                        autoCapitalize="none"
                        value={el?.custom}
                      />
                    ) : (
                      <TouchableInput
                        onClick={() => this.setState({ socialMediaModal: true, socialMediaIdx: idx })}
                        value={el?.label}
                        placeholder={profileOther.socialMediaPlattform}
                      />
                    )}
                    <Input
                      placeholder={profileOther.socialMediaLink}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.onChangeSocialMedia(idx, e.target.value, 'value')
                      }
                      autoCapitalize="none"
                      value={el?.value}
                      error={el?.error}
                    />
                  </div>
                ))}
                <div className={classes.addButtonWrapper}>
                  <div className={classes.addButtonContainer}>
                    <ButtonBase
                      disabled={!isAddSocialMedia}
                      style={{ borderRadius: 50, fontSize: 40, background: theme.BACKGROUND }}
                      onClick={() => this.onAddItem('stateSocialMedia')}
                    >
                      <AddCircle
                        style={{
                          color:
                            isAddSocialMedia || stateSocialMedia.length === 0
                              ? theme.BUTTON_PRIMARY
                              : theme.BACKGROUND_SECONDARY,
                        }}
                        fontSize="inherit"
                      />
                    </ButtonBase>
                  </div>
                </div>
              </div>
            )}
            <div className={classes.updateButtonWraper}>
              <Button
                disabled={
                  // lastKulturpaten &&
                  // lastKulturpaten.titel &&
                  (lastInterest && lastInterest.titel) || !!isAddSocialMedia
                }
                label={btn.saveBtn}
                onClick={this.updateProfileHandler}
              />
            </div>
          </div>
        )}
        <SelectModal
          modalIsOpen={socialMediaModal}
          modalTitle={profileOther.socialMediaPlattform}
          modalHandler={() => this.setState({ socialMediaModal: false })}
          selectItemHandler={(value: string) => this.onChangeSocialMedia(socialMediaIdx, value, 'label')}
          data={socialMedia.map(({ label, id }: { label: string; id: string }) => ({
            label,
            id,
            icon: id !== 'custom' && <IonIcon name={'logo-' + id} size={15} style={{ marginRight: '5px' }} />,
          }))}
        />
        <Loader showLoader={userIsLoading} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.users.user,
  userIsLoading: state.users.userIsLoading,
  common: state.common,
});

const mapDispatchToProps = {
  updateUserRequest,
  showResponse,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withLanguage(withConfig(ProfileSonstiges))));
