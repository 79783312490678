import React from 'react';
import { withConfig } from '../../config';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { HeadingProps } from '../model';

const styles = createStyles({
  heading: {
    width: '100%',
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'Poppins',
  },
});

interface Props extends WithStyles<typeof styles>, HeadingProps { }

const heading: React.FC<Props> = ({ classes, theme, title }) => (
  <div className={classes.heading}>
    <h1 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
      {title}
    </h1>
  </div>
);

export default withStyles(styles)(withConfig(heading));
