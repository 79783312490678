import * as api from './../../api/kreise';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { updateUserSuccess } from 'store/actions/users';
import { KreiseItemType } from 'store/types/kreiseTypes';
import { fileType } from './attachments';

export const getKreiseById = createAsyncThunk(
  'kreise/getKreiseById',
  async (kreiseId: string) => await api.getKreiseById(kreiseId),
);

export const getUserKreise = createAsyncThunk(
  'kreise/getUserKreise',
  async ({ page, limit, query, clean = false }: { page: number; limit: number; query?: string; clean?: boolean }) =>
    await api.getUserKreise(page, limit, query),
);

export const getKreise = createAsyncThunk(
  'kreise/getKreise',
  async ({ page, limit }: { page: number; limit: number }) => await api.getKreise(page, limit),
);

export const searchKreise = createAsyncThunk(
  'kreise/searchKreise',
  async ({ query, skip, limit }: { query: string; skip: number; limit: number }) =>
    await api.searchKreise(query, skip, limit),
);

export const subscribeKreise = createAsyncThunk(
  'kreise/subscribeKreise',
  async (payload: any) => await api.subscribeKreise(payload),
);

export const sendKreiseRequest = createAsyncThunk(
  'kreise/sendKreiseRequest',
  async ({ kreiseId }: { kreiseId: string }) => await api.sendKreiseRequest(kreiseId),
);

export const getKreiseSubscribers = createAsyncThunk(
  'kreise/getKreiseSubscribers',
  async ({ kreiseName, page, limit }: { kreiseName: string; page: number; limit: number }) =>
    await api.getKreiseSubscribers(kreiseName, page, limit),
);

export const createKreise = createAsyncThunk(
  'kreise/createKreise',
  async ({ kreise }: { kreise: { name: string; description?: string; members: string[] } }, { dispatch, getState }) => {
    const res = await api.createKreise(kreise);
    const state = getState();
    // @ts-ignore
    const updatedUser = { ...state.users.user, kreise: [res._id, ...state.users.user.kreise] };
    dispatch(updateUserSuccess({ ...updatedUser }));
    return res;
  },
);

export const removeKreiseUser = createAsyncThunk(
  'kreise/removeKreiseUser',
  async ({ kreiseId, userId }: { kreiseId: string; userId: string }) =>
    await api.removeUserFromKreise(kreiseId, userId),
);

export const addKreiseUser = createAsyncThunk(
  'kreise/addKreiseUser',
  async ({ kreiseId, userId, adminId }: { kreiseId: string; userId: string; user: any; adminId: string }) =>
    await api.addUserToKreise(kreiseId, userId, adminId),
);

export const deleteKreise = createAsyncThunk(
  'kreise/deleteKreise',
  async ({ kreiseId }: { kreiseId: string }, { dispatch, getState }) => {
    const res = await api.deleteKreise(kreiseId);
    const state = getState();
    // @ts-ignore
    const updatedUser = { ...state.users.user, kreise: state.users.user.kreise.filter((el) => el !== kreiseId) };
    dispatch(updateUserSuccess({ ...updatedUser }));
    return res;
  },
);

export const addKreiseAdmin = createAsyncThunk(
  'kreise/addKreiseAdmin',
  async ({ kreiseId, userId }: { kreiseId: string; userId: string; user: any }, { dispatch, getState }) =>
    await api.addAdminToKreise(kreiseId, userId),
);

export const removeKreiseAdmin = createAsyncThunk(
  'kreise/removeKreiseAdmin',
  async ({ kreiseId, userId }: { kreiseId: string; userId: string }) =>
    await api.removeAdminFromKreise(kreiseId, userId),
);

export const editKreise = createAsyncThunk(
  'kreise/editKreise',
  async ({
    kreiseId,
    payload,
  }: {
    kreiseId: string;
    payload: { name?: string; description?: string; suggestionInNewsfeed?: any; photo?: fileType[] };
  }) => await api.editKreise(kreiseId, { ...payload }),
);

export const getKreiseForEvents = createAsyncThunk('kreise/getKreiseForEvents', async () => {
  const userId = localStorage.getItem('userId');
  return await api.getKreiseForEvents(userId);
});

const initialState = {
  kreiseIsLoading: false,
  kreiseNamesIsLoading: false,
  ownerKreise: [] as KreiseItemType[],
  kreise: [] as KreiseItemType[],
  kreiseSubscribedCount: null as number,
  error: null,
  kreiseCount: null as number,
  currentKreise: null as KreiseItemType | null,
  previousKreise: null as KreiseItemType | null, // TODO: DELETE ME
  currentKreiseIsLoading: false,
  // fix me
  kreiseSubscribers: [] as any[],
  kreiseSubscribersAmount: 0,
  kreiseSubscribersIsLoading: false,
  kreiseForEvents: [] as KreiseItemType[],
};

export const kreiseSlice = createSlice({
  name: 'kreise',
  initialState,
  reducers: {
    clearCurrentKreise: (state) => {
      state.previousKreise = state.currentKreise;
      state.currentKreise = null;
    },
    clearKreise: (state) => {
      state.kreiseIsLoading = false;
      state.kreiseNamesIsLoading = false;
      state.kreise = [];
      state.error = null;
      state.kreiseCount = null;
      state.kreiseSubscribedCount = null;
      state.ownerKreise = [];
    },
    clearUnsubKreise: (state) => {
      state.kreise = [];
      state.kreiseNamesIsLoading = false;
      state.kreiseIsLoading = false;
    },
    clearUserKreise: (state) => {
      state.ownerKreise = [];
      state.kreiseNamesIsLoading = false;
      state.kreiseIsLoading = false;
    },
    clearKreiseSubscribers: (state) => {
      state.kreiseSubscribers = [];
      state.kreiseSubscribersAmount = 0;
    },
    // TODO: DELETE ME
    setPreviousKriese: (state) => {
      state.previousKreise = state.currentKreise;
    },
  },
  extraReducers: (builder) => {
    // --> getKreiseById
    builder.addCase(getKreiseById.pending, (state) => {
      state.currentKreiseIsLoading = true;
    });
    builder.addCase(getKreiseById.fulfilled, (state, action: any) => {
      state.previousKreise = state.currentKreise;
      state.currentKreise = action.payload.Data;
      state.currentKreiseIsLoading = false;
    });
    builder.addCase(getKreiseById.rejected, (state, action: any) => {
      state.error = action.payload.error;
      state.currentKreiseIsLoading = false;
    });
    //  getKreiseById <--

    // --> getUserKreise
    builder.addCase(getUserKreise.pending, (state, action) => {
      state.kreiseNamesIsLoading = true;
    });
    builder.addCase(getUserKreise.fulfilled, (state, action: any) => {
      state.kreiseNamesIsLoading = false;
      state.kreiseSubscribedCount = action.payload.Total;
      if (action.meta.arg.clean) {
        state.ownerKreise = action.payload.Data;
        return;
      }
      // const userKreiseArr = [...state.ownerKreise, ...action.payload.Data];
      state.ownerKreise = state.ownerKreise.concat(action.payload.Data);
    });
    builder.addCase(getUserKreise.rejected, (state, action: any) => {
      console.log('action', action);
      state.error = action.error;
      state.kreiseNamesIsLoading = false;
    });
    // getUserKreise <--

    // --> getKreise
    builder.addCase(getKreise.pending, (state) => {
      state.kreiseNamesIsLoading = true;
    });
    builder.addCase(getKreise.fulfilled, (state, action: any) => {
      const kreiseArr = state.kreise.concat(action.payload.Data);
      state.kreiseNamesIsLoading = false;
      state.kreise = kreiseArr;
      state.kreiseCount = action.payload.Total;
    });
    builder.addCase(getKreise.rejected, (state, action: any) => {
      state.error = action.payload.error;
      state.kreiseNamesIsLoading = false;
    });
    // getKreise <--

    // --> sendKreiseRequest
    builder.addCase(sendKreiseRequest.pending, (state) => {
      state.kreiseIsLoading = true;
    });
    builder.addCase(sendKreiseRequest.fulfilled, (state, action: any) => {
      // state.currentKreise = action.payload.kreiseId;
      // state.kreise.find(el =>{ if(el.id === action.payload.kreiseId)})
      const id = state.kreise.findIndex((el) => el.id === action.payload.target);
      //@ts-ignore
      state.kreise[id] = { ...state.kreise[id], request: action.payload._id };
      state.kreiseIsLoading = false;
    });
    builder.addCase(sendKreiseRequest.rejected, (state, action: any) => {
      state.error = action.payload.error;
      state.kreiseIsLoading = false;
    });
    //  sendKreiseRequest <--

    // --> searchKreise
    builder.addCase(searchKreise.pending, (state) => {
      state.kreiseNamesIsLoading = true;
    });
    builder.addCase(searchKreise.fulfilled, (state, action: any) => {
      console.log('action', action);
      state.kreiseNamesIsLoading = false;
      state.kreise = action.page > 1 ? state.kreise.concat(action.payload.Data) : action.payload.Data;
      state.kreiseCount = action.payload.Total;
    });
    builder.addCase(searchKreise.rejected, (state, action: any) => {
      state.error = action.error;
      state.kreiseNamesIsLoading = false;
    });
    // searchKreise <--

    // -> subscribeKreise
    builder.addCase(subscribeKreise.pending, (state) => {
      state.kreiseIsLoading = true;
    });
    builder.addCase(subscribeKreise.fulfilled, (state, action) => {
      state.kreiseIsLoading = false;
      state.currentKreise = action.payload.Data;
      if (!action.meta.arg.is_subscribed)
        state.ownerKreise = state.ownerKreise.filter((el) => el.id !== action.payload.Data.id);
      if (action.meta.arg.is_subscribed) state.ownerKreise.push(action.payload.Data);
    });
    builder.addCase(subscribeKreise.rejected, (state, action: any) => {
      state.error = action.payload.error;
      state.kreiseIsLoading = false;
    });
    // subscribeKreise <--

    // --> getKreiseSubscribers
    builder.addCase(getKreiseSubscribers.pending, (state) => {
      state.kreiseSubscribersIsLoading = true;
    });
    builder.addCase(getKreiseSubscribers.fulfilled, (state, action: any) => {
      state.kreiseSubscribersIsLoading = false;
      state.kreiseSubscribers = state.kreiseSubscribers.concat(action.payload.Data.data);
      state.kreiseSubscribersAmount = action.payload.Data.total;
    });
    builder.addCase(getKreiseSubscribers.rejected, (state, action: any) => {
      state.error = action.payload.error;
      state.kreiseSubscribersIsLoading = false;
    });
    // getKreiseSubscribers <--

    //
    builder.addCase(removeKreiseUser.pending, (state, action) => {
      state.currentKreiseIsLoading = true;
    });
    builder.addCase(removeKreiseUser.fulfilled, (state, action) => {
      state.currentKreiseIsLoading = false;
      state.kreiseSubscribers = state.kreiseSubscribers.filter((item) => item.id._id !== action.meta.arg.userId);
      state.kreiseSubscribersAmount = state.kreiseSubscribersAmount - 1;
      state.currentKreise.admins = state.currentKreise.admins.filter((item) => item._id !== action.meta.arg.userId);
    });
    builder.addCase(removeKreiseUser.rejected, (state, action) => {
      state.currentKreiseIsLoading = false;
    });
    //

    //
    builder.addCase(addKreiseUser.pending, (state, action) => {
      state.currentKreiseIsLoading = true;
    });
    builder.addCase(addKreiseUser.fulfilled, (state, action) => {
      state.currentKreiseIsLoading = false;
      state.kreiseSubscribers = [{ id: action.meta.arg.user }, ...state.kreiseSubscribers];
      state.kreiseSubscribersAmount = state.kreiseSubscribersAmount + 1;
    });
    builder.addCase(addKreiseUser.rejected, (state, action) => {
      state.currentKreiseIsLoading = false;
    });
    //

    //
    builder.addCase(deleteKreise.pending, (state, action) => {
      state.currentKreiseIsLoading = true;
    });
    builder.addCase(deleteKreise.fulfilled, (state, action) => {
      state.currentKreiseIsLoading = false;
      state.ownerKreise = state.ownerKreise.filter((el) => el.id !== action.meta.arg.kreiseId);
      state.kreise = state.kreise.filter((el) => el.id !== action.meta.arg.kreiseId);
    });
    builder.addCase(deleteKreise.rejected, (state, action) => {
      state.currentKreiseIsLoading = false;
    });
    //

    //
    builder.addCase(createKreise.pending, (state, action) => {
      state.currentKreiseIsLoading = true;
    });
    builder.addCase(createKreise.fulfilled, (state, action) => {
      state.currentKreiseIsLoading = false;
      console.log('action.payload', action.payload);
      // @ts-ignore
      state.ownerKreise = [action.payload, ...state.ownerKreise];
      state.kreiseSubscribedCount += 1;
    });
    builder.addCase(createKreise.rejected, (state, action) => {
      state.currentKreiseIsLoading = false;
    });
    //

    //
    builder.addCase(removeKreiseAdmin.pending, (state, action) => {
      state.currentKreiseIsLoading = true;
    });
    builder.addCase(removeKreiseAdmin.fulfilled, (state, action) => {
      state.currentKreiseIsLoading = false;
      state.currentKreise.admins = state.currentKreise.admins.filter((item) => item._id !== action.meta.arg.userId);
    });
    builder.addCase(removeKreiseAdmin.rejected, (state, action) => {
      state.currentKreiseIsLoading = false;
    });
    //

    //
    builder.addCase(addKreiseAdmin.pending, (state, action) => {
      state.currentKreiseIsLoading = true;
    });
    builder.addCase(addKreiseAdmin.fulfilled, (state, action) => {
      state.currentKreiseIsLoading = false;
      state.currentKreise.admins = [action.meta.arg.user, ...state.currentKreise?.admins];

      const alreadyMember = state.kreiseSubscribers.findIndex((el) => el.id._id === action.meta.arg.userId) !== -1;

      if (!alreadyMember) {
        state.kreiseSubscribers = [{ id: action.meta.arg.user }, ...state.kreiseSubscribers];
        state.kreiseSubscribersAmount = state.kreiseSubscribersAmount + 1;
      }
    });
    builder.addCase(addKreiseAdmin.rejected, (state, action) => {
      state.currentKreiseIsLoading = false;
    });
    //

    //
    builder.addCase(editKreise.pending, (state, action) => {
      state.currentKreiseIsLoading = true;
    });
    builder.addCase(editKreise.fulfilled, (state, action) => {
      state.currentKreiseIsLoading = false;
      state.currentKreise = { ...state.currentKreise, ...action.payload };
    });
    builder.addCase(editKreise.rejected, (state, action) => {
      state.currentKreiseIsLoading = false;
    });
    //

    builder.addCase(getKreiseForEvents.fulfilled, (state, action: PayloadAction<KreiseItemType[]>) => {
      //@ts-ignore
      state.kreiseForEvents = action.payload.map((el) => ({ ...el, _id: el._id || el.id }));
    });
  },
});

export const kreise = kreiseSlice.reducer;

export const {
  clearUserKreise,
  clearCurrentKreise,
  clearKreise,
  clearKreiseSubscribers,
  clearUnsubKreise,
  setPreviousKriese,
} = kreiseSlice.actions;
