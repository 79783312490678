import {
  GET_CORPS_NAMES_REQUEST,
  GET_CORPS_NAMES_SUCCESS,
  GET_CORPS_NAMES_FAILURE,
  SET_INITIAL_CORP,
  CLEAR_INITIAL_CORP,
  GET_CORPS_SUBS_REQUEST,
  GET_CORPS_SUBS_SUCCESS,
  GET_CORPS_SUBS_FAILURE,
} from '../../constants';

// GET CORPS
export const getCorpsNamesRequest = (name?: string, guest?: boolean) => ({
  type: GET_CORPS_NAMES_REQUEST,
  name,
  guest,
});

export const getCorpsNamesSuccess = (corpsNames: Array<object>): { type: string; corpsNames: Array<object> } => ({
  type: GET_CORPS_NAMES_SUCCESS,
  corpsNames,
});

export const getCorpsNamesFailure = (error: object): { type: string; error: object } => ({
  type: GET_CORPS_NAMES_FAILURE,
  error,
});

// INITIAL CORP
export const setInitialCorp = (corp) => ({
  type: SET_INITIAL_CORP,
  corp,
});

export const clearInitialCorp = () => ({
  type: CLEAR_INITIAL_CORP,
});

// GET CORPS SUBS REQUEST
export const getCorpsSubsRequest = () => ({
  type: GET_CORPS_SUBS_REQUEST,
});

export const getCorpsSubsSuccess = (corpsSubs: Array<object>) => ({
  type: GET_CORPS_SUBS_SUCCESS,
  corpsSubs,
});

export const getCorpsSubsFailure = (error: object) => ({
  type: GET_CORPS_SUBS_FAILURE,
});
