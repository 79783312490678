import { serverSlice } from './server';
import { common } from './common';
import { mentoring } from './mentoring';
import { media } from './mediaSlice';
import { combineReducers } from 'redux';
import { corps } from './corps';
import { response } from './response';
import { auth } from './auth';
import { users } from './users';
import { layout } from './layout';
import { kreise } from './kreise';
import { news } from './news';
import { likes } from './likes';
import { comments } from './commentsSlice';
// import { search } from './search';
import { contacts } from './contacts';
import { requests } from './requests';
import { chat } from './chat';
import { notifications } from './notifications';
import { advertisement } from './advertisement';
import { events } from './events';
import { globalSearchSlice } from './globalSearch';
import { search } from './searchSlice';

export const rootReducer = combineReducers({
  layout,
  auth,
  news,
  corps,
  response,
  users,
  kreise,
  likes,
  comments,
  search,
  contacts,
  requests,
  chat,
  notifications,
  advertisement,
  events,
  media,
  mentoring,
  common,
  globalSearch: globalSearchSlice,
  server: serverSlice,
});
