const linkDecorator = (href: string, text: string) => (
  <a
    style={{ textDecoration: 'none', color: 'rgb(38, 113, 209)' }}
    href={href}
    key={text}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a>
);

export default linkDecorator;
