import { updateProfileBasic } from 'api/users';
import Button from 'components/UI/Button';

import moment from 'moment';
import { RootState, store } from 'store';
import { signOutRequest } from 'store/actions/auth';
import { hideResponse, showResponse } from 'store/actions/response';
import isValidDateString from 'utilities/isValidDateString';

let unsubscribe: (() => void) | null = null;
let isSubscribed = false;

function setupSubscription() {
  unsubscribe = store.subscribe(handleStateChange);
  isSubscribed = true;
}

function handleStateChange() {
  const state = store.getState() as RootState; // Get the current state
  const {
    users: { user },
    common,
  } = state;

  if (user._id && isValidDateString(user.acknowledgeAgb)) {
    unsubscribe();
    return;
  }
  if (common.first_login_accept_agb === false) {
    unsubscribe();
    return;
  }

  if (user._id && user.acknowledgeAgb === null && common.first_login_accept_agb) {
    if (unsubscribe) {
      unsubscribe(); // Unsubscribe if conditions are met
      isSubscribed = false;

      // Perform your action here
      store.dispatch(
        showResponse({
          title: 'Nutzungsbedingungen',
          message: (
            <span className="break-normal">
              Mit der Nutzung der App stimmen Sie den{' '}
              <a href={common.tou_link} className="text-blue-500 hover:text-blue-600" target="_blank" rel="noreferrer">
                Nutzungsbedingungen
              </a>{' '}
              zu und nehme die{' '}
              <a href={common.dpd_link} className="text-blue-500 hover:text-blue-600" target="_blank" rel="noreferrer">
                Datenschutzerklärung
              </a>{' '}
              zur Kenntnis.
            </span>
          ),
          onClick: () => {
            store.dispatch(signOutRequest());
            store.dispatch(hideResponse());
          },
          cancelBtnLabel: 'Ausloggen',
          options: [
            <Button
              label={'Zustimmen'}
              onClick={async () => {
                const res = await updateProfileBasic({ acknowledgeAgb: moment() });
                if (isValidDateString(res.acknowledgeAgb)) {
                  store.dispatch(hideResponse());
                }
              }}
            />,
          ],
          route: '/sign-in',
        }),
      );
    }
  }
}

// Initial setup
setupSubscription();

// Function to resubscribe
export function resubscribeAgbSubscription() {
  if (isSubscribed === true) return;
  unsubscribe = store.subscribe(handleStateChange); // Set up the subscription again
  isSubscribed = true;
}

// Function to check if subscribed
export function checkSubscription() {
  return isSubscribed;
}
