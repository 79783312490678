import React from 'react';
import { withConfig } from '../../../config/';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { BerufHeadingProps } from '../model';

const styles = createStyles({
  container: {
    width: '100%',
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 15,
    margin: '0 0 10px',
  },
});

interface Props extends WithStyles<typeof styles>, BerufHeadingProps {}

const BerufHeading: React.FC<Props> = ({ classes, title, theme }) => (
  <div className={classes.container}>
    <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
      {title}
    </h3>
  </div>
);

export default withStyles(styles)(withConfig(BerufHeading));
