import React, { ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ButtonBase } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';

import { useAppDispatch } from 'store';
import { withConfig, Theme } from 'config';
import { imagePath } from 'utilities';
import defaultImage from 'assets/default_avatar.png';
import { clearKreiseSubscribers } from 'store/reducers/kreise';

const styles = createStyles({
  wrapper: {
    width: '100%',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '10px 10px',
    overflowX: 'hidden',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    borderRadius: 30,
    border: '1px solid',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
  },
  content: {
    marginLeft: 15,
    flex: 1
  },
  title: {
    display: 'block',
    fontSize: 20,
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginBottom: 0,
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
  },
  subtitle: {
    display: 'block',
    fontSize: 14,
    fontFamily: 'Roboto',
    marginBottom: 5,
    textAlign: 'left',
  },
  '@media(max-width: 650px)': {
    container: {
      padding: '10px 20px',
    },
    title: {
      fontSize: 18,
      maxWidth: 250,
      lineHeight: '18px',
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  subscriber: any;
  openInNewWindow?: boolean
  additionalInfo?: ReactElement | string
  renderCity?: boolean
  renderCorp?: boolean
}

const KreiseSubscriber: React.FC<Props> = ({ theme, classes, subscriber, history, openInNewWindow = false, additionalInfo, renderCity = true, renderCorp = true }) => {
  const { name, lastname, wohnort, corp, photo } = subscriber;
  const dispatch = useAppDispatch();

  let senderPhoto;
  if (photo) {
    senderPhoto = imagePath(null, photo);
  }

  const toProfileDetailsHandler = () => {
    if (openInNewWindow) {
      window.open(`/profile-details/${subscriber._id}`)
      return
    }
    history.push(`/profile-details/${subscriber._id}`, { userId: subscriber._id });
    dispatch(clearKreiseSubscribers());
  };

  return (
    <ButtonBase className={classes.container} onClick={toProfileDetailsHandler}>
      <div className={classes.imageWrapper} style={{ borderColor: theme.BACKGROUND_SECONDARY }}>
        <img src={senderPhoto || defaultImage} alt=" " className={classes.image} />
      </div>
      <div className={classes.content}>
        <span className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
          {name} {lastname} {corp?.[0]?.status ? `(${corp?.[0].status})` : null}
        </span>
        {additionalInfo &&
          <span className={classes.subtitle} style={{ color: theme.TEXT_PRIMARY }}>
            {additionalInfo}
          </span>}
        {renderCity &&
          <span className={classes.subtitle} style={{ color: theme.TEXT_PRIMARY }}>
            {wohnort}
          </span>}
        {renderCorp && <span className={classes.subtitle} style={{ color: theme.TEXT_PRIMARY }}>
          {corp?.[0]?.corpsname}
        </span>}
      </div>
    </ButtonBase>
  );
};

export default withStyles(styles)(withRouter(withConfig(KreiseSubscriber)));
