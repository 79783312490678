import React from 'react';
import { InputBase, withStyles } from '@material-ui/core';
import { getConfig } from 'config/config';

const { theme } = getConfig();
export const BootstrapInput = withStyles((muiTheme) => ({
  root: {
    'label + &': {
      marginTop: muiTheme.spacing(3),
    },
  },
  input: {
    borderRadius: 20,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 25px 8px 25px',
    transition: muiTheme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'Roboto',
    borderColor: theme.BACKGROUND_SECONDARY,
    background: theme.BACKGROUND_PRIMARY,
    '&:focus': {
      borderRadius: 20,
      borderColor: theme.ACTIVE_INPUT,
    },
  },
}))(InputBase);
