import React, { useState, useEffect, ReactNode } from 'react';
import { ButtonBase } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { withConfig, Theme } from '../../config';
import { getConfig } from 'config/config';

const { theme } = getConfig();

const styles = createStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(97, 97, 97, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 60,
    overflowX: 'hidden', //horizontal
    overflowY: 'hidden', //horizontal
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > div::-webkit-scrollbar': {
      display: 'none',
    },
  },
  modalWrapper: {
    position: 'relative',
    width: 520,
    paddingBottom: 15,
    borderRadius: 10,
    zIndex: 60,
    '@media(max-width: 650px)': {
      width: '90%',
    },
    '& > div::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    overflowY: 'hidden', //horizontal
    overflowX: 'hidden', //horizontal
  },
  heading: {
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    position: 'relative',
    padding: '20px 0 20px 0',
  },
  title: {
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    margin: 0,
    letterSpacing: '0.1',
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  scrollWrapper: {
    width: '100%',
    overflow: 'scroll',
    maxHeight: '80vh',
  },
  scroll: {
    padding: '15px 25px',
    margin: 0,
  },
  scrollItem: {
    width: '100%',
    display: 'block',
    textAlign: 'left',
    listStyleType: 'none',
    fontSize: 15,
    fontFamily: 'Roboto',
    lineHeight: '25px',
    cursor: 'pointer',
    padding: '5px 0',
    borderRadius: 5,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 10,
    outline: 'none',
    border: 'none',
  },
  messageWrapper: {
    padding: '10px 20px',
  },
  message: {
    fontSize: 15,
    textAlign: 'center',
  },
  leftContent: {
    position: 'absolute',
    left: 20,
    top: 20,
  },
});

interface Props extends WithStyles<typeof styles> {
  showModal: boolean;
  selectItemHandler?: () => void;
  onClose?: () => void;
  modalTitle: string;
  message?: string | ReactNode;
  children: any;
  modalWidth?: number;
  leftContent?: ReactNode;

  // TODO: delete all mentions of modalName, theme
  modalName?: any;
  theme?: any;
}

const DefaultModal: React.FC<Props> = ({
  classes,
  showModal,
  selectItemHandler,
  onClose,
  modalTitle,
  children,
  message,
  modalWidth = 520,
  leftContent,
  ...props
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showModal]);

  return showModal ? (
    <div className={classes.container}>
      <div className={classes.modalWrapper} style={{ background: theme.BACKGROUND, width: modalWidth }}>
        <div className={classes.heading} style={{ background: theme.BACKGROUND_SECONDARY }}>
          {leftContent && <div className={classes.leftContent}>{leftContent}</div>}
          <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
            {modalTitle}
          </h3>
          <div className={classes.buttonWrapper}>
            <ButtonBase onClick={onClose} style={{ borderRadius: 50 }}>
              <Close style={{ color: theme.BUTTON_SECONDARY }} />
            </ButtonBase>
          </div>
        </div>
        <div className={classes.scrollWrapper} style={{ background: theme.BACKGROUND }}>
          {message && (
            <div className={classes.messageWrapper}>
              <p className={classes.message}>{message}</p>
            </div>
          )}
          {children}
        </div>
      </div>
      <div className={classes.backdrop} onClick={onClose}></div>
    </div>
  ) : null;
};

export default withStyles(styles)(DefaultModal);
