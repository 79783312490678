import React, { useId, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useAppDispatch } from 'store';
import { fileType } from 'store/reducers/attachments';
import { uploadDocument } from 'store/reducers/mediaSlice';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFToJPGConverter = ({
  pdf,
  receiverId,
  isPrivate,
  setPdfs,
}: {
  pdf: fileType;
  receiverId?: string;
  isPrivate?: boolean;
  setPdfs: React.Dispatch<React.SetStateAction<fileType[]>>;
}) => {
  const dispatch = useAppDispatch();
  const id = useId();
  const [numPages, setNumPages] = useState(null);

  // Function to handle document load success
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Function to render the first page as an image
  const renderFirstPageAsJPG = () => {
    return (
      <Page
        pageNumber={1}
        scale={3} // Adjust the scale for image resolution
        width={200} // Adjust width as needed
        renderMode="canvas"
        onRenderSuccess={convertToJPG}
      />
    );
  };

  const convertToJPG = () => {
    const canvas = document.querySelector('canvas');
    canvas.toBlob(
      (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onloadend = () => {
          pdf.formdata.append('thumb', blob, id + '.png');

          dispatch(uploadDocument({ formdata: pdf.formdata, receiverId, isPrivate, thumb: blob }));
          setPdfs((c) => c.filter((el) => el.fileName !== pdf.fileName));
        };
      },
      'image/png',
      1,
    );
  };

  return (
    <div className="hidden">
      <Document file={pdf.renderUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {numPages && renderFirstPageAsJPG()}
      </Document>
    </div>
  );
};

export default PDFToJPGConverter;
