import React from 'react';
import { withConfig } from '../../config';
import lockImg from '../../assets/padlock.png';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { IconUIProps } from '../model';

const styles = createStyles({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 20,
  },
  imageWrapper: {
    width: 80,
    height: 80,
    borderRadius: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 40,
    height: 40,
  },
  '@media (min-width: 1024px)': {
    image: {
      width: 40,
      height: 40,
    },
  },
});

interface Props extends WithStyles<typeof styles>, IconUIProps {}

const logo: React.FC<Props> = ({ classes, theme }) => (
  <div className={classes.container}>
    <div className={classes.imageWrapper} style={{ background: theme.BACKGROUND_PRIMARY }}>
      <img src={lockImg} className={classes.image} />
    </div>
  </div>
);

export default withStyles(styles)(withConfig(logo));
