import React, { FC, useState } from "react";
import DefaultModal from "components/Shared/DefaultModal";
import InfiniteScroll from 'react-infinite-scroller';
import { useAppDispatch } from "store";
import { surveyGetVotes } from "store/reducers/news";
import LikeComponent from "components/Shared/LikeComponent";
import { getConfig } from "config/config";
import { useLanguage } from "languages/languageContext";
import { surveyCommentsGetVotes } from "store/reducers/commentsSlice";

type Props = {
  isOpen: boolean
  handleModal: () => void
  options: { title: string, _id: string, votersCount: number }[]
  postId: string
  surveyTitle: string
  type: 'post' | 'comment'
}

type TState = {
  isLoading: boolean
  data: TData[]
}

type TData = {
  _id: string
  title: string
  page: number
  hasMore: boolean,
  vote: {
    time?: string
    user?: {
      _id: string,
      name: string,
      lastname: string,
      photo: string
    }
  }[]
}

const { theme } = getConfig()

export const SurveyVotesModal: FC<Props> = ({ isOpen, handleModal, options, postId, surveyTitle, type }) => {
  const { surveys: lSurveys } = useLanguage()
  const dispatch = useAppDispatch()

  const initialData: TData[] = options.map(({ title, _id }) => ({
    _id,
    title,
    page: 1,
    hasMore: true,
    vote: []
  }))

  const [state, setState] = useState<TState>({ isLoading: false, data: initialData })
  const hasMore = !!state.data.find(({ hasMore }) => hasMore)

  const getMoreData = () => {
    if (state.isLoading) return
    const currentOption = state.data.findIndex((option) => option.hasMore === true)
    getData(currentOption)
  };

  const getData = async (idx: number) => {
    try {
      const option = state.data[idx]
      if (!state.data[idx].hasMore) return

      setState({ ...state, isLoading: true })

      const { payload } = type === 'comment' ?
        await dispatch(surveyCommentsGetVotes({
          limit: 10,
          commentId: postId,
          page: option.page,
          optionId: option._id,
        })) :
        await dispatch(surveyGetVotes({
          limit: 10,
          postId,
          page: option.page,
          optionId: option._id,
        }))

      const newData = state.data

      // @ts-ignore
      if (!payload.length) {
        newData[idx].hasMore = false
        setState({ ...state, data: newData })
        return
      }
      // where 10 is a limit
      // @ts-ignore
      if (payload.length < 10)
        newData[idx].hasMore = false
      // @ts-ignore
      newData[idx].vote = [...newData[idx].vote, ...payload]
      newData[idx].page += 1
      setState({ ...state, data: newData })
      return
    } catch (error) {
      console.log('You loose', error)
    }
  }

  return <DefaultModal
    showModal={isOpen}
    onClose={handleModal}
    modalTitle={lSurveys.votesModalTitle}
  >
    <InfiniteScroll
      threshold={50}
      initialLoad={true}
      pageStart={1}
      loadMore={getMoreData}
      hasMore={hasMore}
    >
      <div>
        <h2 style={{ margin: '0 30px' }}>{surveyTitle}</h2>
        {state.data.map(el => {
          return <div key={el._id}>
            <h3 style={{ margin: '0 30px', fontWeight: 'normal' }}>
              {el.title}: {' '}
              <strong>
                {options.find(item => item._id === el._id)?.votersCount} {el.vote.length == 1 ? lSurveys.votes.single : lSurveys.votes.multiple}
              </strong>
            </h3>
            {el.vote.length
              ? el.vote.map(v => <LikeComponent like={v.user} key={v.time} date={v.time} />)
              : <h4 style={{ marginLeft: 30, color: theme.ICON_BORDER }}>{lSurveys.noVotes}</h4>
            }
          </div>
        })}
      </div>
    </InfiniteScroll>
  </DefaultModal>
}