export const GET_CORPS_NAMES_REQUEST = 'GET_CORPS_NAMES_REQUEST';
export const GET_CORPS_NAMES_SUCCESS = 'GET_CORPS_NAMES_SUCCESS';
export const GET_CORPS_NAMES_FAILURE = 'GET_CORPS_NAMES_FAILURE';

export const SET_INITIAL_CORP = 'SET_INITIAL_CORP';
export const CLEAR_INITIAL_CORP = 'CLEAR_INITIAL_CORP';

// GET CORPS
export const GET_CORPS_SUBS_REQUEST = 'GET_CORPS_SUBS_REQUEST';
export const GET_CORPS_SUBS_SUCCESS = 'GET_CORPS_SUBS_SUCCESS';
export const GET_CORPS_SUBS_FAILURE = 'GET_CORPS_SUBS_FAILURE';
