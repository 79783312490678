import React, { Fragment } from 'react';
import { ProfileItemProps } from '../model';
import { getConfig } from '../../../config/config';
import { AttachmentsGallery } from '../../../components/Shared/AttachmentsGallery';
import { makeStyles } from '@material-ui/core';
import { useLanguage } from 'languages/languageContext';

const { config, theme } = getConfig();
const useStyles = makeStyles({
  contentItem: {
    paddingVertical: 10,
    paddingHorizontal: 0,
  },
  contentTitle: {
    fontSize: 15,
    color: theme.TEXT_SECONDARY,
  },
  contentSubtitle: {
    fontSize: 15,
    color: theme.TEXT_PRIMARY,
  },
});

type Props = {
  value: {
    pdf?: string;
    video?: string;
    image?: string;
    link?: string;
  };
};

const ProfileAttachments: React.FC<Props> = ({ value }) => {
  const { profileDetails } = useLanguage();
  if (!value) return <></>;
  const valuesArray = Object.entries(value);

  const items = Object.entries(value).map(([key, val], idx) => {
    const nextItemExists = !!valuesArray[idx + 1]?.[1].length;

    const renderSeparator = idx + 1 !== valuesArray.length && nextItemExists;

    return val.length ? (
      <Fragment key={key}>
        <a href={val} target="_blank" rel="noopener noreferrer">
          {profileDetails[key]}
        </a>
        {renderSeparator && <span> | </span>}
      </Fragment>
    ) : null;
  });

  return <div>{items}</div>;
};
export default ProfileAttachments;
