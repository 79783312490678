// GET BIRTHDAY EVENTS
export const CLEAR_BIRTHDAY_EVENTS = 'CLEAR_BIRTHDAY_EVENTS';
export const GET_BIRTHDAY_EVENTS_REQUEST = 'GET_BIRTHDAY_EVENTS_REQUEST';
export const GET_BIRTHDAY_EVENTS_SUCCESS = 'GET_BIRTHDAY_EVENTS_SUCCESS';
export const GET_BIRTHDAY_EVENTS_FAILURE = 'GET_BIRTHDAY_EVENTS_FAILURE';

// GET ALL EVENT
export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';

// GET MY EVENTS
export const GET_MY_EVENTS_REQUEST = 'GET_MY_EVENTS_REQUEST';
export const GET_MY_EVENTS_SUCCESS = 'GET_MY_EVENTS_SUCCESS';
export const GET_MY_EVENTS_FAILURE = 'GET_MY_EVENTS_FAILURE';

// GET CORPS EVENTS
export const GET_CORPS_EVENTS_REQUEST = 'GET_CORPS_EVENTS_REQUEST';
export const GET_CORPS_EVENTS_SUCCESS = 'GET_CORPS_EVENTS_SUCCESS';
export const GET_CORPS_EVENTS_FAILURE = 'GET_CORPS_EVENTS_FAILURE';

export const CLEAR_EVENTS = 'CLEAR_EVENTS';

// GET EVENT BY ID
export const GET_EVENT_BY_ID_REQUEST = 'GET_EVENT_BY_ID_REQUEST';
export const GET_EVENT_BY_ID_SUCCESS = 'GET_EVENT_BY_ID_SUCCESS';
export const GET_EVENT_BY_ID_FAILURE = 'GET_EVENT_BY_ID_FAILURE';

export const CLEAR_CURRENT_EVENT = 'CLEAR_CURRENT_EVENT';

// GET EVENT BY GUEST MAIL
export const GET_EVENT_BY_MAIL_REQUEST = 'GET_EVENT_BY_MAIL_REQUEST';

// GET EVENT MEMBERS
export const GET_EVENT_MEMBERS_REQUEST = 'GET_EVENT_MEMBERS_REQUEST';
export const GET_EVENT_MEMBERS_SUCCESS = 'GET_EVENT_MEMBERS_SUCCESS';
export const GET_EVENT_MEMBERS_FAILURE = 'GET_EVENT_MEMBERS_FAILURE';

export const CLEAR_EVENT_MEMBERS = 'CLEAR_EVENT_MEMBERS';

// ADD EVENT MEMBER
export const ADD_EVENT_MEMBER_REQUEST = 'ADD_EVENT_MEMEBER_REQUEST';
export const ADD_EVENT_MEMBER_SUCCESS = 'ADD_EVENT_MEMEBER_SUCCESS';
export const ADD_EVENT_MEMBER_FAILURE = 'ADD_EVENT_MEMEBER_FAILURE';

// ADD EVENT GUEST MEMBER
export const ADD_EVENT_GUEST_REQUEST = 'ADD_EVENT_GUEST_REQUEST';

// REMOVE EVENT MEMBER
export const REMOVE_EVENT_MEMBER_REQUEST = 'REMOVE_EVENT_MEMEBER_REQUEST';
export const REMOVE_EVENT_MEMBER_SUCCESS = 'REMOVE_EVENT_MEMEBER_SUCCESS';
export const REMOVE_EVENT_MEMBER_FAILURE = 'REMOVE_EVENT_MEMEBER_FAILURE';

// REMOVE EVENT GUEST MEMBER
export const REMOVE_EVENT_GUEST_REQUEST = 'REMOVE_EVENT_GUEST_REQUEST';

// GET AUTOCOMPLETE
export const GET_AUTOCOMPLETE_EVENTS_REQUEST = 'GET_AUTOCOMPLETE_EVENTS_REQUEST';
export const GET_AUTOCOMPLETE_EVENTS_SUCCESS = 'GET_AUTOCOMPLETE_EVENTS_SUCCESS';
export const GET_AUTOCOMPLETE_EVENTS_FAILURE = 'GET_AUTOCOMPLETE_EVENTS_FAILURE';

// GET CORPS CONTACTS
export const GET_CORPS_CONTACTS_REQUEST = 'GET_CORPS_CONTACTS_REQUEST';
export const GET_CORPS_CONTACTS_SUCCESS = 'GET_CORPS_CONTACTS_SUCCESS';
export const GET_CORPS_CONTACTS_FAILURE = 'GET_CORPS_CONTACTS_FAILURE';

export const CLEAR_CORPS_CONTACTS = 'CLEAR_CORPS_CONTACTS';

// ADD EVENT
export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE';

// UPDATE EVENT
export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';

// REMOVE EVENT
export const REMOVE_EVENT_REQUEST = 'REMOVE_EVENT_REQUEST';
export const REMOVE_EVENT_SUCCESS = 'REMOVE_EVENT_SUCCESS';
export const REMOVE_EVENT_FAILURE = 'REMOVE_EVENT_FAILURE';
