import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { TextareaAutosize, ButtonBase } from '@material-ui/core';
import Button from '../../../components/UI/Button';
import { useLanguage } from 'languages/languageContext';
import { UploadMedia } from 'components/Shared/UploadMedia';
import { getConfig } from 'config/config';
import { IonIcon } from 'components/UI/IonIcon';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    bottom: 0,
    right: 0,
    padding: '10px',
    zIndex: 10,
    boxSizing: 'border-box',
    boxShadow: '1px 0 5px 0 #ccc',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: '10px',
  },
  inputWrapper: {
    position: 'relative',
    flexGrow: 1,
    marginRight: 10,
    boxSizing: 'border-box',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px',
    textAlign: 'center',
    borderRadius: 50,
    color: '#fff',
  },
  input: {
    fontFamily: 'Poppins',
    padding: '5px 0',
    border: 'none',
    outline: 'none',
    fontSize: 15,
    resize: 'none',
    width: '100%',
    '&::placeholder': {
      fontSize: 16,
      fontFamily: 'Roboto',
      paddingTop: 2,
    },
  },
  imagesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '10px 0 0 40px',
  },
  image: {
    position: 'relative',
    width: 100,
    height: 70,
    backgroundSize: 'contain',
    marginBottom: 10,
    marginRight: 20,
  },
  deleteButton: {
    position: 'absolute',
    top: -10,
    right: -15,
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addBeerWrapper: {
    width: 55,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
  },
  '@media(max-width: 1024px)': {
    container: {
      padding: '10px',
      width: '450px',
    },
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      left: 0,
      right: 0,
      padding: '10px',
    },
    imagesWrapper: {
      padding: '10px 0 0',
    },
    inputWrapper: {
      paddingLeft: 10,
    },
    addBeerWrapper: {
      marginRight: 10,
    },
  },
});

interface Props extends WithStyles<typeof styles> {
  onChange: (e: any) => void;
  value: string;
  sendMessage: () => void;
  disabled: boolean;
  receiver: any;
}

const AddMessage: React.FC<Props> = ({ classes, value, onChange, sendMessage, disabled, receiver }) => {
  const { messages, btn, post } = useLanguage();
  const handleKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <div className={classes.container} style={{ background: theme.BACKGROUND_PRIMARY }}>
      <div className={classes.wrapper}>
        <UploadMedia receiver={receiver} />
        <div className={classes.inputWrapper}>
          <TextareaAutosize
            rowsMax={3}
            style={{ background: theme.BACKGROUND_PRIMARY, padding: '5px 0' }}
            onChange={onChange}
            className={classes.input}
            placeholder={messages.addMessageLabel}
            value={value}
            onKeyDown={handleKeyDown}
          />
          {value.length < 19999 || (
            <span style={{ color: theme.ERROR_PRIMARY, marginBottom: 10 }}>{post.longMessage}</span>
          )}
        </div>
        <ButtonBase
          onClick={sendMessage}
          disabled={!disabled}
          className={classes.button}
          style={{ background: !disabled ? '#ccc' : theme.BUTTON_PRIMARY }}
        >
          <IonIcon name="paper-plane-outline" color="#FFF" size={28} style={{ height: 29, width: 29 }} />
        </ButtonBase>
      </div>
    </div>
  );
};

export default withStyles(styles)(AddMessage);
