import { OPEN_MENU, CLOSE_MENU } from '../../constants/';

type StateProps = {
  menuIsOpen: boolean;
};

const initialState: StateProps = {
  menuIsOpen: false,
};

export const layout = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MENU:
      return {
        ...state,
        menuIsOpen: true,
      };
    case CLOSE_MENU:
      return {
        ...state,
        menuIsOpen: false,
      };
    default:
      return {
        ...state,
      };
  }
};
