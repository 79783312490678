import * as React from 'react';
import { Link } from 'react-router-dom';
import { withConfig, Theme } from '../../../config/';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { translationsType, useLanguage, withLanguage } from 'languages/languageContext';

const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 25px 0 25px',
    marginBottom: 40,
    boxSizing: 'border-box',
  },
  link: {
    textDecoration: 'underline',
    fontSize: 12,
    fontFamily: 'Roboto',
    letterSpacing: 0.01,
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  language: translationsType;
  email?: string
}

const ResetPassword: React.FC<Props> = ({ classes, theme, language, email }) => (
  <div className={classes.container}>
    <Link to={{ pathname: "/reset-password", state: email }} className={classes.link} style={{ color: theme.TEXT_SECONDARY }}>
      {language.signIn.forgotPassword}
    </Link>
  </div>
);

export default withStyles(styles)(withLanguage(withConfig(ResetPassword)));
