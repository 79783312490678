import React, { PropsWithChildren } from 'react';
import { ButtonBase } from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { withConfig } from '../../config';
import { ButtonProps } from '../model';
import { getConfig } from 'config/config';

const { theme } = getConfig();

const styles = createStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  base: {
    borderRadius: 50,
  },
  button: {
    display: 'block',
    padding: '12px 0 12px 0',
    textAlign: 'center',
    borderRadius: 50,
    color: '#fff',
    fontFamily: 'Roboto',
  },
  description: {
    display: 'block',
    // padding: '12px 0 12px 0',
    textAlign: 'center',
    // borderRadius: 50,
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 11,
  },
});

interface Props extends WithStyles<typeof styles>, ButtonProps {
  onClick?: (any) => any;
  description?: string;
  children?: React.ReactNode;
  labelStyle?: React.CSSProperties;
}

const button: React.FC<Props> = ({
  label,
  classes,
  width = 220,
  height = 'auto',
  bottomMargin = 15,
  background = theme.BUTTON_PRIMARY,
  disabled = true,
  fontSize = 15,
  description = '',
  children,
  labelStyle,
  ...props
}) => (
  <div className={classes.container} style={{ marginBottom: bottomMargin }}>
    <ButtonBase disabled={!disabled} className={classes.base} {...props}>
      <span
        className={classes.button}
        style={{
          background: !disabled ? '#ccc' : background,
          width: width,
          height: height,
          fontSize: fontSize,
          ...labelStyle,
        }}
      >
        {label}
        <span className={classes.description}>{description}</span>
      </span>
    </ButtonBase>
  </div>
);

export default withStyles(styles)(button);
