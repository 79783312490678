import { Box, ButtonBase } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { getConfig } from 'config/config';
import React, { FC } from 'react';

type Props = { item: any; name?: string; deleteItemHandler: any };

export const MultipleModalItem: FC<Props> = ({ item, name, deleteItemHandler }) => {
  let value = '';
  if (item?.firstname && item?.lastname) value = item.firstname + ' ' + item.lastname;
  value = item?.name || item?.value || item;
  const styles = useStyles({});
  return (
    <Box className={styles.itemContainer} key={value}>
      <Box className={styles.itemWrapper}>
        <span>{value}</span>
      </Box>
      <ButtonBase onClick={() => deleteItemHandler(item, name)} className={styles.deleteWrapper}>
        <Delete style={{ color: theme.ERROR_PRIMARY }} />
      </ButtonBase>
    </Box>
  );
};

const { theme } = getConfig();
const useStyles = makeStyles({
  inputContainer: {
    marginBottom: 20,
  },

  deleteWrapper: {
    backgroundColor: theme.BACKGROUND_PRIMARY,
    border: `1px solid ${theme.BACKGROUND_SECONDARY}`,
    padding: 8,
    borderRadius: 20,
  },
  itemWrapper: {
    backgroundColor: theme.BACKGROUND_PRIMARY,
    borderRadius: 20,
    border: `1px solid ${theme.BACKGROUND_SECONDARY}`,
    margin: 5,
    padding: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    display: 'flex',
  },
  itemContainer: {
    alignItems: 'center',
    paddingHorizontal: 5,
    display: 'flex',
  },
});
