import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { getConfig } from 'config/config';
import { cn } from 'utilities/utils';

const { theme } = getConfig();

interface Props {
  showLoader?: boolean;
  isLoading?: boolean;
  className?: string;
}

const Loader: React.FC<Props> = ({ showLoader, isLoading, className }) => {
  const loading = showLoader || isLoading;

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showLoader, isLoading]);

  return loading ? (
    <div
      className={cn(
        'absolute flex items-center justify-center top-0 left-0 bottom-0 right-0 z-[100] bg-white/20',
        className,
      )}
    >
      <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={35} />
    </div>
  ) : null;
};

export default Loader;
