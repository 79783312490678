import React, { useState } from 'react'
import { getConfig } from '../../../config/config'
import { useLanguage } from '../../../languages/languageContext'
import { imagePath } from '../../../utilities'
import { itemTypes } from '../MentoringSearchScreen'
import PdfFile from '../../../assets/icons/PdfFileIcon'
import { Box, ButtonBase } from '@material-ui/core'
import { useHistory } from 'react-router'

const defaultAvatar = require('../../../assets/default_avatar.png')
const searchedUser = require('../../../assets/searchedUser.png')

const { theme, config } = getConfig()
const styles = {
  container: {
    // width: '100%',
    display: 'flex',
    padding: '10px 15px',
    marginBottom: 10,

  },
  contentWrapper: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    display: 'flex',
  },
  imageWrapper: {
    justifyContent: 'flex-end',
    width: 60,
    height: 60,
    marginRight: 10,
    borderRadius: 30,
    // borderWidth: 2,
    // borderColor: theme.ICON_BORDER,
    border: `1px solid ${theme.ICON_BORDER}`,
    overflow: 'hidden',
    backgroundColor: theme.ICON_BACKGROUND,
  },
  heading: {
    alignSelf: 'center',
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start'
  },
  nameWrapper: {
    // flexDirection: 'row',
    flex: 1,
  },
  name: {
    fontSize: 16,
    flex: 1,
    width: '100%',
    color: theme.TEXT_PRIMARY,
  },
  corps: {
    fontSize: 12,
    textAlign: 'left',
    maxWidth: 200,
    color: theme.TEXT_PRIMARY,
  },
  iconWrapper: {
    position: 'relative',
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  addIconWrapper: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    right: -5,
    width: 16,
    height: 16,
    borderRadius: 8,
    zIndex: 10,
  },
  image: {
    width: '100%',
    height: '100%',
  },
}

interface Props {
  user: any
  searchEntries: any
}

export const MentorSearchItem: React.FC<Props> = ({ user, searchEntries }) => {
  const history = useHistory()
  const {
    mentoring: { profile },
  } = useLanguage()
  const [pdfState, setPdfState] = useState({ show: false })
  let image
  image = user.photo ? imagePath(null, user.photo) : null

  function goToProfile() {
    localStorage.setItem('mentoringRegions', JSON.stringify(searchEntries.mentoringRegions))
    window.open(`/mentoring/mentoring-profile/${user._id}`)
    // history.push(`/mentoring/mentoring-profile/${user._id}`, { user })
    // navigation.navigate('Details', {
    //   screen: 'Details',
    //   params: {
    //     userId: user._id,
    //     route: 'MentoringScreen',
    //   },
    // })
  }

  const RenderItem = ({ type }: { type: itemTypes }) => {
    if (!user[type]?.length) return <></>
    const translationType = type === 'mentoringRegions' ? 'reigonsShort' : type
    const translation =
      profile[translationType] === 'Themenfelder' ? 'Themen' : profile[translationType]
    const item =
      user[type].find((el: string) => el === searchEntries[type][0]) ||
      (user[type].includes('Alle') && searchEntries[type][0]) ||
      user[type][0]

    const itemCount = user[type]?.length
    const others = itemCount - 1 ? ` + ${itemCount - 1}` : ''
    return (
      <span>
        <span style={{ fontWeight: 'bolder' }}>{translation}</span>: {item}
        {others}
      </span>
    )
  }

  return (<>
    <Box style={styles.container}>
      <ButtonBase style={styles.contentWrapper} onClick={goToProfile}>
        <Box style={{ ...styles.imageWrapper, alignItems: image ? undefined : 'center', }}>
          {image ? (
            <img src={image} style={{ ...styles.image, objectFit: 'contain' }} />
          ) : (
            <img src={defaultAvatar} style={{ width: 60, height: 60, }} />
          )}
        </Box>
        <Box style={{ ...styles.heading, flexDirection: 'column', }}>
          <Box style={{ ...styles.nameWrapper, }}>
            <span style={styles.name}>
              {user.name} {user.lastname} {user.status ? `(${user.status})` : ''}
            </span>
          </Box>
          <RenderItem type="mentoringRegions" />
          <RenderItem type="topics" />
          <RenderItem type="departments" />
        </Box>
      </ButtonBase>
      {!!user?.mentorPdf?.length &&
        (<ButtonBase style={{ alignSelf: 'center' }} onClick={() => window.open(user.mentorPdf)}>
          <PdfFile fill={theme.ACTIVE_INPUT} />
        </ButtonBase>)}
    </Box>

  </>
  )
}
