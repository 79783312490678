export const paramsToObject = (entries) => {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

export type SearchFilterParams = {
  filter?: string;
  value?: string;
  optionalSource?: string;
  backToGlobal?: string;
};
export const handleFilterSearchParams = (searchString: string, cb: Function) => {
  const iterator = new URLSearchParams(searchString).entries();
  const params: SearchFilterParams = paramsToObject(iterator);
  const { filter, value, backToGlobal } = params;

  if (![value].some((item) => item === undefined)) {
    typeof cb === 'function' &&
      cb({
        filter,
        value,
        optionalSource: params.optionalSource ? params.optionalSource : null,
        backToGlobal: backToGlobal === 'true' ? true : false,
      });
  } else {
    typeof cb === 'function' && cb(undefined);
  }
};
