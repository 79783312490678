import React, { useEffect } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { withConfig, Theme, Config } from '../../config';
import { AlertModalProps } from '../model';

const styles = createStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(97, 97, 97, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 20,
    boxSizing: 'border-box',
  },
  modalWrapper: {
    position: 'relative',
    width: 362,
    paddingBottom: 15,
    borderRadius: 10,
    zIndex: 20,
  },
  heading: {
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    position: 'relative',
    padding: '20px 0 20px 0',
  },
  title: {
    textAlign: 'center',
    fontSize: 15,
    fontFamily: 'Poppins',
    margin: 0,
    fontWeight: 'bold',
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  inputWrapper: {
    padding: 15,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 5,
    outline: 'none',
    border: 'none',
  },
  messageWrapper: {
    padding: '20px 30px',
  },
  message: {
    textAlign: 'center',
    margin: 0,
    padding: 0,
    fontSize: 15,
    fontFamily: 'Roboto',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '10px 20px 0',
    boxSizing: 'border-box',
  },
});

interface Props extends WithStyles<typeof styles>, AlertModalProps {
  config: Config;
  title?: string;
  showCloseBtn?: boolean;
  children?: React.ReactNode;
}

const SearchableModal: React.FC<Props> = ({
  classes,
  children,
  theme,
  message = 'Some message',
  showModal,
  onClose,
  config,
  title,
  showCloseBtn,
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModal]);

  return message && showModal ? (
    <div className={classes.container}>
      <div className={classes.modalWrapper} style={{ background: theme.BACKGROUND }}>
        <div className={classes.heading} style={{ background: theme.BACKGROUND_SECONDARY }}>
          <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
            {title || config.APP_FULL_NAME}
          </h3>
          {showCloseBtn && (
            <div className={classes.buttonWrapper}>
              <ButtonBase style={{ borderRadius: 50 }} onClick={onClose}>
                <Close style={{ color: theme.BUTTON_SECONDARY }} />
              </ButtonBase>
            </div>
          )}
        </div>
        <div className={classes.messageWrapper}>
          <p className={classes.message} style={{ color: theme.TEXT_PRIMARY }}>
            {message}
          </p>
        </div>
        <div className={classes.buttonsWrapper}>{children}</div>
      </div>
      <div>
        <div className={classes.backdrop}></div>
      </div>
    </div>
  ) : null;
};

export default withStyles(styles)(withConfig(SearchableModal));
