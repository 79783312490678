import React, { FC, useEffect, useState } from 'react';
import DefaultModal from '../DefaultModal';
import Input from 'components/UI/Input';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { IonIcon } from 'components/UI/IonIcon';
import Button from 'components/UI/Button';
import { getConfig } from 'config/config';
import { privacyValueTypes } from 'scenes/ProfilePrivacy/ProfilePrivacy';
import { CheckboxUI } from 'components/UI/CheckboxUI';
import { useLanguage } from 'languages/languageContext';

const { theme } = getConfig();

export type TSurveyModal = {
  show: boolean;
  multiAnswer: boolean;
  options: {
    title: string;
    _id?: string;
    voters?: {
      user:
        | {
            _id: string;
            name: string;
            lastname: string;
            photo: string;
          }
        | string;
    }[];
  }[];
  question: string;
};

type Props = {
  signalToOpen: boolean;
  setSignalToOpen: (c: boolean) => void;
  state: TSurveyModal;
  setState: React.Dispatch<React.SetStateAction<TSurveyModal>>;
  initialState: TSurveyModal;
};

export const SurveyModal: FC<Props> = ({ signalToOpen, setSignalToOpen, state, setState, initialState }) => {
  const { surveys: lSurveys } = useLanguage();
  const styles = useStyles({});

  const canAddMore = !state.options.filter((opt) => !opt.title.length).length || state.options.length < 2;
  const submitDisabled =
    !(!state.question.length || state.options.filter((el) => !el.title.length).length) && state.options.length >= 2;

  useEffect(() => {
    if (signalToOpen) {
      setState({ ...state, show: true });
      setSignalToOpen(false);
    }
  }, [signalToOpen]);

  const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const newOptions = state.options;
    newOptions[idx].title = e.target.value;
    setState({ ...state, options: newOptions });
  };

  const onQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => setState({ ...state, question: e.target.value });

  const addOption = () => setState({ ...state, options: [...state.options, { title: '' }] });
  const deleteOption = (idx: number) =>
    setState({ ...state, options: state.options.filter((_, elidx) => idx !== elidx) });

  const closeModal = () => {
    setState(initialState);
  };

  const submitSurvey = () => {
    setState((c) => ({ ...c, show: false }));
  };

  return (
    <DefaultModal showModal={state.show} modalTitle={lSurveys.createModalTitle} onClose={closeModal}>
      <div style={{ padding: '0px 30px', overflowY: 'auto', maxHeight: '80vh' }}>
        <p className={styles.label}>{lSurveys.question}</p>
        <Input value={state.question} onChange={onQuestionChange} placeholder={lSurveys.askAQuestion} label=" " />
        <CheckboxUI
          isChecked={state.multiAnswer}
          setIsChecked={() => setState((c) => ({ ...c, multiAnswer: !c.multiAnswer }))}
          label={lSurveys.allowMultiple}
        />
        <p className={styles.label}>{lSurveys.optionsLabel}</p>
        {state.options.map((el, idx) => (
          <div style={{ display: 'flex', alignItems: 'center' }} key={el._id + idx}>
            <Input
              key={idx}
              value={el.title}
              onChange={(e) => onOptionChange(e, idx)}
              placeholder={lSurveys.option}
              label=" "
            />
            <ButtonBase className={styles.deleteOption} onClick={() => deleteOption(idx)}>
              <IonIcon name="remove-circle-outline" size={32} color="inherit" />
            </ButtonBase>
          </div>
        ))}
        {canAddMore && (
          <ButtonBase style={{ display: 'flex', margin: 'auto' }} onClick={addOption}>
            <IonIcon name="add-circle-outline" size={32} />
          </ButtonBase>
        )}
        <div className={styles.btnWrapper}>
          <Button label={lSurveys.create} onClick={submitSurvey} width={120} disabled={submitDisabled} />
        </div>
      </div>
    </DefaultModal>
  );
};

const useStyles = makeStyles({
  label: {
    fontSize: 16,
    marginBottom: 5,
    color: theme.TEXT_SECONDARY,
  },
  btnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 40,
  },
  deleteOption: {
    display: 'flex',
    margin: 'auto',
    marginBottom: 20,
    alignSelf: 'center',
    color: theme.ICON_BORDER,
    '&:hover': {
      color: theme.ERROR_PRIMARY,
    },
  },
});
