import { Box, ButtonBase, makeStyles } from '@material-ui/core';
import AlertModal from 'components/Shared/AlertModal';
import Button from 'components/UI/Button';
import React, { FC, useState } from 'react';
import { getConfig } from '../../../config/config';
import { useLanguage } from '../../../languages/languageContext';
import { IonIcon } from 'components/UI/IonIcon';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/shadcn/Dropdown';

type Props = {
  title: string;
  id: string;
  isPrivate: boolean | null;
  navigateToFolder: (id: string, title: string) => void;
  deleteFolder: (id: string) => void;
  showFolderModal: (id: string, title: string) => void;
  showDeleteButton: boolean;
  owner?: boolean;
};

export const FolderItem: FC<Props> = (props) => {
  const { downloads, btn } = useLanguage();
  const [showModal, setShowModal] = useState(false);
  const { title, id, isPrivate, navigateToFolder, deleteFolder, showFolderModal, showDeleteButton, owner } = props;
  const styles = useStyles({});

  const handleDeleteFolder = () => setShowModal(!showModal);

  return (
    <>
      <Box className={styles.wrapper}>
        <Box
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => navigateToFolder(id, title)}
        >
          <Box style={{ position: 'static', display: 'flex', width: 36, alignItems: 'center' }}>
            <IonIcon name="folder-outline" size={42} style={{ position: 'absolute' }} />
            {isPrivate && (
              <div className={styles.lockWrapper}>
                <IonIcon name="lock-open-outline" size={20} />
              </div>
            )}
          </Box>
          <span className={styles.title}>{title}</span>
        </Box>
        {(owner || showDeleteButton) && (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Box className={styles.threeDots}>
                <div className={styles.dot} />
                <div className={styles.dot} />
                <div className={styles.dot} />
              </Box>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mr-16 sm:mr-12 md:mr-14 lg:mr-20 w-56">
              <DropdownMenuGroup>
                {owner && (
                  <DropdownMenuItem onClick={() => showFolderModal(id, title)}>
                    <span className={styles.buttonText}>{downloads.renameFolder}</span>
                  </DropdownMenuItem>
                )}
                {(showDeleteButton || owner) && (
                  <DropdownMenuItem onClick={handleDeleteFolder}>
                    <span className={styles.buttonText}>{downloads.delete}</span>
                  </DropdownMenuItem>
                )}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </Box>
      <AlertModal showModal={showModal} modalHandler={handleDeleteFolder} message={downloads.areYouSureDeleteFolder}>
        <Button label={btn.cancelBtn} onClick={handleDeleteFolder} width={140} />
        <Button label={downloads.delete} onClick={() => deleteFolder(id)} width={140} />
      </AlertModal>
    </>
  );
};

const { theme } = getConfig();
const useStyles = makeStyles({
  wrapper: {
    height: 80,
    borderRadius: 10,
    border: `1.5px solid ${theme.BUTTON_PRIMARY}`,
    // flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 10px',
    display: 'flex',
    marginBottom: 10,
  },
  title: {
    fontSize: 18,
    color: theme.TEXT_PRIMARY,
    marginLeft: 20,
  },
  threeDots: {
    height: 45,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 10px',
    width: 50,
    borderRadius: 50,
    flexDirection: 'column',
    display: 'flex',
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 6,
    backgroundColor: theme.BUTTON_SECONDARY,
  },
  dropdown: {
    minWidth: 140,
    backgroundColor: theme.BACKGROUND_SECONDARY,
    zIndex: 100,
    right: 135,
    overflow: 'visible',
    borderRadius: 8,
    borderTopRightRadius: 0,
    padding: 5,
    top: -25,
    alignItems: 'flex-start',
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
  },
  button: {
    padding: 4,
    borderBottomColor: theme.BUTTON_SECONDARY,
    borderWidth: 0,
    borderBottomWidth: 0.5,
    border: 'solid',
    textAlign: 'left',
  },
  buttonText: {
    fontSize: 16,
    textAlign: 'left',
  },
  lockWrapper: {
    backgroundColor: theme.BACKGROUND,
    borderRadius: 20,
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    position: 'relative',
    bottom: -1,
    right: -11,
    zIndex: 1,
    overflow: 'visible',
  },
});
