import { ProfileItemRedirectTypes } from '../scenes/ProfileDetails/model';

const TabToFieldSet = {
  [ProfileItemRedirectTypes.Name]: ['wohnort', 'corpsname'],
  [ProfileItemRedirectTypes.Clubs]: ['corpsname', 'charge'],
  [ProfileItemRedirectTypes.Stadt]: ['corpsname', 'industry', 'beruf'],
  [ProfileItemRedirectTypes.Country]: ['wohnort', 'corpsname', 'beruf'],
  [ProfileItemRedirectTypes.Branche]: ['wohnort', 'company', 'beruf'],
  [ProfileItemRedirectTypes.Firma]: ['wohnort', 'corpsname', 'beruf'],
  [ProfileItemRedirectTypes.Activity]: ['wohnort', 'corpsname', 'beruf'],
  [ProfileItemRedirectTypes.Beruf]: ['wohnort', 'corpsname', 'industry'],
  [ProfileItemRedirectTypes.Interessen]: ['wohnort', 'corpsname', 'beruf'],
  [ProfileItemRedirectTypes.Studiengang]: ['hochschule', 'wohnort', 'corpsname'],
  [ProfileItemRedirectTypes.Hochschule]: ['studiengang', 'wohnort', 'corpsname'],
  [ProfileItemRedirectTypes.PostCode]: ['postcode', 'wohnort', 'corpsname'],
  [ProfileItemRedirectTypes.Function]: ['corp'],

  //non common tabs
};

export const getUserFieldProps = (tab: ProfileItemRedirectTypes): string[] => TabToFieldSet[tab];
