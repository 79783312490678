import React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';

import { withConfig } from 'config/';
import defaultImg from 'assets/default_avatar.png';

import { ProfileAvatarProps } from '../model';

const styles = createStyles({
  imgWrapper: {
    width: 120,
    height: 120,
    borderRadius: 60,
    borderWidth: '1px',
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginBottom: 20,
    cursor: 'pointer',
  },
  img: {
    width: '100%',
    height: '100%',
  },
});

interface Props extends WithStyles<typeof styles>, ProfileAvatarProps {}

const ProfileAvatar: React.FC<Props> = ({ classes, theme, image, onClick }) => (
  <div className={classes.imgWrapper} style={{ borderColor: theme.NAV_BAR }} onClick={image ? onClick : () => {}}>
    <img src={image || defaultImg} className={classes.img} alt=" " />
  </div>
);

export default withStyles(styles)(withConfig(ProfileAvatar));
