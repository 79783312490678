import { Box, ButtonBase, Modal } from "@material-ui/core";
import CloseIcon from "assets/icons/CloseIcon";
import DefaultModal from "components/Shared/DefaultModal";
import Input from "components/UI/Input";
import React, { FC, useEffect, useState } from "react";
import { getConfig } from "../../../config/config";
import { useLanguage } from "../../../languages/languageContext";


const { theme } = getConfig()
type Props = {
  modalHandler: () => void
  showModal: boolean
  title?: string
  createNewFolder: (title: string) => void
  mode: 'renameFile' | 'createFolder' | 'renameFolder' | null
  renameFolder: (id: string, title: string) => void
  renameFile: (id: string, title: string) => void
  id?: string
}
export const FolderModal: FC<Props> = ({ modalHandler, showModal, title = '', createNewFolder, mode, renameFolder, id, renameFile }) => {
  const { downloads, btn } = useLanguage()
  const [newTitle, setNewTitle] = useState(title)

  useEffect(() => {
    if (title.length) return setNewTitle(title)
    setNewTitle('')
  }, [title, mode])

  return <DefaultModal showModal={showModal} onClose={modalHandler} modalTitle={mode === 'createFolder' ? downloads.createNewFolder : downloads.renameFolder}>
    <Box style={styles.modal}>
      <Input
        value={newTitle}
        onChange={(e) => {
          setNewTitle(e.target.value)
        }}
        placeholder={downloads.newFolderPlaceholder}
        style={styles.input}
        selectTextOnFocus
      />
      <Box style={styles.buttonWrapper}>
        <ButtonBase style={styles.button} onClick={modalHandler}>
          <span style={styles.buttonText}>{downloads.cancel}</span>
        </ButtonBase>
        {mode === 'createFolder' && <ButtonBase style={styles.button} disabled={!newTitle?.length} onClick={() => createNewFolder(newTitle)}>
          <span style={styles.buttonText}>{downloads.create}</span>
        </ButtonBase>}
        {mode === 'renameFolder' && <ButtonBase style={styles.button} disabled={!newTitle?.length} onClick={() => renameFolder(id, newTitle)}>
          <span style={styles.buttonText}>{downloads.renameFolder}</span>
        </ButtonBase>}
        {mode === 'renameFile' && <ButtonBase style={styles.button} disabled={!newTitle?.length} onClick={() => renameFile(id, newTitle)}>
          <span style={styles.buttonText}>{downloads.rename}</span>
        </ButtonBase>}
      </Box>
    </Box>
  </DefaultModal>
}

const styles = {
  container: {
    flex: 1,
    zIndex: 10,
    backgroundColor: 'rgba(97, 97, 97, .9)',
    paddingTop: 40,
  },
  wrapper: {
    height: '100%',
    width: '100%',
    zIndex: 30,
    borderRadius: 10,
    paddingBottom: 20,
    justifyContent: 'center'
  },
  closeButton: {
    top: 20,
    right: 20,
    width: 30,
    height: 30,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
    backgroundColor: theme.BACKGROUND_SECONDARY,
  },
  modal: {
    // width: '80%',
    // maxWidth: 340,
    // height: 150,
    alignSelf: 'center',
    borderRadius: 20,
    alignItems: 'center',
    paddingVertical: 15,
    justifyContent: 'space-between',
    padding: '0 30px'
  },
  title: {
    fontSize: 18,
    color: theme.TEXT_PRIMARY,
  },
  input: {
    fontSize: 18,
    borderWidth: 2,
    padding: 10,
    borderRadius: 5,
    borderColor: theme.ACTIVE_INPUT,
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginLeft: 20,
    backgroundColor: theme.BUTTON_PRIMARY,
    // padding: '15px 6px',
    padding: 6,
    borderRadius: 5,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16
  }
}