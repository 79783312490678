// GET CHAT USERS
export const GET_CHAT_USERS_REQUEST = 'GET_CHAT_USERS_REQUEST';
export const GET_CHAT_USERS_SUCCESS = 'GET_CHAT_USERS_SUCCESS';
export const GET_CHAT_USERS_FAILURE = 'GET_CHAT_USERS_FAILURE';

// CLEAR CHAT USERS
export const CLEAR_CHAT_USERS = 'CLEAR_CHAT_USERS';

// MARK AS READ
export const MARK_MESSAGE_AS_READ_REQUEST = 'MARK_MESSAGE_AS_READ_REQUEST';
export const MARK_MESSAGE_AS_READ_SUCCESS = 'MARK_MESSAGE_AS_READ_SUCCESS';
export const MARK_MESSAGE_AS_READ_FAILURE = 'MARK_MESSAGE_AS_READ_FAILURE';

// GET DIALOG
export const GET_DIALOG_REQUEST = 'GET_DIALOG_REQUEST';
export const GET_DIALOG_SUCCESS = 'GET_DIALOG_SUCCESS';
export const GET_DIALOG_FAILURE = 'GET_DIALOG_FAILURE';

// CLEAR DIALOG
export const CLEAR_DIALOG = 'CLEAR_DIALOG';

// SEND MESSAGE
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';

// UNREAD MESSAGES
export const UNREAD_MESSAGES_REQUEST = 'UNREAD_MESSAGES_REQUEST';
export const UNREAD_MESSAGES_SUCCESS = 'UNREAD_MESSAGES_SUCCESS';
export const UNREAD_MESSAGES_FAILURE = 'UNREAD_MESSAGES_FAILURE';

export const CLEAR_UNREAD_MESSAGES = 'CLEAR_UNREAD_MESSAGES';
