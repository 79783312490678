import React, { useState, useEffect } from 'react';
import { ButtonBase, CircularProgress } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { withConfig, Theme } from '../../config';
// import PostLike from '../components/PostLike';
import { useSelector, useDispatch } from 'react-redux';
// import { getPostLikesRequest } from '../../store/actions/';
import InfiniteScroll from 'react-infinite-scroller';
import Like from './LikeComponent';
import MembersComponent from './MembersComponent';

const styles = createStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(97, 97, 97, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 1500,
    overflowX: 'hidden', //horizontal
    overflowY: 'hidden', //horizontal
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > div::-webkit-scrollbar': {
      display: 'none',
    },
  },
  modalWrapper: {
    position: 'relative',
    width: 520,
    paddingBottom: 10,
    borderRadius: 10,
    zIndex: 20,
    overflowX: 'hidden', //horizontal
    overflowY: 'hidden', //horizontal
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > div::-webkit-scrollbar': {
      display: 'none',
    },
    '@media(max-width: 650px)': {
      width: '90%',
    },
  },
  heading: {
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    position: 'relative',
    padding: '20px 0 20px 0',
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Roboto',
    margin: 0,
    letterSpacing: '0.1',
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  scrollWrapper: {
    height: 400,
    width: '100%',
    overflow: 'scroll',
    paddingTop: 20,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 5,
    outline: 'none',
    border: 'none',
  },
  progressWrapper: {
    width: '100%',
    padding: '100px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  showModal: boolean;
  modalHandler: () => void;
  getDataHandler: (id: string, page: number, limit: number) => void;
  modalTitle: string;
  modalName: string;
  data: any;
  item: any;
  items: Array<any>;
  itemsCount: number;
  loader: boolean;
  backRoute?: string;
  isCreator: boolean;
  type?: 'likes' | 'members';
  registrationType?: boolean;
}

const DataModal: React.FC<Props> = ({
  classes,
  showModal,
  modalHandler,
  theme,
  modalTitle,
  item,
  getDataHandler,
  items,
  itemsCount,
  loader,
  isCreator,
  type = 'likes',
  registrationType,
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    dispatch(getDataHandler(item._id, 1, 10));
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  // PROPS
  const dispatch = useDispatch();

  const getMoreDataHandler = () => {
    if (!loader) {
      const nextPage = pageNumber + 1;
      setPageNumber(nextPage);

      if (pageNumber > 1 && !loader) {
        dispatch(getDataHandler(item._id, pageNumber, 10));
      }
    }
  };

  return (
    <div className={classes.container} key={item._id || modalTitle}>
      <div className={classes.modalWrapper} style={{ background: theme.BACKGROUND }}>
        <div className={classes.heading} style={{ background: theme.BACKGROUND_SECONDARY }}>
          <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
            {modalTitle}
          </h3>
          <div className={classes.buttonWrapper}>
            <ButtonBase onClick={modalHandler} style={{ borderRadius: 50 }}>
              <Close style={{ color: theme.BUTTON_SECONDARY }} />
            </ButtonBase>
          </div>
        </div>
        <div className={classes.scrollWrapper} style={{ background: theme.BACKGROUND }}>
          {items.length >= itemsCount && loader ? (
            <div className={classes.progressWrapper}>
              <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={35} />
            </div>
          ) : (
            <InfiniteScroll
              threshold={50}
              initialLoad={false}
              pageStart={0}
              loadMore={getMoreDataHandler}
              hasMore={!(items.length >= itemsCount)}
              loader={
                <div style={{ textAlign: 'center' }} key={50}>
                  <CircularProgress
                    style={{
                      color: theme.BUTTON_SECONDARY,
                    }}
                    size={25}
                  />
                </div>
              }
              useWindow={false}
            >
              {items &&
                items.length > 0 &&
                items.map((like: any, i: number) => {
                  if (type === 'likes') return <Like key={like._id + 1 + i} like={like} showGuestEmail={isCreator} />;
                  if (type === 'members')
                    return (
                      <MembersComponent
                        registrationType={registrationType}
                        key={like._id + 1 + i}
                        like={like}
                        showGuestEmail={isCreator}
                      />
                    );
                })}
            </InfiniteScroll>
          )}
        </div>
      </div>
      <div className={classes.backdrop} onClick={modalHandler}></div>
    </div>
  );
};

export default withStyles(styles)(withConfig(DataModal));
