import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="500.000000pt"
      height="500.000000pt"
      viewBox="0 0 500.000000 500.000000"
      {...props}
    >
      <path
        d="M1790 3332c-46-47-56-121-35-271 29-211 91-411 190-617 78-161 88-226 84-534l-4-245 90-6c152-11 1016-22 1089-15l69 8-7 101c-18 291-145 533-322 618-44 21-104 36-212 54-249 42-434 104-525 179-66 54-127 144-155 230-13 38-34 141-48 229-32 211-38 229-79 266-47 41-95 42-135 3z"
        transform="matrix(.1 0 0 -.1 0 500)"
      />
      <path
        d="M2525 3227c-95-32-160-85-206-169-32-60-34-68-34-163 0-112 14-151 83-227 134-148 356-155 496-15 56 56 83 108 95 183 33 208-115 391-323 401-41 1-88-3-111-10zM1414 3000c-89-28-181-123-205-213-15-57-6-154 19-202 45-89 141-155 244-170 96-14 228 50 281 136l28 47-26 78c-15 44-37 123-50 177s-25 100-26 101c-2 2-24 14-49 26-51 26-163 36-216 20zM3409 2996c-122-43-198-141-206-266-9-125 50-226 165-282 55-27 78-33 132-33 112 1 218 67 273 170 17 32 22 58 22 125 0 76-3 91-30 139-70 126-230 192-356 147zM1425 2330c-215-29-405-205-450-419-8-40-15-101-15-137v-64l488 2 487 3 3 198 2 198-51 54c-121 128-290 188-464 165zM3420 2330c-67-10-161-44-191-69l-22-18 22-31c63-90 119-268 128-414l6-88h677v63c0 163-52 292-162 401-127 125-291 181-458 156z"
        transform="matrix(.1 0 0 -.1 0 500)"
      />
    </svg>
  )
}

export default SvgComponent
