import { ReactElement, ReactNodeArray } from 'react';
import { SHOW_RESPONSE, HIDE_RESPONSE } from '../../constants';

export const showResponse = (
  response: {
    message?: string | ReactElement;
    title?: string;
    options?: ReactNodeArray;
    immediately?: boolean;
    cancelBtnLabel?: string;
    route?: any;
    messageAlignLeft?: boolean;
    onClick?: () => void;
  },
  route: string | null = null,
) => ({
  type: SHOW_RESPONSE,
  response: {
    ...response,
    route,
  },
});

export const hideResponse = () => ({
  type: HIDE_RESPONSE,
});
