import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { ButtonBase, CircularProgress } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import Close from '@material-ui/icons/Close';

import { getCommentsLikesRequest } from 'store/actions/likes';
import { withConfig, Theme } from 'config';
import Like from 'components/Shared/LikeComponent';

const styles = createStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(97, 97, 97, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 15,
    overflowX: 'hidden', //horizontal
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  modalWrapper: {
    position: 'relative',
    width: 520,
    paddingBottom: 10,
    borderRadius: 10,
    zIndex: 20,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > div::-webkit-scrollbar': {
      display: 'none',
    },
    '@media(max-width: 650px)': {
      width: '90%',
    },
    overflowX: 'hidden', //horizontal
  },
  heading: {
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    position: 'relative',
    padding: '20px 0 20px 0',
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Roboto',
    margin: 0,
    letterSpacing: '0.1',
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  scrollWrapper: {
    height: 400,
    width: '100%',
    overflow: 'scroll',
    paddingTop: 20,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 5,
    outline: 'none',
    border: 'none',
  },
  progressWrapper: {
    width: '100%',
    padding: '100px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  onClose: () => void;
  modalTitle: string;
  comment: any;
}

const CommentLikesModal: React.FC<Props> = ({ classes, theme, onClose, modalTitle, comment }) => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    dispatch(getCommentsLikesRequest(comment._id, 1, 10));
  }, []);

  // PROPS
  const { commentsLikes, commentsLikesCount, commentsLikesIsLoading } = useSelector((state: any) => state.likes);
  const dispatch = useDispatch();

  const getCommentsLikesHandler = () => {
    if (!commentsLikesIsLoading) {
      const nextPage = pageNumber + 1;
      setPageNumber(nextPage);

      if (pageNumber > 1 && !commentsLikesIsLoading) {
        dispatch(getCommentsLikesRequest(comment._id, pageNumber, 10));
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.modalWrapper} style={{ background: theme.BACKGROUND }}>
        <div className={classes.heading} style={{ background: theme.BACKGROUND_SECONDARY }}>
          <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
            {modalTitle}
          </h3>
          <div className={classes.buttonWrapper}>
            <ButtonBase onClick={onClose} style={{ borderRadius: 50 }}>
              <Close style={{ color: theme.BUTTON_SECONDARY }} />
            </ButtonBase>
          </div>
        </div>
        <div className={classes.scrollWrapper} style={{ background: theme.BACKGROUND }}>
          {commentsLikes.length >= commentsLikesCount && commentsLikesIsLoading ? (
            <div className={classes.progressWrapper}>
              <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={35} />
            </div>
          ) : (
            <InfiniteScroll
              threshold={50}
              initialLoad={false}
              pageStart={0}
              loadMore={getCommentsLikesHandler}
              hasMore={!(commentsLikes.length >= commentsLikesCount)}
              loader={
                <div style={{ textAlign: 'center' }} key={50}>
                  <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
                </div>
              }
              useWindow={false}
            >
              {commentsLikes &&
                commentsLikes.length > 0 &&
                commentsLikes.map((like: any, i: number) => <Like key={like._id + 2 + i} like={like} />)}
            </InfiniteScroll>
          )}
        </div>
      </div>
      <div className={classes.backdrop} onClick={onClose}></div>
    </div>
  );
};

export default withStyles(styles)(withConfig(CommentLikesModal));
