import {
  GET_POST_LIKES_REQUEST,
  GET_POST_LIKES_SUCCESS,
  GET_POST_LIKES_FAILURE,
  GET_COMMENTS_LIKES_REQUEST,
  GET_COMMENTS_LIKES_SUCCESS,
  GET_COMMENTS_LIKES_FAILURE,
  CLEAR_POST_LIKES,
  CLEAR_COMMENTS_LIKES,
  LIKE_POST_REQUEST,
  LIKE_POST_SUCCESS,
  LIKE_POST_FAILURE,
  UNLIKE_POST_REQUEST,
  UNLIKE_POST_SUCCESS,
  UNLIKE_POST_FAILURE,
  LIKE_COMMENT_REQUEST,
  LIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_FAILURE,
  UNLIKE_COMMENT_REQUEST,
  UNLIKE_COMMENT_SUCCESS,
  UNLIKE_COMMENT_FAILURE,
  GET_EVENT_LIKES_REQUEST,
  GET_EVENT_LIKES_SUCCESS,
  GET_EVENT_LIKES_FAILURE,
  CLEAR_EVENT_LIKES,
  LIKE_EVENT_REQUEST,
  LIKE_EVENT_SUCCESS,
  LIKE_EVENT_FAILURE,
  UNLIKE_EVENT_REQUEST,
  UNLIKE_EVENT_SUCCESS,
  UNLIKE_EVENT_FAILURE,
  LIKE_EVENT_COMMENT_REQUEST,
  LIKE_EVENT_COMMENT_SUCCESS,
  LIKE_EVENT_COMMENT_FAILURE,
  UNLIKE_EVENT_COMMENT_REQUEST,
  UNLIKE_EVENT_COMMENT_SUCCESS,
  UNLIKE_EVENT_COMMENT_FAILURE,
  GET_EVENT_COMMENT_LIKES_REQUEST,
  GET_EVENT_COMMENT_LIKES_SUCCESS,
  GET_EVENT_COMMENT_LIKES_FAILURE,
  CLEAR_EVENT_COMMENT_LIKES,
} from '../../constants';

// GET POST LIKES
export const getPostLikesRequest = (postId: string, page: number, limit: number) => ({
  type: GET_POST_LIKES_REQUEST,
  postId,
  page,
  limit,
});

export const getPostLikesSuccess = (likes: Array<any>, total: number) => ({
  type: GET_POST_LIKES_SUCCESS,
  likes,
  total,
});

export const getPostLikesFailure = (error: object) => ({
  type: GET_POST_LIKES_FAILURE,
  error,
});

// GET COMMENTS LIKES
export const getCommentsLikesRequest = (postId: string, page: number, limit: number) => ({
  type: GET_COMMENTS_LIKES_REQUEST,
  postId,
  page,
  limit,
});

export const getCommentsLikesSuccess = (likes: Array<any>, total: number) => ({
  type: GET_COMMENTS_LIKES_SUCCESS,
  likes,
  total,
});

export const getCommentsLikesFailure = (error: object) => ({
  type: GET_COMMENTS_LIKES_FAILURE,
  error,
});

// CLEAR LIKES
export const clearPostLikes = () => ({
  type: CLEAR_POST_LIKES,
});

export const clearCommentsLikes = () => ({
  type: CLEAR_COMMENTS_LIKES,
});

// LIKE POST
export const likePostRequest = (liked_user: string, post_id: string) => ({
  type: LIKE_POST_REQUEST,
  liked_user,
  post_id,
});

export const likePostSuccess = (post: object) => ({
  type: LIKE_POST_SUCCESS,
  post,
});

export const likePostFailure = (error: object) => ({
  type: LIKE_POST_FAILURE,
  error,
});

// UNLIKE POST
export const unlikePostRequest = (liked_user: string, post_id: string) => ({
  type: UNLIKE_POST_REQUEST,
  liked_user,
  post_id,
});

export const unlikePostSuccess = (post: object) => ({
  type: UNLIKE_POST_SUCCESS,
  post,
});

export const unlikePostFailure = (error: object) => ({
  type: UNLIKE_POST_FAILURE,
  error,
});

// LIKE COMMENT
export const likeCommentRequest = (liked_user: string, comment_id: string) => ({
  type: LIKE_COMMENT_REQUEST,
  liked_user,
  comment_id,
});

export const likeCommentSuccess = (post: object) => ({
  type: LIKE_COMMENT_SUCCESS,
  post,
});

export const likeCommentFailure = (error: object) => ({
  type: LIKE_COMMENT_FAILURE,
  error,
});

// UNLIKE COMMENT
export const unlikeCommentRequest = (liked_user: string, comment_id: string) => ({
  type: UNLIKE_COMMENT_REQUEST,
  liked_user,
  comment_id,
});

export const unlikeCommentSuccess = (post: object) => ({
  type: UNLIKE_COMMENT_SUCCESS,
  post,
});

export const unlikeCommentFailure = (error: object) => ({
  type: UNLIKE_COMMENT_FAILURE,
  error,
});

// GET EVENT LIKES
export const getEventLikesRequest = (eventId: string, page: number, limit: number) => ({
  type: GET_EVENT_LIKES_REQUEST,
  eventId,
  page,
  limit,
});

export const getEventLikesSuccess = (eventLikes: Array<any>, total: number) => ({
  type: GET_EVENT_LIKES_SUCCESS,
  eventLikes,
  total,
});

export const getEventLikesFailure = (error: object) => ({
  type: GET_EVENT_LIKES_FAILURE,
  error,
});

export const clearEventLikes = () => ({
  type: CLEAR_EVENT_LIKES,
});

// LIKE EVENT
export const likeEventRequest = (eventId: string, userId: string) => ({
  type: LIKE_EVENT_REQUEST,
  eventId,
  userId,
});

export const likeEventSuccess = (like: object) => ({
  type: LIKE_EVENT_SUCCESS,
  like,
});

export const likeEventFailure = (error: object) => ({
  type: LIKE_EVENT_FAILURE,
  error,
});

// UNLIKE EVENT
export const unlikeEventRequest = (eventId: string, userId: string) => ({
  type: UNLIKE_EVENT_REQUEST,
  eventId,
  userId,
});

export const unlikeEventSuccess = (like: object) => ({
  type: UNLIKE_EVENT_SUCCESS,
  like,
});

export const unlikeEventFailure = (error: object) => ({
  type: UNLIKE_EVENT_FAILURE,
  error,
});

// LIKE EVENT COMMENT
export const likeEventCommentRequest = (eventId: string, userId: string, commentId: string) => ({
  type: LIKE_EVENT_COMMENT_REQUEST,
  eventId,
  userId,
  commentId,
});

export const likeEventCommentSuccess = (comment: string) => ({
  type: LIKE_EVENT_COMMENT_SUCCESS,
  comment,
});

export const likeEventCommentFailure = (error: object) => ({
  type: LIKE_EVENT_COMMENT_FAILURE,
  error,
});

// UNLIKE EVENT COMMENT
export const unlikeEventCommentRequest = (eventId: string, userId: string, commentId: string) => ({
  type: UNLIKE_EVENT_COMMENT_REQUEST,
  eventId,
  userId,
  commentId,
});

export const unlikeEventCommentSuccess = (comment: string) => ({
  type: UNLIKE_EVENT_COMMENT_SUCCESS,
  comment,
});

export const unlikeEventCommentFailure = (error: object) => ({
  type: UNLIKE_EVENT_COMMENT_FAILURE,
  error,
});

// GET EVENT COMMENT LIKES
export const getEventCommentLikesRequest = (commentId: string, page: number, limit: number) => ({
  type: GET_EVENT_COMMENT_LIKES_REQUEST,
  commentId,
  page,
  limit,
});

export const getEventCommentLikesSuccess = (likes: Array<object>, total: number) => ({
  type: GET_EVENT_COMMENT_LIKES_SUCCESS,
  likes,
  total,
});

export const getEventCommentLikesFailure = (error: object) => ({
  type: GET_EVENT_COMMENT_LIKES_FAILURE,
  error,
});

export const clearEventCommentLikes = () => ({
  type: CLEAR_EVENT_COMMENT_LIKES,
});
