import React from 'react';
import { withConfig } from '../../../config/';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';
import { DrawerLinkProps } from '../model';
import { ReactComponent as LogOutIcon } from '../assets/logout.svg';
import { ButtonBase } from '@material-ui/core';
import { useLanguage } from 'languages/languageContext';

const styles = createStyles({
  base: {
    width: '100%',
  },
  container: {
    width: '100%',
    cursor: 'pointer',
    padding: '10px 20px',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 12,
  },
  button: {
    marginLeft: 10,
    fontSize: 12,
    fontFamily: 'Poppins',
  },
});

interface Props extends WithStyles<typeof styles>, DrawerLinkProps {
  handleLogOut: () => void;
}

const DrawerLogOut: React.FC<Props> = ({ classes, theme, handleLogOut }) => {
  const { drawer } = useLanguage();

  return (
    <>
      <ButtonBase className={classes.base} onClick={handleLogOut}>
        <div className={classes.container}>
          <div className={classes.buttonWrapper}>
            <LogOutIcon />
            <span className={classes.button} style={{ color: theme.TEXT_PRIMARY }}>
              {drawer.logout}
            </span>
          </div>
        </div>
      </ButtonBase>
    </>
  );
};

export default withStyles(styles)(withConfig(DrawerLogOut));
