import { SET_CURRENT_REQUEST } from './../../constants/requests';
import {
  GET_USER_REQUESTS_REQUEST,
  GET_USER_REQUESTS_SUCCESS,
  GET_USER_REQUESTS_FAILURE,
  CLEAR_USER_REQUESTS,
  GET_REQUEST_REQUEST,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_FAILURE,
  CLEAR_REQUEST,
  HANDLE_REQUEST_REQUEST,
  HANDLE_REQUEST_SUCCESS,
  HANDLE_REQUEST_FAILURE,
  SEND_REQUEST_REQUEST,
  SEND_REQUEST_SUCCESS,
  SEND_REQUEST_FAILURE,
  SEND_REPORT_REQUEST,
  SEND_REPORT_SUCCESS,
  SEND_REPORT_FAILURE,
  DECLINE_REQUEST_REQUEST,
  DECLINE_REQUEST_SUCCESS,
  DECLINE_REQUEST_FAILURE,
} from '../../constants/';

// GET USER REQUESTS
export const getUserRequestsRequest = () => ({
  type: GET_USER_REQUESTS_REQUEST,
});

export const getUserRequestsSuccess = (outRequests: Array<any>, incRequests: Array<any>) => ({
  type: GET_USER_REQUESTS_SUCCESS,
  outRequests,
  incRequests,
});

export const getUserRequestsFailure = (error: object) => ({
  type: GET_USER_REQUESTS_FAILURE,
  error,
});

// CLEAR REQUESTS
export const clearUserRequests = () => ({
  type: CLEAR_USER_REQUESTS,
});

// GET REQUEST
export const getRequestRequest = (requestType: string, requestId: string, onRequestFailure: any) => ({
  type: GET_REQUEST_REQUEST,
  requestType,
  requestId,
  onRequestFailure,
});

export const getRequestSuccess = (request: object) => ({
  type: GET_REQUEST_SUCCESS,
  request,
});

export const getRequestFailure = (error: object) => ({
  type: GET_REQUEST_FAILURE,
  error,
});

export const clearRequest = () => ({
  type: CLEAR_REQUEST,
});

// HANDLE REQUEST
export const handleRequestRequest = (requestType: string, requestId: string) => ({
  type: HANDLE_REQUEST_REQUEST,
  requestType,
  requestId,
});

export const handleRequestSuccess = () => ({
  type: HANDLE_REQUEST_SUCCESS,
});

export const handleRequestFailure = (error: object) => ({
  type: HANDLE_REQUEST_FAILURE,
  error,
});

// SEND REQUEST
export const sendRequestRequest = (requestId: string, message: string, showMessageRequest?: string) => ({
  type: SEND_REQUEST_REQUEST,
  requestId,
  message,
  showMessageRequest
});

export const sendRequestSuccess = () => ({
  type: SEND_REQUEST_SUCCESS,
});

export const sendRequestFailure = (error: object) => ({
  type: SEND_REQUEST_FAILURE,
  error,
});

// SEND REPORT
export const sendReportRequest = (report: object, reportId: string) => ({
  type: SEND_REPORT_REQUEST,
  report,
  reportId,
});

export const sendReportSuccess = () => ({
  type: SEND_REPORT_SUCCESS,
});

export const sendReportFailure = (error: object) => ({
  type: SEND_REPORT_FAILURE,
});

// DECLINE REQUEST
export const declineRequestRequest = (requestId: string) => ({
  type: DECLINE_REQUEST_REQUEST,
  requestId,
});

export const declineRequestSuccess = () => ({
  type: DECLINE_REQUEST_SUCCESS,
});

export const declineRequestFailure = (error: object) => ({
  type: DECLINE_REQUEST_FAILURE,
  error,
});

export const setCurrentRequest = (request: any) => ({
  type: SET_CURRENT_REQUEST,
  payload: request,
});
