import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import Linkify from 'react-linkify';
import { withConfig, Theme } from '../../../config';
import linkDecorator from '../../../components/UI/linkDecorator';
import Content from 'components/UI/Content';
import { getConfig } from 'config/config';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    width: '100%',
    boxSizing: 'border-box',
  },
  contentWrapper: {
    width: '100%',
    // marginBottom: 20,
  },
  text: {
    marginTop: 16,
    fontFamily: 'Roboto',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    color: theme.TEXT_SECONDARY,
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  news: any;
}

const PostContent: React.FC<Props> = ({ classes, theme, news }) => {
  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        <Content content={news.content} className={classes.text} />
      </div>
    </div>
  );
};

export default withStyles(styles)(withConfig(PostContent));
