import React from 'react';
import { withStyles, WithStyles, createStyles, ButtonBase } from '@material-ui/core';
import RemoveCircle from '@material-ui/icons/HighlightOff';
import { withConfig, Theme } from '../../../config/';

const styles = createStyles({
  container: {
    width: '100%',
    position: 'relative',
    marginBottom: 20,
  },
  addButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 10,
  },
  input: {
    border: 'none',
    fontSize: 16,
    fontFamily: 'Roboto',
    outline: 'none',
    width: '85%',
  },
  inputWrapper: {
    width: '100%',
    position: 'relative',
    padding: '0 0 15px',
    borderBottom: '1px solid',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  value: string;
  onRemoveItem: () => void;
  onChangeHandler: () => void;
}

const SonstigenInput: React.FC<Props> = ({ classes, theme, value, onRemoveItem, onChangeHandler }) => {
  return (
    <div className={classes.container}>
      <div className={classes.inputWrapper} style={{ borderColor: theme.BACKGROUND_SECONDARY }}>
        <div className={classes.addButtonContainer}>
          <ButtonBase style={{ borderRadius: 20, fontSize: 30, background: theme.BACKGROUND }} onClick={onRemoveItem}>
            <RemoveCircle style={{ color: theme.BUTTON_SECONDARY }} fontSize="inherit" />
          </ButtonBase>
        </div>
        <input
          type="text"
          value={value}
          className={classes.input}
          style={{ color: theme.TEXT_PRIMARY }}
          onChange={onChangeHandler}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(withConfig(SonstigenInput));
