import React, { ReactNode } from 'react';
import { WithStyles, withStyles, createStyles, ButtonBase, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { withConfig } from '../../config/';
import { ScreenTitleProps } from '../model';
import { ReactComponent as MenuIcon } from '../../assets/menu.svg';
import { UploadMedia } from './UploadMedia';
import { getConfig } from 'config/config';
import { useAppSelector } from 'store';
import { openMenu } from 'store/actions/layout';
import { PlusIcon } from '@radix-ui/react-icons';

const { theme } = getConfig();

const styles = createStyles({
  container: {
    top: 0,
    position: 'sticky',
    width: '100%',
    // left: 0,
    // right: 0,
    // paddingLeft: 'calc(25% + 30px)',
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.BACKGROUND_SECONDARY,
    // paddingRight: '1.5%',
  },
  menuButtonWrapper: {
    display: 'none',
    left: 0,
    top: 3,
    padding: 13,
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: 30,
    textAlign: 'left',
    margin: 0,
    fontWeight: 'bolder',
    padding: '5px 0',
  },
  notificationWrapper: {
    width: 10,
    height: 10,
    borderRadius: 5,
    position: 'absolute',
    top: 10,
    right: 10,
    background: theme.ERROR_PRIMARY,
  },
  notification: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  addButton: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    marginRight: '15px',
    justifyContent: 'center',
  },
  rightBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 50
  },
  '@media(max-width: 1024px)': {
    container: {
      paddingLeft: 'calc(28% + 30px)',
      paddingRight: 'unset',
    },
  },
  '@media (max-width: 650px)': {
    container: {
      width: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      padding: 0,
      zIndex: 3,
      paddingRight: 'unset',
    },
    title: {
      textAlign: 'center' as any,
      margin: '5px 0',
      fontSize: 25,
      alignSelf: 'center',
    },
    menuButtonWrapper: {
      display: 'block',
    },
  },
});

interface Props extends WithStyles<typeof styles>, ScreenTitleProps {
  addEvent?: () => void;
  showAddIcon?: boolean;
  uploadMedia?: boolean;
  rightBlock?: ReactNode;
  hideMenuBtn?: boolean;
}

const ScreenTitle: React.FC<Props> = ({
  classes,
  title,
  addEvent,
  showAddIcon = false,
  uploadMedia,
  rightBlock,
  hideMenuBtn = false,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.users.user);
  const unreadMessages = useSelector((state: any) => state.chat.unreadMessages);
  const contactRequests = user && user.pending_contact_request && user.pending_contact_request.length > 0;
  const messageNotifications = unreadMessages && unreadMessages.length > 0;
  const { isWidget } = useAppSelector((state) => state.common.widget);

  return (
    <div className={classes.container} style={{ paddingLeft: isWidget ? 15 : undefined }}>
      {!hideMenuBtn && (
        <ButtonBase onClick={() => dispatch(openMenu())} className={classes.menuButtonWrapper}>
          <MenuIcon />
          {contactRequests || messageNotifications ? <div className={classes.notificationWrapper}></div> : null}
        </ButtonBase>
      )}
      <h2 className={classes.title} style={{ color: theme.TEXT_SECONDARY, fontSize: '30px!important' }}>
        {title}
      </h2>
      {showAddIcon && (
        <ButtonBase onClick={addEvent} className={classes.addButton}>
          <div
            className="w-8 h-8 rounded-full flex justify-center items-center"
            style={{ backgroundColor: theme.BUTTON_ICON }}
          >
            <PlusIcon className="w-7 h-7 text-white" />
          </div>
        </ButtonBase>
      )}
      {uploadMedia && (
        <Box className={classes.addButton}>
          <UploadMedia slideToBottom allFormats viewType="button" isPrivate />
        </Box>
      )}
      {!(uploadMedia || showAddIcon) && <div className={classes.rightBlock}>{rightBlock}</div>}
    </div>
  );
};

export default withStyles(styles)(withConfig(ScreenTitle));
