import { fileType } from 'store/reducers/attachments';
import { client } from './client';

export const mentoringGetData = () => client.get('/mentoring/info');

export const mentoringGetProfile = () => client.get('/mentoring/profile');

export const mentoringGetProfileById = (id: string) => client.get(`/mentoring/profile/${id}`);

export const mentoringUpdateProfile = (profile: any) => client.put('/mentoring/profile', profile);

export const mentoringDeleteProfile = () => client.delete('/mentoring/profile');

export const mentoringFindMentor = (data: any) => client.post('/mentoring/find-mentor', data);

export const mentoringSendRequest = (
  receiverId: string,
  text: string,
  mentoringRegions: string[],
  images?: fileType[],
  videos?: fileType[],
  documents?: fileType[],
) => client.post(`/mentoring/request`, { text, receiverId, images, videos, documents, mentoringRegions });

export const mentoringDeleteRequest = (id: string) => client.delete(`/mentoring/request/${id}`);

export const mentoringAcceptRequest = (id: string) => client.patch(`/mentoring/request/${id}`, { isAccepted: true });

export const mentoringUpdateRequest = (id: string, action: requestType, text: string = '') =>
  client.patch(`/mentoring/request/${id}/${action}`, { text });

export const mentoringGetRequests = () => client.get('/mentoring/request');

export const mentoringGetInfoPage = () => client.get('/mentoring/page-info');

export const mentoringRepetEmail = (requestId: string) => client.post(`/mentoring/request/${requestId}/repeat`);

export const mentoringMarkAsRead = (requestId: string) => client.patch(`/mentoring/request/${requestId}/seen`);

export const becomeMentor = () => client.post('/mentoring/become-mentor');
export const becomeMentee = () => client.post('/mentoring/become-mentee');

type requestType = 'accepted' | 'declined' | 'finished' | 'cancelled';
