import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, withConfig } from '../../../config';
import { branchType, configDate } from '../../../utilities';
import Button from '../../../components/UI/Button';
import BerufHeading from './BerufHeading';
import Input from '../../../components/UI/Input';
import AlertModal from '../../../components/Shared/AlertModal';
import DatePicker from '../../../components/Shared/DatePicker/DatePicker';
import SelectModal from '../../../components/Shared/SelectModal';
import TouchableInput from '../../../components/UI/TouchableInput';
import { useLanguage } from 'languages/languageContext';
import { dateToString } from 'utilities/dateToString';

const useStyles = makeStyles({
  inputsWrapper: {
    width: '100%',
    position: 'relative',
    zIndex: 0,
  },
  updateButtonWraper: {
    marginTop: 20,
  },
  jobEndWrapper: {
    width: '100%',
    position: 'relative',
    paddingTop: 20,
    zIndex: 0,
  },
  defaultWrapper: {
    position: 'absolute',
    padding: 10,
    right: 20,
    top: -20,
    zIndex: 10,
  },
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  iconWrapper: {
    width: 60,
    height: 60,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsWrapper: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props {
  language: any;
  theme: Theme;
  beruf: {
    _id: string;
    name: string;
    company: string;
    industry: string;
    start: string;
    end: string;
  };
  lastElem: boolean;
  elemIndex: number;
  removeBerufHandler: (id: string) => void;
  changeBerufHandler: (value: string, name: string, id: string) => void;
}

interface State {
  name: {
    value: string;
    isFocused: boolean;
    isValid: boolean;
  };
  company: {
    value: string;
    isFocused: boolean;
    isValid: boolean;
  };
  industry: string;
  start: string;
  end: string;
  brancheModalIsOpen: boolean;
  jobStartModalIsOpen: boolean;
  jobEndModalIsOpen: boolean;
  alertModalIsOpen: boolean;
}

interface Action {
  type: 'state';
  payload: any;
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'state':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};

const Beruf: React.FC<Props> = (props) => {
  const { profileProfession, profileDetails, btn } = useLanguage();
  const { theme, beruf, changeBerufHandler, lastElem } = props;

  const { name, company, industry, start, end } = beruf;

  const styles = useStyles({});

  const initState = (): State => {
    return {
      name: {
        value: name || '',
        isFocused: false,
        isValid: false,
      },
      company: {
        value: company || '',
        isFocused: false,
        isValid: false,
      },
      industry: industry || '',
      start: start || '',
      end: end || '',
      brancheModalIsOpen: false,
      jobStartModalIsOpen: false,
      jobEndModalIsOpen: false,
      alertModalIsOpen: false,
    };
  };

  const [state, dispatch] = useReducer(reducer, initState());

  const formattedStartDate = dateToString(state.start, 'MM.YYYY');
  const formattedEndDate = dateToString(state.end, 'MM.YYYY');
  // MODAL HANDLER
  const modalHandler = (name: string) => {
    let modalName = '';
    switch (name) {
      case 'industry':
        modalName = 'brancheModalIsOpen';
        break;
      case 'start':
        modalName = 'jobStartModalIsOpen';
        break;
      case 'end':
        modalName = 'jobEndModalIsOpen';
        break;
      case 'alert':
        modalName = 'alertModalIsOpen';
        break;
      default:
        modalName = 'brancheModalIsOpen';
        break;
    }

    dispatch({
      type: 'state',
      payload: {
        [modalName]: !state[modalName],
      },
    });
  };

  // FOCUS HANDLER
  const focusHandler = (inputName: string): void => {
    dispatch({
      type: 'state',
      payload: {
        [inputName]: {
          ...state[inputName],
          isFocused: !state[inputName].isFocused,
        },
      },
    });
  };

  // ON CHANGE TEXT
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
    const { value } = e.target;

    dispatch({
      type: 'state',
      payload: {
        [inputName]: {
          ...state[inputName],
          value,
          isFocused: true,
        },
      },
    });

    changeBerufHandler(value, inputName, beruf._id);
  };

  const selectItemHandler = (value: string, name: string) => {
    dispatch({
      type: 'state',
      payload: {
        ...state,
        [name]: value,
      },
    });

    changeBerufHandler(value, name, beruf._id);
  };

  const dateHandler = (inputName: string, value: string) => {
    // const date = configDate(value, 'month') || '';

    dispatch({
      type: 'state',
      payload: {
        ...state[inputName],
        [inputName]: value,
      },
    });

    changeBerufHandler(value, inputName, beruf._id);
  };

  // REMOVE BERUF METHODS
  const onRemoveHandler = () => {
    const { removeBerufHandler, beruf } = props;
    if (beruf?._id) {
      dispatch({
        type: 'state',
        payload: {
          alertModalIsOpen: true,
        },
      });
    } else {
      removeBerufHandler(beruf._id);
    }
  };

  const removeBeruf = () => {
    const { removeBerufHandler, beruf } = props;

    removeBerufHandler(beruf._id);
  };

  return (
    <>
      <div
        style={{
          borderBottomColor: theme.BACKGROUND_SECONDARY,
          borderBottomWidth: !lastElem ? 1 : 0,
          paddingBottom: 20,
        }}
      >
        <div className={styles.buttonWrapper}>
          <Button
            background={theme.BUTTON_SECONDARY}
            width="20px"
            label="X"
            onClick={onRemoveHandler}
            disabled={true}
          />
        </div>
        <BerufHeading title="Weitere Tätigkeit" />
        <Input
          placeholder={profileProfession.itemNameLabel}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e, 'name')}
          autoCapitalize="none"
          value={state.name.value}
          isFocused={state.name.isFocused}
          onBlur={() => focusHandler('name')}
          onFocus={() => focusHandler('name')}
        />
        <Input
          placeholder={profileDetails.firmaLabel}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e, 'company')}
          autoCapitalize="none"
          value={state.company.value}
          isFocused={state.company.isFocused}
          onBlur={() => focusHandler('company')}
          onFocus={() => focusHandler('company')}
        />
        <TouchableInput
          onClick={() => modalHandler('industry')}
          value={state.industry}
          placeholder={profileProfession.industryLabel}
        />
        <div className={styles.inputsWrapper}>
          <TouchableInput
            onClick={() => modalHandler('start')}
            value={formattedStartDate}
            placeholder={profileProfession.fromLabel}
          />
          <TouchableInput
            onClick={() => modalHandler('end')}
            value={formattedEndDate}
            placeholder={profileProfession.toLabel}
          />
        </div>
      </div>
      <SelectModal
        modalIsOpen={state.brancheModalIsOpen}
        modalTitle={profileProfession.industryLabel}
        modalHandler={() => modalHandler('industry')}
        selectItemHandler={selectItemHandler}
        data={branchType}
        modalName="industry"
      />
      <DatePicker
        dateHandler={dateHandler}
        modalHandler={() => modalHandler('start')}
        selectItemHandler={selectItemHandler}
        modalName="start"
        maximumDate={new Date()}
        minimumDate={state.start}
        date={new Date()}
        dateModalIsOpen={state.jobStartModalIsOpen}
        closeModalHandler={() => modalHandler('start')}
        inputName="start"
        // Date picker config
        dateFormat="MM/yyyy"
        showMonthYearPicker
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        fixedHeight={true}
        startOpen={true}
        inline
        shouldCloseOnSelect={false}
      />

      <DatePicker
        dateHandler={dateHandler}
        modalHandler={() => modalHandler('end')}
        selectItemHandler={selectItemHandler}
        modalName="end"
        minimumDate={state.start}
        date={new Date()}
        dateModalIsOpen={state.jobEndModalIsOpen}
        closeModalHandler={() => modalHandler('end')}
        inputName="end"
        // Date picker config
        dateFormat="MM/yyyy"
        // minDate={minDate}
        showMonthYearPicker
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        fixedHeight={true}
        startOpen={true}
        inline
        shouldCloseOnSelect={false}
      />
      <AlertModal
        showModal={state.alertModalIsOpen}
        modalHandler={() => modalHandler('alert')}
        message={'Eintrag wirklich löschen?'}
      >
        <div className={styles.buttonsWrapper}>
          <Button label={btn.cancelBtn} background={theme.BUTTON_SECONDARY} onClick={() => modalHandler('alert')} />
          <Button
            onClick={() => {
              modalHandler('alert');
              removeBeruf();
            }}
            label="OK"
          />
        </div>
      </AlertModal>
    </>
  );
};

export default withConfig(Beruf);
