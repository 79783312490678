import { takeEvery, call, put, fork } from 'redux-saga/effects';
import {
  GET_USER_REQUESTS_REQUEST,
  GET_REQUEST_REQUEST,
  HANDLE_REQUEST_REQUEST,
  SEND_REQUEST_REQUEST,
  SEND_REPORT_REQUEST,
  DECLINE_REQUEST_REQUEST,
} from '../../constants/';
import {
  getUserRequestsSuccess,
  getUserRequestsFailure,
  getRequestSuccess,
  getRequestFailure,
  handleRequestSuccess,
  handleRequestFailure,
  sendRequestSuccess,
  sendRequestFailure,
  sendReportSuccess,
  sendReportFailure,
  declineRequestSuccess,
  declineRequestFailure,
  getUserRequestsRequest,
} from '../actions/requests';
import { getOwnerProfileSuccess, getOwnerProfileFailure } from '../actions/users';
import { showResponse } from '../actions/response';

import {
  getUserRequests,
  getRequest,
  handleRequest,
  sendRequest,
  sendReport,
  getOwnerProfile,
  declineRequest,
} from '../../api/';

// GET OWNER PROFILE SAGAS
function* getOwnerProfileSaga(userId) {
  try {
    const result = yield call(() => getOwnerProfile(userId));
    yield put(getOwnerProfileSuccess(result.Data));
  } catch (e) {
    yield put(getOwnerProfileFailure(e));
    yield put(showResponse(e));
  }
}

// GET USER REQUESTS
function* getUserRequestsWorkerSaga() {
  try {
    const result = yield call(getUserRequests);
    yield put(getUserRequestsSuccess(result.Data.sent_contact_request, result.Data.pending_contact_request));
  } catch (e) {
    yield put(getUserRequestsFailure(e));
    yield put(showResponse(e));
  }
}

function* getUserRequestsWatcherSaga() {
  yield takeEvery(GET_USER_REQUESTS_REQUEST, getUserRequestsWorkerSaga);
}

// GET REQUEST
function* getRequestWorkerSaga(action) {
  const { requestType, requestId, onRequestFailure } = action;
  try {
    const result = yield call(() => getRequest(requestType, requestId));
    const requestObject = { _id: result.Data._id, message: result.Data.message };
    yield put(getRequestSuccess(requestObject));
  } catch (e) {
    yield put(getRequestFailure(e));
    onRequestFailure();
    // yield put(showResponse(e));
  }
}

function* getRequestWatcherSaga() {
  yield takeEvery(GET_REQUEST_REQUEST, getRequestWorkerSaga);
}

// HANDLE REQUEST
function* handleRequestWorkerSaga(action) {
  const { requestType, requestId } = action;
  const userId = localStorage.getItem('userId');

  try {
    const result = yield call(() => handleRequest(requestType, requestId));
    yield put(handleRequestSuccess());

    if (result) {
      yield* getOwnerProfileSaga(userId);
      yield put(getUserRequestsRequest());
    }
  } catch (e) {
    yield put(handleRequestFailure(e));
    yield put(showResponse(e));
  }
}

function* handleRequestWatcherSaga() {
  yield takeEvery(HANDLE_REQUEST_REQUEST, handleRequestWorkerSaga);
}

// SEND REQUEST
function* sendRequestWorkerSaga(action) {
  const { requestId, message, showMessageRequest } = action;
  const userId = localStorage.getItem('userId');

  try {
    const result = yield call(() => sendRequest(requestId, message));
    yield put(sendRequestSuccess());
    yield put(showResponse({ message: showMessageRequest }));

    if (result) {
      yield* getOwnerProfileSaga(userId);
    }
  } catch (e) {
    yield put(sendRequestFailure(e));
    yield put(showResponse(e));
  }
}

function* sendRequestWatcherSaga() {
  yield takeEvery(SEND_REQUEST_REQUEST, sendRequestWorkerSaga);
}

// SEND REPORT
function* sendReportWorkerSaga(action) {
  const { report, reportId } = action;
  const userId = localStorage.getItem('userId');

  try {
    const result = yield call(() => sendReport(report, reportId));
    yield put(sendReportSuccess());

    if (result) {
      yield* getOwnerProfileSaga(userId);
    }
  } catch (e) {
    yield put(sendReportFailure(e));
    yield put(showResponse(e));
  }
}

function* sendReportWatcherSaga() {
  yield takeEvery(SEND_REPORT_REQUEST, sendReportWorkerSaga);
}

// DECLINE REQUEST
function* declineRequestWorkerSaga(action) {
  const { requestId } = action;
  const userId = localStorage.getItem('userId');

  try {
    const result = yield call(() => declineRequest(requestId));
    yield put(declineRequestSuccess());

    if (result) {
      yield* getOwnerProfileSaga(userId);
      yield put(getUserRequestsRequest());
    }
  } catch (e) {
    yield put(declineRequestFailure(e));
    yield put(showResponse(e));
  }
}

function* declineRequestWatcherSaga() {
  yield takeEvery(DECLINE_REQUEST_REQUEST, declineRequestWorkerSaga);
}

// SAGAS
const requestsSagas = [
  fork(getUserRequestsWatcherSaga),
  fork(getRequestWatcherSaga),
  fork(handleRequestWatcherSaga),
  fork(sendRequestWatcherSaga),
  fork(sendReportWatcherSaga),
  fork(declineRequestWatcherSaga),
];

export default requestsSagas;
