import { client } from './client';
import { authHeader, handleConnectionError, handleResponse } from './core';
import { API } from '../constants/';
import { globalSearchResponseType } from 'store/reducers/globalSearch';

// SEARCH ENTITY
export const searchRequest = (include: boolean, limit: number, filter: string, page: number, searchkey = '') => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    `${API}/v2/search/?includeMembers=${include}&index=0&limit=${limit}&name=${filter}&page=${page}&searchkey=${encodeURIComponent(
      searchkey,
    )}`,
    options,
  )
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// SEARCH FILTERED USERS
export const searchFilteredUsers = (
  entity: string,
  value: string,
  limit: number,
  filter: string,
  page: number,
  searchkey = '',
  corpsStatus = '',
) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    `${API}/v4/searchPaged/${entity}?entityVal=${encodeURIComponent(
      value,
    )}&limit=${limit}&orderBy=${filter}&page=${page}&corpsStatus=${corpsStatus}&searchkey=${encodeURIComponent(
      searchkey,
    )}`,
    options,
  )
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

export const globalSearch = (
  query: string,
  page = '1',
  limit = '5',
  abortController: AbortController,
): Promise<globalSearchResponseType> =>
  client.get(`/v4/search/global?page=${page}&limit=${limit}&query=${query}`, {
    signal: abortController.signal,
    timeout: 10000,
  });

export const globalSearchByName = (
  query: string,
  page = 1,
  limit = 5,
  keys: string,
): Promise<Partial<globalSearchResponseType>> =>
  client.get(`/v4/search/global?page=${page}&limit=${limit}&query=${query}&keys=${keys}`, {
    timeout: 10000,
  });
