import { takeEvery, put, call, fork, select, take, race } from 'redux-saga/effects';
import {
  RESET_PASSWORD_REQUEST,
  SIGN_OUT_REQUEST,
  UPDATE_PASSWORD_REQUEST,
} from '../../constants';
import {
  resetPassword,
  getUserProfile,
  updatePassword,
} from '../../api/';
import {
  resetPasswordFailure,
  resetPasswordSuccess,
  signInFailure,
  signInSuccess,
  signOutSuccess,
  updatePasswordSuccess,
  updatePasswordFailure,
  signInRequest,
} from './../actions/auth';

import { RootState } from 'store';
import languages from 'languages/languages';
import { showResponse } from 'store/actions/response';
import { closeMenu } from 'store/actions/layout';
import { getUserProfileSuccess, getUserProfileFailure } from 'store/actions/users';

// GET USER PROFILE
function* getUserProfileSaga(userId) {
  try {
    const result = yield call(() => getUserProfile(userId));
    yield put(getUserProfileSuccess(result.Data));
    return result;
  } catch (e) {
    yield put(getUserProfileFailure(e));
    yield put(showResponse(e));
  }
}

// SIGN OUT SAGAS
function* signOutWorkerSaga() {
  yield put(signOutSuccess());
  yield put(closeMenu());
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('firebaseID');
  localStorage.removeItem('currentCorps');
}

function* signOutWatcherSaga() {
  yield takeEvery(SIGN_OUT_REQUEST, signOutWorkerSaga);
}


// RESET PASSWORD
function* resetPasswordWorkerSaga(action) {
  try {
    const result = yield call(() => resetPassword(action.email));
    yield put(resetPasswordSuccess());
    yield put(showResponse({ message: result.Status }, '/sign-in'));
  } catch (e) {
    yield put(resetPasswordFailure(e));
    yield put(showResponse(e, '/sign-in'));
  }
}

function* resetPasswordWatcherSaga() {
  yield takeEvery(RESET_PASSWORD_REQUEST, resetPasswordWorkerSaga);
}

// UPDATE PASSWORD
function* updatePasswordWorkerSaga(action) {
  const { token, newPassword, email } = action;

  try {
    const result = yield call(() => updatePassword(token, newPassword));
    yield put(updatePasswordSuccess());
    if (!result.Boolean) throw new Error();
    yield put(signInRequest(email, newPassword));
    yield race([signInSuccess, signInFailure]);
    yield put(showResponse({ message: result.Status }, '/posts'));
  } catch (e) {
    const state: RootState = yield select();
    const user = state.users.user;
    const { responseHandler } = languages().find((lang) => lang.key === user.language) || languages()[0];
    yield put(updatePasswordFailure());
    yield put(showResponse({ message: responseHandler.expiredLinkLabel }, '/sign-in'));
  }
}

function* updatePasswordWatcherSaga() {
  yield takeEvery(UPDATE_PASSWORD_REQUEST, updatePasswordWorkerSaga);
}

const authSagas = [
  fork(resetPasswordWatcherSaga),
  fork(signOutWatcherSaga),
  fork(updatePasswordWatcherSaga),
];

export default authSagas;
