import React, { CSSProperties, FC } from 'react';
import linkDecorator from './linkDecorator';
import Linkify from 'react-linkify';
import { decodeHTMLEntities } from 'utilities/formatString';
interface IContentProps {
  content: string;
  style?: CSSProperties;
  className?: string;
}

/*
    This component is created only to check if the content has emojis.
    If it has it, it converts the content into HTML, otherwise it simply renders
*/

const Content: FC<IContentProps> = ({ content = '', style, className }) => {
  const decodedText = decodeHTMLEntities(content);

  return (
    <Linkify componentDecorator={linkDecorator}>
      <div className={className} style={style}>
        {decodedText}
      </div>
    </Linkify>
  );
};

export default Content;
