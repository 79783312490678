import React, { FC, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { ArrowDownward } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { getConfig } from 'config/config';
import { DownloadButton } from './DownloadButton';

const { theme } = getConfig();
export const VideoPlayer: FC<{ videos: any }> = ({ videos }) => {
  const [size, setSize] = useState<null | string>(null);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const srcUri = videos[0]?.url;
  useEffect(() => {
    fetch(srcUri, { method: 'HEAD' })
      .then((res) => {
        const videoSize = Number(res.headers.get('Content-Length') || 0);
        const string = `${Math.round(videoSize / Math.pow(10, 4)) / 100}MB`;
        setSize(string);
      })
      .catch((e) => console.log('FAILED TO FETCH VIDEO SIZE :', e));
  }, []);

  const LoadView = (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: theme.BUTTON_PRIMARY,
        width: 100,
        height: 100,
        borderRadius: 100,
      }}
    >
      <ArrowDownward fontSize="large" htmlColor={'#fff'} />
      <p style={{ color: '#fff', fontWeight: 'bold', fontSize: 14 }}>{size}</p>
    </Box>
  );

  return (
    <div style={{ width: 'auto', height: 'auto' }}>
      {videoIsPlaying && <DownloadButton {...videos[0]} style={{ top: 40, left: 260 }} />}
      <ReactPlayer
        url={videos[0]?.url}
        controls={true}
        playing={true}
        width="640px"
        height="360px"
        playIcon={LoadView}
        onPlay={() => setVideoIsPlaying(true)}
        light={videos[0]?.thumb}
      />
    </div>
  );
};
