import React, { FC } from 'react';
import { postImagePath } from 'utilities/postImagePath';
import DocumentsGallery from './DocumentsGallery';
import PostGallery from './PostGallery';

type Props = { documents: any; videos: any; images: any; showAsString?: boolean };

export const AttachmentsGallery: FC<Props> = ({ documents, videos, images }) => {
  const oldFormatFiles = documents
    ?.filter((file) => file.path)
    .map((file) => ({
      fileName: file.name,
      url: postImagePath('full', file.path),
    }));
  const documentsToRender = oldFormatFiles?.length ? oldFormatFiles : documents?.length ? documents : [];

  return (
    <>
      {(!!images?.length || !!videos?.length) && <PostGallery images={images} videos={videos} />}
      <DocumentsGallery files={documentsToRender} />
    </>
  );
};
