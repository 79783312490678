import { takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects';
import { GET_USER_CONTACTS_REQUEST, GET_CONTACTS_LIST_REQUEST } from '../../constants/';

import {
  getUserContactsSuccess,
  getUserContactsFailure,
  getContactsListSuccess,
  getContactsListFailure,
} from '../actions/contacts';
import { showResponse } from '../actions/response';

import { getUserContacts, getContactsList } from '../../api/';

// GET USER CONTACTS
function* getUserContactsWorkerSaga(action) {
  const { search, userId, page, limit, searchkey } = action;

  try {
    const result = yield call(() => getUserContacts(userId, page, limit, searchkey));
    yield put(getUserContactsSuccess(result.Data.data, result.Data.total, search));
  } catch (e) {
    yield put(getUserContactsFailure(e));
    yield put(showResponse(e));
  }
}

function* getUserContactsWatcherSaga() {
  yield takeLatest(GET_USER_CONTACTS_REQUEST, getUserContactsWorkerSaga);
}

// GET CONTACTS LIST
function* getContactsListWorkerSaga() {
  const userId = localStorage.getItem('userId');

  try {
    if (userId) {
      const result = yield call(() => getContactsList(userId));

      yield put(getContactsListSuccess(result.Data.contact_list));
    }
  } catch (e) {
    yield put(getContactsListFailure(e));
    yield put(showResponse(e));
  }
}

function* getContactsListWatcherSaga() {
  yield takeEvery(GET_CONTACTS_LIST_REQUEST, getContactsListWorkerSaga);
}

const contactsSagas = [fork(getUserContactsWatcherSaga), fork(getContactsListWatcherSaga)];

export default contactsSagas;
