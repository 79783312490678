import React, { ReactNodeArray, useEffect } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { withConfig, Theme, Config } from '../../../../config';
import Button from '../../../UI/Button';
import { useLanguage } from 'languages/languageContext';
import { makeDateBold } from 'utilities';
import Linkify from 'react-linkify';
import { cn } from 'utilities/utils';

const styles = createStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(97, 97, 97, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    zIndex: 10,
    whiteSpace: 'pre-line',
  },
  modalWrapper: {
    position: 'relative',
    width: 362,
    paddingBottom: 15,
    borderRadius: 10,
    zIndex: 20,
  },
  heading: {
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    position: 'relative',
    padding: '20px 0 20px 0',
  },
  title: {
    textAlign: 'center',
    fontSize: 15,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    margin: 0,
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 5,
    outline: 'none',
    border: 'none',
  },
  messageWrapper: {
    padding: '20px 30px',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  message: {
    margin: 0,
    padding: 0,
    fontSize: 15,
    fontFamily: 'Roboto',
    wordBreak: 'break-all',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  message: string;
  showModal: boolean;
  onClick: () => void;
  config: Config;
  title?: string;
  options?: ReactNodeArray;
  cancelBtnLabel?: string;
  children?: React.ReactNode;
  messageAlignLeft?: boolean;
}

const ResponseModal: React.FC<Props> = ({
  classes,
  children,
  theme,
  message,
  showModal,
  onClick,
  config,
  title,
  options,
  cancelBtnLabel,
  messageAlignLeft,
  ...props
}) => {
  const {
    btn: { cancelBtn },
  } = useLanguage();

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModal]);

  return message && showModal ? (
    <div className={classes.container}>
      <div className={classes.modalWrapper} style={{ background: theme.BACKGROUND }}>
        <div className={classes.heading} style={{ background: theme.BACKGROUND_SECONDARY }}>
          <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
            {title || config.APP_FULL_NAME}
          </h3>
          <div className={classes.buttonWrapper}>
            <ButtonBase {...props} style={{ borderRadius: 50 }} onClick={onClick}>
              <Close style={{ color: theme.BUTTON_SECONDARY }} />
            </ButtonBase>
          </div>
        </div>
        <div className={classes.messageWrapper}>
          <p
            className={cn(classes.message)}
            style={{
              color: theme.TEXT_PRIMARY,
              textAlign: messageAlignLeft ? 'left' : 'center',
            }}
          >
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a style={{ color: '#2671D1' }} href={decoratedHref} key={key} target="_blank">
                  {decoratedText}
                </a>
              )}
            >
              {makeDateBold(message)}
            </Linkify>
          </p>
        </div>
        <div className="flex flex-col">{options}</div>
        <Button
          label={options?.length ? cancelBtnLabel || cancelBtn : cancelBtnLabel || 'OK'}
          background={options?.length ? theme.BUTTON_SECONDARY : undefined}
          onClick={onClick}
        />
      </div>
      <div {...props}>
        <div className={classes.backdrop}></div>
      </div>
    </div>
  ) : null;
};

export default withStyles(styles)(withConfig(ResponseModal));
