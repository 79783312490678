import React from 'react';
import { withConfig } from '../../../config';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';
import { DrawerLinkProps } from '../model';
import { ReactComponent as LogOutIcon } from '../assets/logout.svg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ButtonBase } from '@material-ui/core';
import DrawerFooter from './DrawerFooter';

const styles = createStyles({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: '#fff',
  },
  buttonBase: {
    width: '100%',
  },
  logoutContainer: {
    width: '100%',
    cursor: 'pointer',
    padding: '20px 30px',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 16,
  },
  button: {
    marginLeft: 10,
    fontSize: 14,
    fontFamily: 'Poppins',
  },
});

interface Props extends WithStyles<typeof styles>, DrawerLinkProps {}

const DrawerLogOut: React.FC<Props> = ({ classes, theme }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <ButtonBase
        className={classes.buttonBase}
        onClick={() => {
          history.push('/sign-in');
        }}
      >
        <div className={classes.logoutContainer}>
          <div className={classes.buttonWrapper}>
            <LogOutIcon />
            <span className={classes.button} style={{ color: theme.TEXT_PRIMARY }}>
              Login
            </span>
          </div>
        </div>
      </ButtonBase>
      <DrawerFooter disabled />
    </div>
  );
};

export default withStyles(styles)(withConfig(DrawerLogOut));
