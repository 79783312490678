import React, { useEffect } from 'react';
import ResponseModal from './components/ResponseModal';
import { useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store';
import { hideResponse } from 'store/actions/response';
import { getConfig } from 'config/config';
import { getLocalStorage } from 'utilities/localStorageHandler';

const { theme } = getConfig();
interface Props extends RouteComponentProps<any> {
  children?: React.ReactNode;
}

const ResponseHandler: React.FunctionComponent<Props> = ({ children, history }) => {
  const token = getLocalStorage('token');
  const dispatch = useDispatch();
  const responseObj = useAppSelector((state) => state.response);
  const { support_email } = useAppSelector((state) => state.common);
  const { response, showResponse } = responseObj;
  const location = useLocation();
  useEffect(() => {
    if (response?.immediately) {
      history.push(response.route);
      dispatch(hideResponse());
    }
  }, [response?.immediately]);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: !token ? 'transparent' : theme.BACKGROUND_SECONDARY,
      }}
    >
      {children}
      {location.pathname !== '/reconnect' && (
        <ResponseModal
          showModal={showResponse}
          title={response?.title}
          message={(response && response.message) || `Unbekannter Fehler. Bitte wenden Sie sich an ${support_email}`}
          onClick={() => {
            response?.onClick?.();
            if (response.route) {
              history.push(response.route);
            }
            dispatch(hideResponse());
          }}
          options={response?.options}
          {...response}
        />
      )}
    </div>
  );
};

export default withRouter(ResponseHandler);
