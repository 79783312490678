import { makeStyles } from "@material-ui/core";
import { getConfig } from "config/config";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { globalSearchResponseType } from "store/reducers/globalSearch";
import { ReactComponent as KreiseIcon } from '../../../../Drawer/assets/kreise.svg';

type Props = {
  chat?: globalSearchResponseType['kreise']['data'][0]
}

export const ChatItem: FC<Props> = (props) => {
  const { chat } = props
  const { _id, name } = chat
  const styles = useStyles({})

  return <Link
    className={styles.container}
    to={{ pathname: `/kreise-posts/${_id}`, state: { kreiseId: _id } }}
  >
    <div className={styles.imageWrapper}>
      <div className={styles.scaledImaged}>
        <KreiseIcon />
      </div>
    </div>
    <div className={styles.infoWrapper}>
      <span className={styles.name}>{name}</span>
    </div>
  </Link>
}

const { theme } = getConfig()
const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: 80,
    backgroundColor: theme.BACKGROUND_SECONDARY,
    padding: 10,
    margin: 7,
    alignItems: 'center',
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  imageWrapper: {
    width: 60,
    height: 60,
    borderRadius: 60,
    background: theme.ICON_BACKGROUND,
    borderColor: theme.ICON_BORDER,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid',
    color: theme.ICON_BORDER,
  },
  scaledImaged: {
    scale: 1.5
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0 20px',
    alignItems: 'flex-start',
    flex: 1
  },
  name: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.TEXT_PRIMARY,
  },
})