import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  EDIT_OWNER_AVATAR_REQUEST,
  EDIT_OWNER_AVATAR_SUCCESS,
  EDIT_OWNER_AVATAR_FAILURE,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_REQUEST,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CLEAR_USER_REQUEST,
  CLEAR_USER_SUCCESS,
  GET_OWNER_PROFILE_REQUEST,
  GET_OWNER_PROFILE_SUCCESS,
  GET_OWNER_PROFILE_FAILURE,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAILURE,
  UPDATE_USER_NOTIFICATIONS_REQUEST,
  UPDATE_USER_NOTIFICATIONS_SUCCESS,
  UPDATE_USER_NOTIFICATIONS_FAILURE,
  MUTE_USER_NOTIFICATIONS_REQUEST,
  MUTE_USER_NOTIFICATIONS_SUCCESS,
  MUTE_USER_NOTIFICATIONS_FAILURE,
  UNMUTE_USER_NOTIFICATIONS_REQUEST,
  UNMUTE_USER_NOTIFICATIONS_SUCCESS,
  UNMUTE_USER_NOTIFICATIONS_FAILURE,
} from '../../constants';

// GET OWNER PROFILE
export const getOwnerProfileRequest = (id: string) => ({
  type: GET_OWNER_PROFILE_REQUEST,
  id,
});

export const getOwnerProfileSuccess = (user: object) => ({
  type: GET_OWNER_PROFILE_SUCCESS,
  user,
});

export const getOwnerProfileFailure = (error: object) => ({
  type: GET_OWNER_PROFILE_FAILURE,
  error,
});

// EDIT OWNER AVATAR
export const editOwnerAvatarRequest = (fullphoto: any, photo: any) => ({
  type: EDIT_OWNER_AVATAR_REQUEST,
  fullphoto,
  photo,
});

export const editOwnerAvatarSuccess = (photo: any) => ({
  type: EDIT_OWNER_AVATAR_SUCCESS,
  photo,
});

export const editOwnerAvatarFailure = (error: object) => ({
  type: EDIT_OWNER_AVATAR_FAILURE,
  error,
});

// GET PROFILE REQUEST
export const getUserProfileRequest = (id: string) => ({
  type: GET_USER_PROFILE_REQUEST,
  id,
});

export const getUserProfileSuccess = (user: object) => ({
  type: GET_USER_PROFILE_SUCCESS,
  user,
});

export const getUserProfileFailure = (error: object) => ({
  type: GET_USER_PROFILE_FAILURE,
  error,
});

// GET USER BY ID
export const getUserByIdRequest = (firebaseIds: Array<string>) => ({
  type: GET_USER_BY_ID_REQUEST,
  firebaseIds,
});

export const getUserByIdSuccess = (users: Array<object>) => ({
  type: GET_USER_BY_ID_SUCCESS,
  users,
});

export const getUserByIdFailure = (error: object) => ({
  type: GET_USER_BY_ID_FAILURE,
  error,
});

// UPDATE USER
export const updateUserRequest = (user, withoutMessage = false) => ({
  type: UPDATE_USER_REQUEST,
  user,
  withoutMessage,
});

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  user,
});

export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  error,
});

// UPDATE USER PASSWORD
export const updateUserPasswordRequest = (payload: object, callback: any) => ({
  type: UPDATE_USER_PASSWORD_REQUEST,
  payload,
  callback,
});

export const updateUserPasswordSuccess = () => ({
  type: UPDATE_USER_PASSWORD_SUCCESS,
});

export const updateUserPasswordFailure = () => ({
  type: UPDATE_USER_PASSWORD_FAILURE,
});

// UPDATE USER NOTIFICATIONS
export const updateUserNotificationsRequest = (mailingNotifyOptions) => ({
  type: UPDATE_USER_NOTIFICATIONS_REQUEST,
  mailingNotifyOptions,
});

export const updateUserNotificationsSuccess = (mailingNotifyOptions) => ({
  type: UPDATE_USER_NOTIFICATIONS_SUCCESS,
  mailingNotifyOptions,
});

export const updateUserNotificationsFailure = (error) => ({
  type: UPDATE_USER_NOTIFICATIONS_FAILURE,
  error,
});

// MUTE USER NOTIFICATIONS
export const muteUserNotificationsRequest = (userId) => ({
  type: MUTE_USER_NOTIFICATIONS_REQUEST,
  userId,
});

export const muteUserNotificationsSuccess = (userId) => ({
  type: MUTE_USER_NOTIFICATIONS_SUCCESS,
  userId,
});

export const muteUserNotificationsFailure = (error) => ({
  type: MUTE_USER_NOTIFICATIONS_FAILURE,
  error,
});

// UNMUTE USER NOTIFICATIONS
export const unmuteUserNotificationsRequest = (userId) => ({
  type: UNMUTE_USER_NOTIFICATIONS_REQUEST,
  userId,
});

export const unmuteUserNotificationsSuccess = (userId) => ({
  type: UNMUTE_USER_NOTIFICATIONS_SUCCESS,
  userId,
});

export const unmuteUserNotificationsFailure = (error) => ({
  type: UNMUTE_USER_NOTIFICATIONS_FAILURE,
  error,
});

// CLEAR USER
export const clearUserRequest = () => ({
  type: CLEAR_USER_REQUEST,
});

export const clearUserSuccess = () => ({
  type: CLEAR_USER_SUCCESS,
});
