import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import { cn } from 'utilities/utils';

type Props = {
  children: ReactNode | string | any;
  className?: ComponentPropsWithoutRef<'div'>['className'];
};

export const ErrorLabel: FC<Props> = (props) => {
  const { children, className } = props;

  return <div className={cn('min-h-4 w-full ml-3 text-left text-xs text-red-500', className)}>{children}</div>;
};
