import React from 'react';
import { withConfig } from '../../../config/';
import { useDispatch } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import { DrawerProfileProps } from '../model';
import { imagePath } from '../../../utilities/';
import { useHistory } from 'react-router-dom';
import { closeMenu } from '../../../store/actions/layout';
import defaultImg from '../../../assets/default_avatar.png';
import { ReactComponent as MailIcon } from '../assets/mail.svg';
import { useCustomHistory } from 'hooks/useCustomHistory';

const styles = createStyles({
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid',
  },
  imgWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: '20px 0 10px 0',
  },
  img: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '50%',
    width: 70,
    height: 70,
  },
  title: {
    fontSize: 16,
    fontFamily: 'Poppins',
    fontWeight: 600,
    margin: 0,
    padding: 0,
    textAlign: 'left',
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  namesWrapper: {
    // boxSizing: 'border-box',
    whiteSpace: 'pre-wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '90%',
    padding: '8px 20px!important',
    fontSize: '14px!important',
  },
  emailWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
  email: {
    fontSize: 14,
    fontFamily: 'Roboto',
    wordBrake: 'break-all',
    overflowWrap: 'break-word',
  },
  backdrop: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: 10,
    cursor: 'pointer',
  },
  '@media(max-width: 650px)': {
    email: {
      fontSize: 12,
      wordBrake: 'break-word',
    },
  },
});

interface Props extends WithStyles<typeof styles>, DrawerProfileProps {}

const DrawerProfile: React.FC<Props> = ({ classes, theme, user, userIsLoading }) => {
  const { customPush } = useCustomHistory();

  const dispatch = useDispatch();
  let image: string | null = null;
  if (user && user.photo) {
    image = imagePath(null, user.photo);
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container} style={{ borderColor: theme.BACKGROUND_SECONDARY }}>
        <div className={classes.imgWrapper} style={{ borderColor: theme.NAV_BAR }}>
          {userIsLoading ? (
            <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
          ) : (
            <img src={image || defaultImg} style={{ borderColor: theme.NAV_BAR }} className={classes.img} alt=" " />
          )}
        </div>

        {user && user.name && user.email ? (
          <div className={classes.namesWrapper}>
            <span className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
              {user.name} {user.lastname}
            </span>
            <div className={classes.emailWrapper}>
              <MailIcon />
              <div style={{ marginLeft: 10, wordBreak: 'break-all' }}>
                <span className={classes.email} style={{ color: theme.BUTTON_PRIMARY }}>
                  {user.email}
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {user && user.name && (
        <div
          onClick={() => {
            setTimeout(() => {
              dispatch(closeMenu());
            }, 1);
            customPush(`/profile-details/${user._id}`, { userId: user._id });
          }}
          className={classes.backdrop}
        ></div>
      )}
    </div>
  );
};

export default withStyles(styles)(withConfig(DrawerProfile));
