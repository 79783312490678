import React from 'react';
import { useDispatch } from 'react-redux';
import { getChatUsersRequest } from '../../../store/actions/chat';
import { withConfig, Theme } from '../../../config/';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import { formatString, timeAgo, imagePath } from '../../../utilities/';
import { ReactComponent as ArrowIcon } from '../../../assets/rightArrow.svg';
import Content from 'components/UI/Content';
const defaultImage = require('../../../assets/default_avatar.png');

const styles = createStyles({
  container: {
    width: '100%',
    padding: '10px 20px',
    display: 'flex',
  },
  heading: {
    flexGrow: 1,
    marginBottom: 5,
  },
  wrapper: {
    position: 'relative',
  },
  imageWrapper: {
    // position: 'relative',
    width: 60,
    height: 60,
    borderRadius: 30,
    border: '2px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginRight: 15,
    zIndex: 0,
  },
  image: {
    width: '100%',
  },
  name: {
    fontSize: 17,
    fontFamily: 'Poppins',
    marginRight: 10,
    textAlign: 'left',
  },
  time: {
    fontSize: 15,
    fontFamily: 'Roboto',
  },
  descriptionContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  descriptionWrapper: {
    textAlign: 'left',
  },
  description: {
    fontSize: 15,
    fontFamily: 'Roboto',
    wordBrake: 'break-word',
    overflowWrap: 'break-word',
  },
  nameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '95%',
  },
  buttonWrapper: {
    display: 'flex',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
  },
  notificationWrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
  },
  notification: {
    width: 20,
    height: 20,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@media(max-width: 1024px)': {
    descriptionWrapper: {
      maxWidth: 380,
    },
    nameWrapper: {
      width: '100%',
    },
  },
  '@media(max-width: 650px)': {
    container: {
      marginBottom: 15,
    },
    descriptionWrapper: {
      maxWidth: 200,
    },
    name: {
      maxWidth: 250,
    },
    nameWrapper: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  kreise: any;
  user: any;
  markAsRead: (receiverUID: string, unreadMessages: Array<any>) => void;
}

const DialogItem: React.FC<Props> = ({ theme, classes, history, user, markAsRead }) => {
  const dispatch = useDispatch();
  const image = imagePath('null', user.photo);
  let messagesArr: Array<any> = [];
  let newMessages: Array<any> = [];

  for (let key in user.messages) {
    messagesArr.push(user.messages[key]);

    if (user.messages[key].read == 0) {
      newMessages.push(key);
    }
  }

  const lastMessageDate = timeAgo(new Date(user.lastMessage.time));
  const formattedContent = formatString(90, user.lastMessage.msg);

  const toDialogHandler = () => {
    dispatch(getChatUsersRequest(false));
    markAsRead(user.firebaseID, newMessages);

    history.push('/dialogs/' + user._id, { receiver: user });
  };

  return (
    <ButtonBase style={{ width: '100%' }} onClick={toDialogHandler}>
      <div
        className={classes.container}
        style={{ background: newMessages.length > 0 ? theme.BACKGROUND_PRIMARY : theme.BACKGROUND }}
      >
        <div className={classes.wrapper}>
          <div className={classes.imageWrapper} style={{ borderColor: theme.BACKGROUND_SECONDARY }}>
            <img src={image || defaultImage} alt=" " className={classes.image} />
          </div>
          {newMessages.length > 0 && (
            <div className={classes.notificationWrapper}>
              <div className={classes.notification} style={{ background: theme.ERROR_PRIMARY }}>
                <span style={{ color: theme.BACKGROUND }}>{newMessages.length}</span>
              </div>
            </div>
          )}
        </div>
        <div style={{ flexGrow: 1 }}>
          <div className={classes.heading}>
            <div className={classes.nameWrapper}>
              <span className={classes.name} style={{ color: theme.BUTTON_PRIMARY }}>
                {user.name} {user.lastname}
              </span>
              <span className={classes.time} style={{ color: theme.TEXT_SECONDARY }}>
                {lastMessageDate}
              </span>
            </div>
          </div>
          <div className={classes.descriptionContainer}>
            <div className={classes.descriptionWrapper}>
              <Content
                content={formattedContent}
                className={classes.description}
                style={{ color: theme.TEXT_PRIMARY }}
              />
            </div>
            <div className={classes.buttonWrapper} style={{ background: theme.BACKGROUND_SECONDARY }}>
              <ArrowIcon />
            </div>
          </div>
        </div>
      </div>
    </ButtonBase>
  );
};

export default withStyles(styles)(withRouter(withConfig(DialogItem)));
