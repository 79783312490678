import { DELETE_OWNER_FAILURE, DELETE_OWNER_REQUEST, DELETE_OWNER_SUCCESS } from 'constants/auth';
import {
  SIGN_UP_MANUAL_REQUEST,
  SIGN_UP_MANUAL_SUCCESS,
  SIGN_UP_MANUAL_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  SIGN_IN_GOOGLE_REQUEST,
  SIGN_IN_GOOGLE_SUCCESS,
  SIGN_IN_GOOGLE_FAILURE,
  GET_ACCOUNT_INFO_GOOGLE_REQUEST,
  GET_ACCOUNT_INFO_GOOGLE_SUCCESS,
  GET_ACCOUNT_INFO_GOOGLE_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
} from '../../constants';

// SIGN UP MANUAL
export const signUpManualRequest = (user, isInvited) => ({
  type: SIGN_UP_MANUAL_REQUEST,
  user,
  isInvited,
});

export const signUpManualSuccess = () => ({
  type: SIGN_UP_MANUAL_SUCCESS,
});

export const signUpManualFailure = (error) => ({
  type: SIGN_UP_MANUAL_FAILURE,
  error,
});

// SIGN IN
export const signInRequest = (email, password, redirectTo?: string) => ({
  type: SIGN_IN_REQUEST,
  email,
  password,
  redirectTo,
});

export const signInSuccess = () => ({
  type: SIGN_IN_SUCCESS,
});

export const signInFailure = (error) => ({
  type: SIGN_IN_FAILURE,
  error,
});

// SIGN UP
export const signUpRequest = (user: object, isInvited: boolean) => ({
  type: SIGN_UP_REQUEST,
  user,
  isInvited,
});

export const signUpSuccess = (currentCorps: string) => ({
  type: SIGN_UP_SUCCESS,
  currentCorps,
});

export const signUpFailure = (error) => ({
  type: SIGN_UP_FAILURE,
  error,
});

// SIGN OUT
export const signOutRequest = () => ({
  type: SIGN_OUT_REQUEST,
});

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: SIGN_OUT_FAILURE,
  error,
});

// RESET PASSWORD
export const resetPasswordRequest = (email) => ({
  type: RESET_PASSWORD_REQUEST,
  email,
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_FAILURE,
  error,
});

// UPDATE PASSWORD
export const updatePasswordRequest = (token: string, newPassword: string, email: string) => ({
  type: UPDATE_PASSWORD_REQUEST,
  token,
  newPassword,
  email,
});

export const updatePasswordSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS,
});

export const updatePasswordFailure = () => ({
  type: UPDATE_PASSWORD_FAILURE,
});

// SIGN IN GOOGLE
export const signInGoogleRequest = (credentials) => ({
  type: SIGN_IN_GOOGLE_REQUEST,
  credentials,
});

export const signInGoogleSuccess = (googleCreds) => ({
  type: SIGN_IN_GOOGLE_SUCCESS,
  googleCreds,
});

export const signInGoogleFailure = (error) => ({
  type: SIGN_IN_GOOGLE_FAILURE,
  error,
});

/// DELETE ACCOUNT

export const deleteOwnerRequest = () => {
  return { type: DELETE_OWNER_REQUEST };
};

export const deleteOwnerSuccess = () => {
  return { type: DELETE_OWNER_SUCCESS };
};

export const deleteOwnerFailure = (error) => {
  return { type: DELETE_OWNER_FAILURE, error };
};
