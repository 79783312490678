import { ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { TSurveyModal } from "components/Shared/SurveyModal/SurveyModal";
import { IonIcon } from "components/UI/IonIcon";
import { getConfig } from "config/config";
import React, { FC, useEffect, useState } from "react";
import { useAppDispatch } from "store";
import { surveyAddVote, surveyGetVotes, surveyRemoveVote } from "store/reducers/news";
import { getLocalStorage } from "utilities/localStorageHandler";
import { SurveyVotesModal } from "./SurveyVotesModal";
import { useLanguage } from "languages/languageContext";
import { surveyCommentsAddVote, surveyCommentsRemoveVote } from "store/reducers/commentsSlice";
const defaultUser = require('../../../assets/default_avatar.png');

type Props = {
  content: string
  survey: TSurveyModal['options']
  multiAnswer: TSurveyModal['multiAnswer']
  postId: string
  post: any
  type: 'post' | 'comment'
}

export const SurveyPost: FC<Props> = ({ content, survey, multiAnswer, postId, type }) => {
  const { surveys: lSurveys } = useLanguage()
  const styles = useStyles({})
  const dispatch = useAppDispatch()
  const [myVote, setMyVote] = useState([])
  const [votes, setVotes] = useState({ overall: 0 })
  const [modal, setModal] = useState(false)
  const userId = getLocalStorage('userId')
  useEffect(() => {
    const newVotes = []
    const voters = {
      overall: 0
    }
    survey.forEach(el => {
      voters[el._id] = el.voters?.length
      voters.overall = voters.overall + el.voters?.length
      const voteId = el.voters.findIndex(vote =>
        typeof vote.user !== 'string' ? vote.user?._id === userId : vote.user === userId
      )

      if (voteId !== -1)
        newVotes.push(el._id)
    })
    setMyVote(newVotes)
    setVotes(voters)
  }, [survey])

  const handleVote = (optionId: string, isSelected: boolean) => {
    if (!multiAnswer && myVote.length) {
      if (type === 'post') dispatch(surveyRemoveVote({ postId, optionId: myVote[0] }))
      if (type === 'comment') dispatch(surveyCommentsRemoveVote({ commentId: postId, optionId: myVote[0] }))
    }

    if (!isSelected) {
      if (type === 'post') dispatch(surveyAddVote({ postId, optionId }))
      if (type === 'comment') dispatch(surveyCommentsAddVote({ commentId: postId, optionId }))
    }
    if (isSelected) {
      if (type === 'post') dispatch(surveyRemoveVote({ postId, optionId }))
      if (type === 'comment') dispatch(surveyCommentsRemoveVote({ commentId: postId, optionId: optionId }))
    }
  }

  const OptionComponent = (el: any, idx: number) => {
    const isSelected = myVote.includes(el._id)
    const votesCount = votes[el._id]
    const overallVotes = votes.overall
    const percents = (100 / overallVotes) * votesCount

    //@ts-ignore
    const votersPhotos = survey.find(v => v._id === el._id).voters.slice(-3).map(({ user }) => user?.photo || defaultUser)

    return <ButtonBase className={styles.option} onClick={() => handleVote(el._id, isSelected)} key={el._id}>

      <div className={`${styles.iconWrapper} ${isSelected ? styles.iconWrapperSelected : ''}`}>
        <IonIcon name="checkmark-outline" size={24} strokeWidth={48} color={theme.BACKGROUND} />
      </div>

      <div className={styles.topPlank}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <p>{el.title}</p>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: 4 }}>
              {votersPhotos.map((el, idx) => <img
                alt='user'
                src={el}
                className={styles.avatar}
                style={{
                  zIndex: idx,
                  left: idx * 10,
                }}
              />
              )}
            </div>
            <p style={{ fontSize: 14, color: theme.TEXT_SECONDARY }}>{votesCount}</p>
          </div>
        </div>

        <div className={styles.progressWrapper}>
          <div className={styles.progressBar} style={{ width: '100%', backgroundColor: '#cccccc60', animation: 'none' }}>
            <div className={styles.progressBar} style={{ width: `${percents}%`, position: 'relative' }} />
          </div>

        </div>
      </div>

    </ButtonBase>
  }

  return <>
    <div className={styles.container}>
      <h3 style={{ marginBottom: 5 }}>{content}</h3>
      <span className={styles.description}>
        {multiAnswer ? lSurveys.chooseOptions.multiple : lSurveys.chooseOptions.single}
      </span>
      <div className={styles.optionsWrapper}>
        {survey.map(OptionComponent)}
      </div>
      <ButtonBase
        className={styles.checkVotes}
        // onClick={() => dispatch(surveyGetVotes({ page: 1, optionId: '650c139765e1d2cf43663b73', postId: '650c139765e1d2cf43663b72', limit: 5 }))}
        onClick={() => setModal(true)}
      >
        <span>{lSurveys.checkVotes}</span>
      </ButtonBase>
    </div>
    {modal && <SurveyVotesModal
      options={survey.map(({ title, _id, voters }) => ({ title, _id, votersCount: voters?.length || 0 })).sort((a, b) => a.votersCount > b.votersCount ? -1 : 0)}
      isOpen={modal}
      handleModal={() => setModal(false)}
      postId={postId}
      surveyTitle={content}
      type={type}
    />}
  </>
}

const { theme } = getConfig()

const useStyles = makeStyles({
  container: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 20px'
  },
  description: {
    fontSize: 14,
    color: theme.TEXT_SECONDARY,
  },
  optionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '5px 0',
  },
  option: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 15,
    fontSize: 16,
    // minWidth: '50%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  topPlank: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  iconWrapper: {
    width: 24,
    height: 24,
    borderRadius: 24,
    border: `2px solid ${theme.ICON_BORDER}`,
    marginRight: 10,
    '&:hover': {
      backgroundColor: theme.ICON_BORDER,
    }
  },
  iconWrapperSelected: {
    borderColor: theme.ACTIVE_INPUT,
    backgroundColor: theme.ACTIVE_INPUT,
  },
  checkVotes: {
    margin: '20px 0',
    fontSize: 16,
    color: theme.TEXT_SECONDARY,
    padding: 10,
    borderRadius: 10,
    backgroundColor: '#cccccc50',
    '&:hover': {
      backgroundColor: '#cccccc30',
    },
  },
  progressBar: {
    width: 0,
    height: 5,
    backgroundColor: theme.ICON_PRIMARY,
    // animation: 'width 1s ease-out',
    transition: 'width 1s ease-in-out', /* Transition for width changes */

    borderRadius: 5
  },
  progressWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    zIndex: 1,
    transition: 'width 1s ease-in-out', /* Transition for width changes */
  },
  '@keyframes slideIn': {
    from: {
      width: '0%',
    },
    to: {
      width: '100%',
    }
  },
  avatar: {
    borderRadius: 25,
    width: 25,
    height: 25,
    position: 'relative',
    border: '1px solid #cccccc',
  }
});