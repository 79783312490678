import { all } from 'redux-saga/effects';
import corpsSagas from './corps';
import authSagas from './auth';
import usersSagas from './users';
// import newsSagas from './news';
import likesSagas from './likes';
import contactsSagas from './contacts';
import requestsSagas from './requests';
import chatSagas from './chat';
import advertisementSagas from './advertisement';
import eventsSagas from './events';

export default function* rootSaga(): Generator<any> {
  yield all([
    ...corpsSagas,
    ...authSagas,
    ...usersSagas,
    ...likesSagas,
    ...contactsSagas,
    ...requestsSagas,
    ...chatSagas,
    ...advertisementSagas,
    ...eventsSagas,
  ]);
}
