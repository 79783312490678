import { authHeader, handleConnectionError, handleResponse } from './core';
import { API } from '../constants';

// GET ALL ADS
export const getAllAds = () => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/opt/allactive`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET ADS
export const putAds = (adsId: string, activity: string) => {
  const options: any = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ activity }),
  };

  return fetch(`${API}/opt/${adsId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};
