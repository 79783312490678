import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import ResponseHandler from '../components/Shared/ResponseHandler/';
import { useSelector } from 'react-redux';
import SignIn from '../scenes/SignIn';
import SignUp from '../scenes/SignUp';
import SignUpPage from '../scenes/SignUpPage';
import ResetPassword from '../scenes/ResetPassword';
import Posts from '../scenes/Posts';
import Kreise from '../scenes/Kreise';
import Profile from '../scenes/Profile';
import Suche from '../scenes/Suche';
import Contacts from '../scenes/Contacts';
import Requests from '../scenes/Requests';
import Dialogs from '../scenes/Dialogs';
import DialogPanel from '../scenes/DialogPanel/';
import ProfileDetails from '../scenes/ProfileDetails';
import Comments from '../scenes/Comments/';
import KreisePosts from '../scenes/KreisePosts';
import RequestDetails from '../scenes/RequestDetails/';
import Report from '../scenes/Report/';
import Event from '../scenes/Event/';
import AppInfo from '../scenes/AppInfo/';
import ChangePassword from '../scenes/ChangePassword/';
// import Documents from '../scenes/Documents';
import { DocumentsScreen } from '../scenes/Documents/DocumentsScreen';
import Mentoring from 'scenes/Mentoring/Mentoring';
import { getConfig } from 'config/config';
import { MapScreen } from 'scenes/MapScreen/MapScreen';
import { KreiseInfoScreen } from 'scenes/KreiseInfo/KreiseInfoScreen';
import { RootState } from 'store';
import { ServerIsDown } from 'scenes/ServerIsDown/ServerIsDown';
import { CreateKreise } from 'scenes/CreateKreise/CreateKreise';
import { CorpsScreen } from 'scenes/CorpsScreen/CorpsScreen';
import { CalendarScreen } from 'scenes/Calendar/CalendarScreen';
import { SignUpScreen } from 'scenes/SignUpPage/SignUpScreen';
import Participants from 'scenes/Participants/Participants';
import { CreateEvent } from 'scenes/CreateEvent/CreateEvent';
import PartParticipants from 'scenes/PartParticipants/PartParticipants';
import CreateDialog from 'scenes/CreateDialog/CreateDialog';

interface Props {}
const { config } = getConfig();
const Routes: React.FC<Props> = () => {
  const { user } = useSelector((state: RootState) => state.users);
  const isGuest = user.isGuest || false;

  return (
    // @ts-ignore
    <Router>
      <ResponseHandler>
        <Switch>
          {/* <Route exact path="/">
                        {token ? <Redirect to="/posts" /> : <Redirect to="/sign-in" />}
                    </Route> */}
          <Route exact path="/sign-up" component={SignUp} />
          <Route exact path="/sign-in" component={SignIn} />
          <Route path="/sign-up-page" component={SignUpPage} />
          <Route path="/sign-up-screen" component={SignUpScreen} />
          <Route path="/reset-password" component={ResetPassword} />

          <Route path="/profile-details/:id" component={ProfileDetails} />
          <Route path="/posts-details/:id" component={Comments} />
          <Route path="/profile" component={Profile} />
          <Route path="/posts" component={Posts} />

          <Route exact path="/calendar" component={CalendarScreen} />
          <Route path="/calendar/:id" component={Event} />
          <Route path="/report" component={Report} />

          <Route exact path="/dialogs" component={Dialogs} />
          <Route path="/dialogs/:id" component={DialogPanel} />
          <Route path="/create-event" component={CreateEvent} />
          <Route path="/edit-event/:id" component={CreateEvent} />
          <Route path="/app-info" component={AppInfo} />
          <Route path="/change-password/:token" component={ChangePassword} />
          <Route path="/reconnect" component={ServerIsDown} />
          {/* this route should be last to handle all wrong routes */}
          {!isGuest && <Route path="/map" component={MapScreen} />}
          {!isGuest && <Route exact path="/requests" component={Requests} />}
          {!isGuest && <Route path="/requests/:id" component={RequestDetails} />}

          {!isGuest && (config.KAS_MENTORING || config.BVS_MENTORING) && (
            <Route path="/mentoring" component={Mentoring} />
          )}
          {!isGuest && <Route path="/contacts" component={Contacts} />}
          {!isGuest && <Route path="/kreise-posts/:kreiseId" component={KreisePosts} />}
          {!isGuest && <Route path="/kreise-info/:kreiseId" component={KreiseInfoScreen} />}
          {!isGuest && <Route exact path="/kreise" component={Kreise} />}
          {!isGuest && <Route exact path="/kreise/create" component={CreateKreise} />}
          {!isGuest && <Route path="/suche" component={Suche} />}
          {!isGuest && <Route path="/documents" component={DocumentsScreen} />}
          {/* {!isGuest && <Route path="/groups/:id" component={CorpsScreen} />} */}
          {!isGuest && <Route path="/participants/:id" component={Participants} />}
          {!isGuest && <Route path="/parts-participants/:id" component={PartParticipants} />}
          {!isGuest && <Route path="/create-dialog" component={CreateDialog} />}
          <Route path="/" component={() => <Redirect to="/posts" />} />
        </Switch>
      </ResponseHandler>
    </Router>
  );
};

export default Routes;
