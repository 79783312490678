import { client } from 'api/client'
import React, { useEffect, useState } from 'react'


export interface PreviewData {
	title?: string
	description?: string
	image?: string
	favicon?: string
	url?: string
}


export const useLinkInfo = (url: string) => {
	const [data, setData] = useState<PreviewData | null>(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if(!url) return
		let newUrl = url
		if (!/^https?:\/\//i.test(url)) {
			newUrl = 'http://' + url;
		}
		if(url && newUrl) {
			client.get(`/link-preview?target=${newUrl}`)
				.then((data) => {
					setData({...data, url} as PreviewData)
				})
				.catch((error) => {
					// console.log(`Error get link from ${url}: ${error}`)
					setData(null)
				})
				.finally(() => setLoading(false))
		}

	}, [url])

	return {data, loading}
}
