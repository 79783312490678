import React, { useEffect, useState } from 'react';
import { withConfig, Theme } from '../../../../config/';
import { formatString } from '../../../../utilities/';
import { withStyles, WithStyles, createStyles, ButtonBase } from '@material-ui/core';
import { checkIfImageExists } from 'utilities/checkIfImageExists';

const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px',
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  iconWrapper: {
    width: 60,
    height: 60,
    borderRadius: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid',
    marginRight: 10,
    boxSizing: 'border-box',
  },
  countWrapper: {
    width: 30,
    height: 30,
    borderRadius: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 20,
    textAlign: 'left',
    display: 'flex',
    flex: 1,
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  title: any;
  count: number;
  iconComponent: JSX.Element;
  onClickItem: () => void;
  photo?: any;
}

const SearchedItem: React.FC<Props> = ({ theme, classes, title, count, iconComponent, onClickItem, photo }) => {
  const str = typeof title === 'object' ? title.join(' ') : title;
  const formattedTitle = formatString(60, str);

  const image = photo?.[0]?.thumb || photo?.[0] || photo?.thumb || photo?.url || photo;
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    if (photo) checkIfImageExists(image, (exists) => setImageExists(exists));
  }, []);

  return title?.toString().length > 0 ? (
    <ButtonBase onClick={onClickItem} style={{ width: '100%' }}>
      <div className={classes.container}>
        <div className={classes.contentWrapper}>
          <div
            className={classes.iconWrapper}
            style={{ background: theme.ICON_BACKGROUND, borderColor: theme.ICON_BORDER }}
          >
            {imageExists ? (
              <img src={image} className="w-[56px] h-[56px] object-cover rounded-full" alt="" />
            ) : (
              iconComponent
            )}
          </div>
          <span className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
            {formattedTitle}
          </span>
        </div>
        <div className={classes.countWrapper} style={{ background: theme.ICON_BACKGROUND }}>
          <span style={{ color: theme.TEXT_PRIMARY }}>{count}</span>
        </div>
      </div>
    </ButtonBase>
  ) : null;
};

export default withStyles(styles)(withConfig(SearchedItem));
