import { PUT_ADS_REQUEST, PUT_ADS_SUCCESS, PUT_ADS_FAILURE } from '../../constants';

// PUT ADS
export const putAdsRequest = (adsId: string, activity: string) => ({
  type: PUT_ADS_REQUEST,
  adsId,
  activity,
});

export const putAdsSuccess = (ads: object) => ({
  type: PUT_ADS_SUCCESS,
  ads,
});

export const putAdsFailure = (error: object) => ({
  type: PUT_ADS_FAILURE,
  error,
});
