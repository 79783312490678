import { useLanguage } from './../languages/languageContext';
import TimeAgo from 'javascript-time-ago';

import en from 'javascript-time-ago/locale/en.json';
import fr from 'javascript-time-ago/locale/fr.json';
import de from 'javascript-time-ago/locale/de.json';
import it from 'javascript-time-ago/locale/it.json';
import hu from 'javascript-time-ago/locale/hu.json';
import ja from 'javascript-time-ago/locale/ja.json';
import zh from 'javascript-time-ago/locale/zh.json';
import es from 'javascript-time-ago/locale/es.json';
import ko from 'javascript-time-ago/locale/ko.json';
import ru from 'javascript-time-ago/locale/ru.json';

const values = {
  GER: 'de-DE',
  ENG: 'en-US',
  FRA: 'fr-FR',
  HNG: 'hu-HU',
  ITA: 'it-IT',
  JAP: 'ja-JA',
  CIN: 'zh-ZH',
  SP: 'es-ES',
  KOR: 'ko-KO',
  RUS: 'ru-RU',
};

export const timeAgo = (time: any, future = false) => {
  // @ts-ignore
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { key } = useLanguage();

  TimeAgo.addLocale(de);
  TimeAgo.addLocale(en);
  TimeAgo.addLocale(fr);
  TimeAgo.addLocale(hu);
  TimeAgo.addLocale(it);
  TimeAgo.addLocale(es);
  TimeAgo.addLocale(zh);
  TimeAgo.addLocale(ru);
  TimeAgo.addLocale(ko);
  TimeAgo.addLocale(ja);
  time = new Date(time);
  if (!future) {
    if (time > new Date()) {
      time = new Date();
    }
  }
  const timeAgo = new TimeAgo(values[key]);

  return timeAgo.format(time);
};
