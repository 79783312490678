import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { StyleRules } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { getConfig } from '../../../config/config';
// import { AddFolder } from "./CreateFolder";
import { useLanguage } from '../../../languages/languageContext';
import { AddFolder } from './CreateFolder';

export const CurrentFolder: FC<{ currentPath: any; goToParentFolder: () => void; showFoldersModal: () => void }> = ({
  currentPath,
  goToParentFolder,
  showFoldersModal,
}) => {
  const { downloads } = useLanguage();

  const disabled = !currentPath.prevFolder;
  const iconColor = disabled ? theme.BACKGROUND_SECONDARY : theme.ACTIVE_INPUT;
  return (
    <Box style={styles.container} flexDirection="row">
      <Box style={styles.iconWrapper}>
        <ButtonBase onClick={goToParentFolder} disabled={disabled}>
          <ArrowBack style={{ color: iconColor, width: 32, height: 32 }} />
        </ButtonBase>
      </Box>
      <span style={styles.text}>{currentPath?.prevFolder ? `../${currentPath?.title}` : downloads?.root}</span>
      <AddFolder currentPath={currentPath} showFoldersModal={showFoldersModal} />
    </Box>
  );
};

const { theme } = getConfig();

const styles = {
  container: {
    display: 'flex',
    padding: '8px 20px',
    backgroundColor: theme.BACKGROUND_PRIMARY,
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 16,
    color: theme.TEXT_PRIMARY,
    alignSelf: 'center',
    flex: 3,
  },
  iconWrapper: {
    width: 80,
    justifyContent: 'flex-start',
  },
};
