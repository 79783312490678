import moment from 'moment-timezone';

export const dateToLocal = (date: any) => {
  const objectDate = new Date(date);
  const defaultDate = new Date();
  // if you create an event in a +3 timezone from +2 timezone
  if (objectDate.getTimezoneOffset() < defaultDate.getTimezoneOffset()) {
    objectDate.setHours(objectDate.getHours() - 1);
  }
  // if you create an event in a +2 timezone from +3 timezone
  else if (objectDate.getTimezoneOffset() > defaultDate.getTimezoneOffset()) {
    objectDate.setHours(objectDate.getHours() + 1);
  }
  return objectDate;
};

export const setTime = (date: any, returnAsObject?: boolean) => {
  const hours = moment(date).format('HH');
  const minutes = moment(date).format('mm');

  return returnAsObject ? { hours, minutes } : `${hours}:${minutes}`;
};
