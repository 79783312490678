import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';
import { withConfig, Theme } from '../../../config/';

const styles = createStyles({
  container: {
    width: '100%',
    padding: '10px 20px',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: 20,
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  title: string;
}

const RequestHeader: React.FC<Props> = ({ theme, classes, title }) => (
  <div className={classes.container}>
    <span className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
      {title}
    </span>
  </div>
);

export default withStyles(styles)(withConfig(RequestHeader));
