import { makeStyles } from '@material-ui/styles';
import SearchBar from 'components/Shared/SearchBar';
import { getConfig } from 'config/config';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  clearResults,
  getGlobalSearch,
  getGlobalSearchByName,
  globalSearchResponseType,
  setCollapsed,
} from 'store/reducers/globalSearch';
import { ChatItem } from './Components/ChatItem';
import { CorpItem } from './Components/CorpItem';
import { DocumentItem } from './Components/DocumentItem';
import { EventItem } from './Components/EventItem';
import { PostItem } from './Components/PostItem';
import { UserItem } from './Components/UserItem';
import { SearchItem } from './Components/SearchItem';
import { ButtonBase } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import Loader from 'components/UI/Loader';
import EmptyList from 'components/Shared/EmptyList';
import { useLanguage } from 'languages/languageContext';
import { FolderItem } from './Components/FolderItem';
import { useHistory } from 'react-router';
import { useQuery } from 'hooks/useQuery';

export const GlobalSearchTab: FC = () => {
  const { search, drawer, globalSearch } = useLanguage();
  const dispatch = useAppDispatch();
  const { results, loading, collapsed, preservedQuery } = useAppSelector((state) => state.globalSearch);
  const history = useHistory();
  const qQuery = useQuery().get('query');
  const query = qQuery || (typeof qQuery === 'string' && !qQuery?.length) ? qQuery : preservedQuery;

  const [error, setError] = useState('');
  const hasResults = Object.values(results).findIndex((el) => el.data?.length !== 0) !== -1;
  const delayRef = useRef(null);
  const controller = useRef(new AbortController());
  const { kreise, corps, documents, events, posts, folders } = results;

  const [firstRender, setFirstRender] = useState(true);

  const styles = useStyles({});

  useEffect(() => {
    history.replace({
      search: `query=${query}`,
    });
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    // Reset the timer whenever the input value changes
    clearTimeout(delayRef.current);
    delayRef.current = setTimeout(() => {
      if (query.trim().length >= 3)
        dispatch(getGlobalSearch({ query, limit: '5', page: '1', abortController: controller.current }));
    }, 1000); // Set the delay time here (in milliseconds))
  }, [query, dispatch]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    history.replace({
      search: `query=${e.target.value}`,
    });
    if (error.length && e.target.value.length >= 3) setError('');

    if (e.target.value.trim().length < 3) {
      setError(globalSearch.error);
      return;
    }

    if (!controller.current) return;

    controller.current.abort();
    controller.current = new AbortController();
  };

  const clearSearchHandler = () => {
    history.replace({
      search: `query=`,
    });
    dispatch(clearResults());
  };

  const DownloadMoreBtn: FC<{ keys: keyof globalSearchResponseType }> = ({ keys }) => {
    const page = results[keys].page + 1;
    const hasMore = results[keys]?.data.length < results[keys]?.total;
    if (!hasMore) return <></>;
    return (
      <ButtonBase
        className={styles.downloadMore}
        onClick={() => {
          dispatch(getGlobalSearchByName({ query, page, limit: 5, keys }));
        }}
      >
        {globalSearch.loadMore}
      </ButtonBase>
    );
  };

  const RenderSearchedValues: FC<{ keys: keyof globalSearchResponseType }> = ({ keys }) => {
    if (!results[keys]?.data.length) return <></>;

    let list, title;
    switch (keys) {
      case 'corps':
        list = corps.data.map((corp) => corp._id?.length && <CorpItem corp={corp} key={corp._id} />);
        title = search.corps;
        break;
      case 'documents':
        list = documents.data.map(
          (document) => document._id?.length && <DocumentItem document={document} key={document._id} query={query} />,
        );
        title = drawer.documents;
        break;
      case 'folders':
        list = folders.data.map((folder) => folder._id?.length && <FolderItem folder={folder} key={folder._id} />);
        title = globalSearch.folder;
        break;
      case 'events':
        list = events.data.map(
          (event) => event._id?.length && <EventItem event={event} key={event._id} query={query} />,
        );
        title = drawer.calendar;
        break;
      case 'posts':
        list = posts.data.map((post) => post._id?.length && <PostItem post={post} key={post._id} query={query} />);
        title = globalSearch.post;
        break;
      case 'kreise':
        list = kreise.data.map((chat) => chat._id?.length && <ChatItem chat={chat} key={chat._id} />);
        title = drawer.kreise;
        break;
      case 'users':
        list = results[keys].data.map((item) => item.name?.length && <UserItem user={item} key={item.name} />);
        title = search.name;
        break;
      default:
        if (keys === 'berufCompany') title = search.company;
        if (keys === 'berufIndustry') title = search.industry;
        if (keys === 'berufTitel') title = search.profession;
        if (keys === 'charge') title = 'Funktion';
        if (keys === 'city') title = search.city;
        if (keys === 'country') title = search.country;
        if (keys === 'hochschule') title = search.university;
        if (keys === 'studiengang') title = search.study;
        if (keys === 'interessen') title = search.hobby;
        if (keys === 'postcode') title = search.postCode;
        list = results[keys].data.map(
          (item) => item.name?.length && <SearchItem item={item} key={item.name} type={keys} />,
        );
        break;
    }

    return (
      <div className={styles.collapsble}>
        <ButtonBase
          className={styles.heading}
          onClick={() => dispatch(setCollapsed({ key: keys, value: !collapsed[keys] }))}
        >
          <div>
            <h2 className={styles.title} style={{ marginBottom: 0 }}>
              {title}
            </h2>
            <h5 className={styles.title} style={{ marginTop: 0 }}>
              {globalSearch.found}: {results[keys].total}
            </h5>
          </div>
          <ChevronLeft style={{ rotate: collapsed[keys] ? '90deg' : '-90deg', alignSelf: 'center' }} />
        </ButtonBase>
        {!collapsed[keys] && (
          <>
            {list}
            <DownloadMoreBtn keys={keys} />
          </>
        )}
      </div>
    );
  };

  // used for prevent rerender while searching in content
  const MemoRender = useCallback(
    ({ type }: { type: 'documents' | 'posts' | 'events' }) => {
      if (type === 'documents') return <RenderSearchedValues keys={'documents'} />;
      if (type === 'posts') return <RenderSearchedValues keys={'posts'} />;
      if (type === 'events') return <RenderSearchedValues keys={'events'} />;
      return <></>;
    },
    [
      results.documents?.data,
      collapsed.documents,
      results.posts?.data,
      collapsed.posts,
      results.events?.data,
      collapsed.events,
    ],
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.inputWrapper} style={{ paddingBottom: !!error?.length ? 10 : undefined }}>
          <SearchBar query={query} onChange={onChangeHandler} clearSearch={clearSearchHandler} error={error} />
        </div>
        <div className={styles.content}>
          <RenderSearchedValues keys={'users'} />
          <RenderSearchedValues keys={'corps'} />
          <RenderSearchedValues keys={'city'} />
          <RenderSearchedValues keys={'postcode'} />
          <RenderSearchedValues keys={'country'} />
          <RenderSearchedValues keys={'berufIndustry'} />
          <RenderSearchedValues keys={'berufTitel'} />
          <RenderSearchedValues keys={'berufCompany'} />
          <RenderSearchedValues keys={'interessen'} />
          <RenderSearchedValues keys={'studiengang'} />
          <RenderSearchedValues keys={'hochschule'} />
          <RenderSearchedValues keys={'charge'} />
          <MemoRender type="posts" />
          <RenderSearchedValues keys={'kreise'} />
          <MemoRender type="events" />
          <MemoRender type="documents" />
          <RenderSearchedValues keys={'folders'} />
          {!hasResults && <EmptyList title={globalSearch.enterQuery} icon="search" />}
          <Loader showLoader={loading} />
        </div>
      </div>
    </>
  );
};

const { theme, config } = getConfig();
const useStyles = makeStyles({
  container: {
    width: '100%',
    position: 'relative',
    zIndex: 0,
    paddingTop: 60,
  },
  title: {
    fontFamily: 'Poppins',
    color: theme.TEXT_PRIMARY,
    margin: 10,
    textAlign: 'left',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    background: theme.BACKGROUND,
    width: '100%',
    padding: '0 20px',
    boxSizing: 'border-box',
    zIndex: 10,
    position: 'sticky',
    paddingTop: 25,
    top: 130,
    justifyContent: 'space-between',
  },
  content: {
    width: '100%',
    overflow: 'hidden',
    height: '100%',
  },
  progressWrapper: {
    width: '100%',
    padding: '50px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listWrapper: {
    boxSizing: 'border-box',
    width: '100%',
  },
  '@media(max-width: 650px)': {
    inputWrapper: {
      padding: '0 10px',
      paddingTop: 25,
    },
  },
  downloadMore: {
    width: 120,
    height: 40,
    borderRadius: 20,
    border: '1px solid',
    borderColor: theme.ACTIVE_INPUT,
    color: theme.ACTIVE_INPUT,
    fontFamily: 'Roboto',
    fontWeight: 'bolder',
    alignSelf: 'center',
    display: 'flex',
    justifySelf: 'center',
    margin: 10,
  },
  collapsble: {
    margin: 15,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.BACKGROUND_SECONDARY,
    borderRadius: 20,
    justifyContent: 'space-between',
    padding: '5px 15px 5px 15px',
    marginBottom: 7,
  },
});
