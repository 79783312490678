// GET USER REQUESTS
export const GET_USER_REQUESTS_REQUEST = 'GET_USER_REQUESTS_REQUEST';
export const GET_USER_REQUESTS_SUCCESS = 'GET_USER_REQUESTS_SUCCESS';
export const GET_USER_REQUESTS_FAILURE = 'GET_USER_REQUESTS_FAILURE';

export const CLEAR_USER_REQUESTS = 'CLEAR_USER_REQUESTS';

// GET REQUEST
export const GET_REQUEST_REQUEST = 'GET_REQUEST_REQUEST';
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS';
export const GET_REQUEST_FAILURE = 'GET_REQUEST_FAILURE';

export const CLEAR_REQUEST = 'CLEAR_REQUEST';

// HANDLE REQUEST
export const HANDLE_REQUEST_REQUEST = 'HANDLE_REQUEST_REQUEST';
export const HANDLE_REQUEST_SUCCESS = 'HANDLE_REQUEST_SUCCESS';
export const HANDLE_REQUEST_FAILURE = 'HANDLE_REQUEST_FAILURE';

// SEND REQUEST
export const SEND_REQUEST_REQUEST = 'SEND_REQUEST_REQUEST';
export const SEND_REQUEST_SUCCESS = 'SEND_REQUEST_SUCCESS';
export const SEND_REQUEST_FAILURE = 'SEND_REQUEST_FAILURE';

// SEND REPORT
export const SEND_REPORT_REQUEST = 'SEND_REPORT_REQUEST';
export const SEND_REPORT_SUCCESS = 'SEND_REPORT_SUCCESS';
export const SEND_REPORT_FAILURE = 'SEND_REPORT_FAILURE';

// DECLINE REQUEST
export const DECLINE_REQUEST_REQUEST = 'DECLINE_REQUEST_REQUEST';
export const DECLINE_REQUEST_SUCCESS = 'DECLINE_REQUEST_SUCCESS';
export const DECLINE_REQUEST_FAILURE = 'DECLINE_REQUEST_FAILURE';

export const SET_CURRENT_REQUEST = 'SET_CURRENT_REQUEST';
