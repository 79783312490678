import React from 'react';

const CloseIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.02509 3.13445L2.7322 2.42735L6.97484 6.66999L6.26773 7.3771L2.02509 3.13445Z" fill="#707486" />
      <path d="M6.26773 2.42735L6.97484 3.13445L2.7322 7.3771L2.02509 6.66999L6.26773 2.42735Z" fill="#707486" />
    </svg>
  );
};

export default CloseIcon;
