import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { withConfig } from '../../config';
import { SearchableInputProps } from '../model';
import SearchableInputItem from './SearchableInputItem';

const styles = createStyles({
  container: {
    width: '100%',
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  labelWrapper: {
    padding: '0 25px 0 25px',
    width: '100%',
    marginBottom: 5,
    boxSizing: 'border-box',
  },
  label: {
    fontSize: 15,
    fontFamily: 'Roboto',
    color: '#353A54',
    fontWeight: 500,
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 9,
  },
  inputWrapper: {
    width: '100%',
    position: 'relative',
    marginBottom: 15,
  },
  input: {
    padding: '12px 25px 12px 25px',
    width: '100%',
    borderRadius: 50,
    borderWidth: 1,
    borderStyle: 'solid',
    outline: 'none',
    fontSize: 15,
    fontFamily: 'Roboto',
    color: '#353A54',
    boxSizing: 'border-box',
    '&::placeholder': {
      fontSize: 15,
      fontFamily: 'Roboto',
      color: '#353A54',
    },
  },
  scrollWrapper: {
    height: 200,
    width: '100%',
    overflow: 'scroll',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 10,
    overflowX: 'hidden',
  },
  scroll: {
    padding: '15px 25px',
    margin: 0,
  },
  scrollItem: {
    listStyleType: 'none',
    fontSize: 15,
    fontFamily: 'Roboto',
    lineHeight: '25px',
    cursor: 'pointer',
  },
});

interface ICorpsData {
  name: string;
  id: string;
}

interface Props extends WithStyles<typeof styles>, SearchableInputProps { }

const SearchableInput: React.FC<Props> = ({
  value = '',
  theme,
  placeholder,
  classes,
  data,
  onChange,
  onItemSelect,
  disableFilter,
  inputName,
  onEndReachedHandler = () => { },
}) => {
  const [active, setActive] = useState(false);
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    focus();
  }, []);

  const focus = () => {
    const elem = input.current!;
    elem.focus();
  };

  const filteredData: ICorpsData[] = useMemo(() => {
    if (disableFilter) return data;
    return data.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
  }, [data, value])


  const scrollHandler = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      onEndReachedHandler();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputWrapper}>
        <input
          ref={input}
          onChange={onChange}
          className={classes.input}
          style={{
            borderColor: active ? theme.ACTIVE_INPUT : theme.BACKGROUND_SECONDARY,
            background: theme.BACKGROUND_PRIMARY,
          }}
          placeholder={placeholder}
          value={value}
          onFocus={() => setActive(!active)}
          onBlur={() => setActive(!active)}
        />
      </div>
      <div
        className={classes.scrollWrapper}
        style={{
          borderColor: theme.BACKGROUND_SECONDARY,
          background: theme.BACKGROUND_PRIMARY,
        }}
        onScroll={scrollHandler}
      >
        <ul className={classes.scroll}>
          {filteredData.map((item: ICorpsData) => (
            <SearchableInputItem item={item} inputName={inputName} onItemSelect={onItemSelect} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default withStyles(styles)(withConfig(SearchableInput));
