import React from 'react';
import { imagePath, timeAgo } from '../../utilities';
import defaultImage from '../../assets/default_avatar.png';
import { useLanguage } from 'languages/languageContext';
import { useCustomHistory } from 'hooks/useCustomHistory';
import { getConfig } from 'config/config';

const { theme } = getConfig();
interface Props {
  sender: any;
  date: string;
  dateLabel: string;
  kreiseName: string;
  kreiseId: string;
  toProfileHandler: (user: any) => void;
  pinToTop?: boolean;
  children?: React.ReactNode;
}

const PostSender: React.FC<Props> = ({
  sender,
  date,
  kreiseName,
  kreiseId,
  toProfileHandler,
  dateLabel,
  pinToTop,
  children,
}) => {
  const { post } = useLanguage();

  let image, newsDate;
  const { customPush } = useCustomHistory();
  if (sender) {
    image = imagePath(null, sender.photo);
    newsDate = dateLabel?.length ? timeAgo(date) : timeAgo(date).charAt(0).toUpperCase() + timeAgo(date).slice(1);
  }

  const toKreisePostsHandler = () => {
    customPush(`/kreise-posts/${kreiseId}`, { kreiseId });
  };

  return (
    <div className="flex flex-row gap-x-2 break-words text-ellipsis px-5">
      <button
        className="flex items-center justify-center border-2 w-12 h-12 rounded-full overflow-hidden hover:opacity-70 duration-150"
        style={{ borderColor: theme.BACKGROUND_SECONDARY }}
        onClick={() => toProfileHandler(sender)}
      >
        <img src={image || defaultImage} alt="avatar" className="w-full" />
      </button>
      <div className="flex flex-1">
        <div>
          <div className="text-txt font-semibold">
            <span
              onClick={() => toProfileHandler(sender)}
              className="font-semibold cursor-pointer hover:opacity-70 duration-150"
            >
              {`${sender.name} ${sender.lastname}`}{' '}
            </span>

            {!!kreiseName && (
              <>
                <span> {post.toKreise} </span>
                <span
                  onClick={toKreisePostsHandler}
                  className="text-primary-btn font-medium cursor-pointer hover:opacity-70  duration-150"
                >
                  {kreiseName}
                </span>
              </>
            )}
          </div>
          <span className="text-sm tracking-tight" style={{ color: theme.TEXT_SECONDARY }}>
            <span className="font-semibold">{pinToTop && post.pinned + ' '}</span>
            {dateLabel} {newsDate}
          </span>
        </div>
      </div>
      <div className="flex items-start justify-center">{children}</div>
    </div>
  );
};

export default PostSender;
