import { ButtonBase, makeStyles } from "@material-ui/core";
import { getConfig } from "config/config";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ProfileItemRedirectTypes } from "scenes/ProfileDetails/model";
import { useAppDispatch } from "store";
// import { clearSearchQuery, searchPostCodeRequest, searchQueryRequest } from "store/actions/search";
import { globalSearchResponseType, TGlobalSearchField, TUserFields } from "store/reducers/globalSearch";
import { ReactComponent as StadtIcon } from '../../../../../assets/stadtIcon.svg';
import { ReactComponent as CorpsIcon } from '../../../../../assets/corpsIcon.svg';
import { ReactComponent as LandIcon } from '../../../../../assets/landIcon.svg';
import { ReactComponent as StudiumIcon } from '../../../../../assets/studiumIcon.svg';
import { ReactComponent as FirmaIcon } from '../../../../../assets/firmaIcon.svg';
import { ReactComponent as BrancheIcon } from '../../../../../assets/brancheIcon.svg';
import { ReactComponent as BerufIcon } from '../../../../../assets/berufIcon.svg';
import { ReactComponent as InteressenIcon } from '../../../../../assets/heart.svg';
import FunctionIcon from '../../../../../assets/FunctionIcon';

type Props = {
  item?: TGlobalSearchField['data'][0]
  type: keyof TUserFields
}

export const SearchItem: FC<Props> = (props) => {
  const { item, type } = props
  const { name, count } = item
  const dispatch = useAppDispatch()
  const styles = useStyles({})

  const redirect = () => {
    let page: keyof typeof ProfileItemRedirectTypes
    switch (type) {
      case 'city':
        page = 'Stadt'
        break
      case 'country':
        page = 'Country'
        break
      case 'postcode':
        page = 'PostCode'
        break
      case 'berufCompany':
        page = 'Firma'
        break
      case 'berufIndustry':
        page = 'Branche'
        break
      case 'berufTitel':
        page = 'Beruf'
        break
      case 'charge':
        page = 'Function'
        break
      case 'hochschule':
        page = 'Hochschule'
        break
      case 'studiengang':
        page = 'Studiengang'
        break
      case 'interessen':
        page = 'Interessen'
        break
    }
    return ProfileItemRedirectTypes[page] + `?searchKey=${name}&backToGlobal=true`
  }

  const handler = () => {
    if (type === 'postcode') {
      // dispatch(searchQueryRequest(name))
      // dispatch(searchPostCodeRequest(false, true, 30, 'postcode', 1, name))
      return
    }
    // dispatch(clearSearchQuery())
  }

  const Icon = () => {
    if (type === 'city') return <StadtIcon />
    if (type === 'postcode') return <CorpsIcon />
    if (type === 'country') return <LandIcon />
    if (type === 'studiengang') return <StudiumIcon />
    if (type === 'berufCompany') return <FirmaIcon />
    if (type === 'berufIndustry') return <BrancheIcon />
    if (type === 'berufTitel') return <BerufIcon />
    if (type === 'interessen') return <InteressenIcon />
    if (type === 'hochschule') return <StudiumIcon />
    if (type === 'charge') return <FunctionIcon fill={theme.ICON_BORDER} width={64} />
    return <></>
  }

  return <Link
    className={styles.container}
    to={redirect}
    onClick={handler}
  >
    <div className={styles.imageWrapper}>
      <Icon />
    </div>
    <div className={styles.infoWrapper}>
      <span className={styles.name}>{name}</span>
      <span className={styles.name}>{count}</span>
    </div>
  </Link>
}

const { theme } = getConfig()
const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: 80,
    backgroundColor: theme.BACKGROUND_SECONDARY,
    padding: 10,
    margin: 7,
    alignItems: 'center',
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textDecoration: 'none'
  },
  imageWrapper: {
    width: 60,
    height: 60,
    borderRadius: 60,
    backgroundColor: theme.ICON_BACKGROUND,
    borderColor: theme.ICON_BORDER,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flex: 1,
    padding: '0 20px 0 20px'
  },
  name: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.TEXT_PRIMARY,
  },
})