import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  EDIT_OWNER_AVATAR_REQUEST,
  EDIT_OWNER_AVATAR_SUCCESS,
  EDIT_OWNER_AVATAR_FAILURE,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_REQUEST,
  CLEAR_USER_REQUEST,
  CLEAR_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAILURE,
  GET_OWNER_PROFILE_REQUEST,
  GET_OWNER_PROFILE_SUCCESS,
  GET_OWNER_PROFILE_FAILURE,
} from '../../constants/';
import { UserType } from '../types/usersTypes';

type StateType = {
  userIsLoading: boolean;
  user: UserType;
  currentUser: UserType | null;
  users: UserType[];
  userPasswordIsLoading: boolean;
};

const initialState: StateType = {
  userIsLoading: false,
  user: {} as UserType,
  currentUser: null,
  users: [],
  userPasswordIsLoading: false,
};

export const users = (state = initialState, action) => {
  switch (action.type) {
    case GET_OWNER_PROFILE_REQUEST:
      return {
        ...state,
        userIsLoading: true,
      };
    case GET_OWNER_PROFILE_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        user: action.user,
      };
    case GET_OWNER_PROFILE_FAILURE:
      return {
        ...state,
        userIsLoading: false,
      };
    case EDIT_OWNER_AVATAR_REQUEST:
      return {
        ...state,
        userIsLoading: true,
      };
    case EDIT_OWNER_AVATAR_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          photo: action.photo,
        },
        userIsLoading: false,
      };
    case EDIT_OWNER_AVATAR_FAILURE:
      return {
        ...state,
        userIsLoading: false,
      };
    case GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        userIsLoading: true,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        currentUser: action.user,
      };
    case GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        userIsLoading: false,
        error: action.error,
      };
    case GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        userIsLoading: true,
      };
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        users: action.users,
      };
    case GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        userIsLoading: false,
        error: action.error,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        userIsLoading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        user: action.user,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        userIsLoading: false,
        error: action.error,
      };
    case UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        userPasswordIsLoading: true,
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        userPasswordIsLoading: false,
      };
    case UPDATE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        userPasswordIsLoading: false,
      };
    case CLEAR_USER_REQUEST:
      return {
        ...state,
        userIsLoading: false,
      };
    case CLEAR_USER_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        user: {},
        currentUser: null,
        users: [],
      };
    default:
      return {
        ...state,
      };
  }
};
