import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { withConfig, withAuth, Theme } from '../../config/';
import { getOwnerProfileRequest } from '../../store/actions/users';
import { getUserRequestsRequest, clearUserRequests } from '../../store/actions/requests';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import Layout from '../../components/UI/Layout';
import ScreenTitle from '../../components/Shared/ScreenTitle';
import RequestHeader from './components/RequestHeader';
import RequestItem from './components/RequestItem';
import EmptyList from '../../components/Shared/EmptyList';
import { withLanguage } from 'languages';
import { translationsType } from 'languages/languageContext';
import { setCurrentRequest } from 'store/actions/requests';

const styles = createStyles({
  container: {
    width: '100%',
    padding: '20px 0',
    borderRadius: 10,
  },
  title: {
    textAlign: 'center',
  },
  wrapper: {
    padding: '0 30px 0 30px',
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  requestsWrapper: {
    marginBottom: 20,
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  incRequests: Array<any>;
  outRequests: Array<any>;
  user: any;
  getOwnerProfileRequest: (userId: string) => void;
  getUserRequestsRequest: () => void;
  language: translationsType;
  setCurrentRequest: (c: any) => void;
}

class Requests extends React.Component<Props> {
  componentDidMount() {
    const { user, getOwnerProfileRequest } = this.props;

    getOwnerProfileRequest(user._id);
    this.getRequestsHandler();
  }

  getRequestsHandler = () => {
    this.props.getUserRequestsRequest();
  };

  toRequestDetails = (type: string, id: string) => {
    this.props.history.push(`/requests/${type}?id=${id}`, { prevRoute: this.props.history.location });
  };

  render(): JSX.Element {
    const { classes, incRequests, outRequests, language } = this.props;
    const { screenTitles, requestsLabels } = language;
    const requestsList = (
      <>
        {incRequests.length > 0 ? (
          <div className={classes.requestsWrapper}>
            <RequestHeader title={requestsLabels.receiveLabel} />
            {incRequests.map((item, i) => (
              <RequestItem
                key={item.id._id + 2}
                user={item.id}
                onClick={() => {
                  this.props.setCurrentRequest(item);
                  this.toRequestDetails('incoming', item._id);
                }}
              />
            ))}
          </div>
        ) : null}
        {outRequests.length > 0 ? (
          <div className={classes.requestsWrapper}>
            <RequestHeader title={requestsLabels.sentLabel} />
            {outRequests.map((item, i) => (
              <RequestItem
                key={item.id._id + 1}
                user={item.id}
                onClick={() => {
                  this.props.setCurrentRequest(item);
                  this.toRequestDetails('outgoing', item._id);
                }}
              />
            ))}
          </div>
        ) : null}
      </>
    );

    return (
      <Layout>
        <ScreenTitle title={screenTitles.contactReqTitle} />
        <div className={classes.container}>
          {incRequests.length === 0 && outRequests.length === 0 ? <EmptyList /> : requestsList}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.users.user,
  incRequests: state.requests.incRequests,
  outRequests: state.requests.outRequests,
  requestsIsLoading: state.requests.requestsIsLoading,
});

const mapDispatchToProps = {
  getUserRequestsRequest,
  getOwnerProfileRequest,
  clearUserRequests,
  setCurrentRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withAuth(withLanguage(withConfig(Requests)))));
