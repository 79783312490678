import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { signInRequest } from '../../store/actions/auth';
import { RouteComponentProps, Redirect, useHistory, useParams, useLocation } from 'react-router-dom';
import Logo from '../../components/UI/Logo';
import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button';
import ResetPassword from './components/ResetPassword';
import BackToLogin from '../../components/Shared/BackToLogin';
import Heading from '../../components/Shared/Heading';
import Loader from '../../components/UI/Loader';
import { SignInProps } from './model';
import { useLanguage } from 'languages/languageContext';
import { getConfig } from 'config/config';
import LanguageSwitch from 'components/UI/LanguageSwitch';
import Conditions from './components/Conditions';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { useFormik } from 'formik';
import { signInSchema } from 'utilities/schema';
import { login } from 'store/reducers/auth';
import { resubscribeAgbSubscription } from 'store/subscriptions/agbSubscription';
import { FormInput } from 'components/UI/FormInput';

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 0 70px 0',
  },
  wrapper: {
    width: 320,
  },
  inputsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  desc: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    padding: '0 10px',
    textAlign: 'center',
    letterSpacing: 0.01,
  },
});

interface Props extends RouteComponentProps<any>, SignInProps {}

export interface ISignInValues {
  email?: string;
  password?: string;
}

const SignIn: FC<Props> = (props) => {
  const classes = useStyles({});
  const language = useLanguage();
  const dispatch = useAppDispatch();
  const { authIsLoading } = useAppSelector((state: RootState) => state.auth);
  const history = useHistory();
  const token = localStorage.getItem('token');
  const param = useLocation();
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  // const [focused, setFocused] = useState<{ [key: string]: boolean }>({
  //   email: false,
  //   password: false,
  // });

  const { handleChange, handleBlur, handleSubmit, errors, touched, values, isValid } = useFormik({
    initialValues: {
      email: param?.state?.email || localStorage.getItem('email') || '',
      password: localStorage.getItem('password') || '',
    },
    validationSchema: signInSchema(language.signIn),
    onSubmit: ({ email, password }: ISignInValues) => {
      const url = history.location.search;
      let route, redirectTo;
      if (url.includes('redirect=')) {
        route = token ? 'documents' : 'sign-in';
        let specificIndex = url.indexOf('redirect=');
        redirectTo = url.substring(specificIndex + 'redirect='.length);
      }

      if (email && password) {
        dispatch(login({ email, password, redirectTo }));
        resubscribeAgbSubscription();
      }
      setDisabledButton(false);
    },
  });

  // const onBlur = (fieldName: string) => {
  //   setFocused({ [fieldName]: !focused[fieldName] });
  //   handleBlur(fieldName);
  // };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  if (token) {
    return <Redirect push to="/posts" />;
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <LanguageSwitch offlineMode />
          <Logo />
          <Heading title={language.signIn.title} />
          <div className={classes.inputsWrapper}>
            <FormInput
              placeholder={language.signIn.loginPlaceholder}
              onChange={handleChange}
              autoCapitalize="none"
              name="email"
              id="email"
              error={touched.email && errors.email}
              value={values.email}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              onSubmit={() => handleSubmit()}
            />
            <FormInput
              placeholder={language.signIn.passwordPlaceholder}
              name="password"
              id="password"
              onChange={handleChange}
              autoCapitalize="none"
              error={touched.password && errors.password}
              value={values.password}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              onSubmit={() => handleSubmit()}
            />
          </div>
          <ResetPassword email={values.email} />
          <Button
            label={language.signIn.loginBtn}
            background={isValid ? theme.BUTTON_PRIMARY : theme.ICON_BORDER}
            onClick={() => {
              if (!disabledButton) {
                if (isValid) setDisabledButton(true);
                handleSubmit();
              }
            }}
          />
          <BackToLogin
            label={language.signIn.noAccount}
            buttonText={language.signIn.register}
            onClick={() => props.history.push('/sign-up')}
            type="button"
          />
          <Conditions language={language} />
        </div>
      </div>
      <Loader showLoader={disabledButton || authIsLoading} />
    </>
  );
};

export default SignIn;
