import {
  GET_USER_CONTACTS_REQUEST,
  GET_USER_CONTACTS_SUCCESS,
  GET_USER_CONTACTS_FAILURE,
  CLEAR_USER_CONTACTS,
  GET_CONTACTS_LIST_REQUEST,
  GET_CONTACTS_LIST_SUCCESS,
  GET_CONTACTS_LIST_FAILURE,
} from 'constants/index';
import { UserType } from 'store/types/usersTypes';

interface State {
  contacts: UserType[];
  contactsIsLoading: boolean;
  contactsCount: number;
  contactsList: any[];
}

const initialState: State = {
  contacts: [],
  contactsCount: null as 0,
  contactsIsLoading: false,
  contactsList: [],
};

export const contacts = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_CONTACTS_REQUEST:
      return {
        ...state,
        contactsIsLoading: true,
      };
    case GET_USER_CONTACTS_SUCCESS:
      return {
        ...state,
        contactsIsLoading: false,
        contactsCount: action.total,
        contacts: action.search ? action.contacts : state.contacts.concat(action.contacts),
      };
    case GET_USER_CONTACTS_FAILURE:
      return {
        ...state,
        error: action.error,
        contactsIsLoading: false,
      };
    case CLEAR_USER_CONTACTS:
      return {
        ...state,
        contacts: [],
        contactsCount: null,
      };
    // GET CONTACTS LIST
    case GET_CONTACTS_LIST_REQUEST:
      return {
        ...state,
        contactsIsLoading: true,
      };
    case GET_CONTACTS_LIST_SUCCESS:
      return {
        ...state,
        contactsIsLoading: false,
        contactsList: action.contacts,
      };
    case GET_CONTACTS_LIST_FAILURE:
      return {
        ...state,
        contactsIsLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
