const getData = () => {
  try {
    return require(`./dataSource/data_${process.env.REACT_APP_NAME}.ts`);
  } catch (error) {
    return require(`./dataSource/data_bayern`);
  }
};

export const data = getData();

export const {
  geschlechtObj,
  corpsFunction,
  corpsStatuses,
  employmentType,
  activityType,
  seniorityType,
  branchType,
  specialityType,
  educationLevel,
} = data.default;

export const socialMedia = [
  { label: 'Facebook', id: 'facebook' },
  { label: 'Instagram', id: 'instagram' },
  { label: 'WhatsApp', id: 'whatsapp' },
  { label: 'YouTube', id: 'youtube' },
  { label: 'Vimeo', id: 'vimeo' },
  { label: 'XING', id: 'xing' },
  { label: 'LinkedIn', id: 'linkedin' },
  { label: 'Snapchat', id: 'snapchat' },
  { label: 'TikTok', id: 'tiktok' },
  { label: 'Reddit', id: 'reddit' },
  { label: 'Twitter / X', id: 'twitter' },
  { label: 'Twitch', id: 'twitch' },
  { label: 'Tumblr', id: 'tumblr' },
  { label: 'Sonstige (Eingeben)', id: 'custom' },
];
