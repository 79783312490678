import Button from 'components/UI/Button';
import { getConfig } from 'config/config';
import { useLanguage } from 'languages/languageContext';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { hideResponse, showResponse } from 'store/actions/response';

const { config } = getConfig();

export const useCustomHistory = () => {
  const history = useHistory();
  const { isGuest } = useAppSelector((state) => state.users.user);
  const {
    widget: { isWidget },
    restrictions: commonRestrictions,
  } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();
  const {
    privacy: { restrictions: lRestrictions },
    profileDetails,
  } = useLanguage();

  const restrictions = commonRestrictions.overall ? commonRestrictions : lRestrictions;
  const customPush = (path: string, options?: any) => {
    if (isWidget) {
      let message = '';
      if (path.includes('profile-details')) message = restrictions.profile;

      if (message.length) {
        dispatch(showResponse({ message }));
        return;
      }
    }
    if (isGuest) {
      let message = '';
      let title = undefined;
      let modalOptions = [];
      if (path.includes('posts-details')) {
        message = config.APP_NAME === 'wilkar' ? restrictions.commentsWilkar : restrictions.comments;
      }
      if (path.includes('kreise')) {
        message = config.APP_NAME === 'wilkar' ? restrictions.groupchatWilkar : restrictions.groupchat;
      }
      if (path.includes('calendar/')) {
        message = config.APP_NAME === 'wilkar' ? restrictions.commentsWilkar : restrictions.comments;
      }

      if (path.includes('profile-details')) {
        path = path.replace('profile-details', 'dialogs');
        message = restrictions.profile;
        title = profileDetails.sendMessageLabel;
        modalOptions = [
          <Button
            label="Nachricht"
            onClick={() => {
              history.push(path, { receiver: options?.receiver });
              dispatch(hideResponse());
            }}
          />,
        ];
      }

      dispatch(showResponse({ message, options: modalOptions, title }));
      return;
    }
    history.push(path, options);
  };

  return {
    ...history,
    customPush,
  };
};

export const withCustomHistory = (WrappedComponent: React.FC | any) => {
  return (props) => {
    const customHistory = useCustomHistory();

    return <WrappedComponent {...props} customHistory={customHistory} />;
  };
};
