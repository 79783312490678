import * as React from 'react'

function Icon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="#000"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M256.001 101.02c-39.388 0-71.432 32.045-71.432 71.432 0 39.388 32.045 71.432 71.432 71.432 39.388 0 71.432-32.045 71.432-71.432s-32.044-71.432-71.432-71.432zM256 213.445c-22.603 0-40.993-18.389-40.993-40.993s18.389-40.993 40.993-40.993 40.993 18.39 40.993 40.993c0 22.604-18.39 40.993-40.993 40.993z"></path>
      <path d="M403.703 302.096h-63.715c28.369-43.321 43.249-87.709 43.249-129.644 0-70.159-57.079-127.238-127.238-127.238s-127.238 57.079-127.238 127.238c0 41.935 14.881 86.323 43.249 129.644h-63.715L0 466.786h512l-108.297-164.69zM159.202 172.453c-.001-53.377 43.423-96.8 96.798-96.8s96.799 43.424 96.799 96.799c0 53.343-28.498 101.699-52.407 132.872-.767.999-1.533 1.977-2.3 2.955-.781.997-1.563 1.981-2.344 2.956-.249.31-.498.623-.747.931-.777.963-1.552 1.914-2.327 2.854-.249.301-.497.604-.745.903a355.4 355.4 0 01-2.315 2.767l-.727.858c-.774.911-1.545 1.81-2.313 2.696l-.686.79a419.43 419.43 0 01-2.311 2.63l-.646.727a378.827 378.827 0 01-5.215 5.733l-.485.521c-.791.85-1.578 1.687-2.353 2.505l-.365.383c-.808.849-1.607 1.684-2.394 2.497l-.222.228c-.827.853-1.645 1.69-2.445 2.502l-.053.054c-5.135 5.2-9.71 9.54-13.394 12.908-26.075-23.86-96.803-96.421-96.803-181.269zm-34.49 160.082h69.609c22.376 27.534 43.37 45.907 50.029 51.489l.006.005.668.557.04.032a109.937 109.937 0 001.41 1.153l.03.024.002.001h.001l.01.008 9.486 7.575 9.486-7.575.01-.008h.001l.002-.001.03-.024c.071-.057.209-.167.4-.323l.05-.041c.238-.194.564-.462.96-.789l.04-.032.668-.557.006-.005c6.66-5.583 27.653-23.955 50.029-51.489h69.609l68.264 103.811H56.448l68.264-103.811z"></path>
    </svg>
  );
}

export default Icon;
