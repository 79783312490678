import { ISignUp } from 'store/reducers/auth';
import { API, API_KEY } from '../constants/';
import { client } from './client';
import { handleResponse, handleGoogleResponse, authHeader, handleConnectionError } from './core';
import { ISignInValues } from 'scenes/SignIn/SignIn';

interface ISignInRes {
  Data: {
    _id: string | number;
    firebaseID: string;
    language: 'string';
  };
  Token: string;
}

// Sign In
export const signIn = (data: { email: string; password: string }) =>
  client.post<ISignInValues, ISignInRes>('/login', data);

// Sign Up with invitation code
export const signUpWithCode = (data: ISignUp) => client.post('/v2/register', data);

// Sign Up without invitation code
export const signUpWithoutCode = (data: ISignUp) => client.post('/register', data);

// check email in sign up page
export const checkEmail = (email: string) =>
  client.get<{ email: string }, { user: boolean }>(`/check-email?email=${email}`);

// Reset Password
export const resetPassword = (email: string, use_ssl = 1) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, use_ssl }),
  };

  return fetch(`${API}/v4/forgotpass`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// UPDATE PASSWORD
export const updatePassword = async (token: string, newPassword: string) => {
  const options: any = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ newPassword }),
  };

  const res = await fetch(`${API}/v4/checkpasstoken/${token}`, options);
  return await res.json();
};

// Sign in google
export const signUpNewUserGoogle = (credentials: any) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(credentials),
  };

  return fetch(`https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=${API_KEY}`, options)
    .then(handleGoogleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// Get account info google
export const getAccountInfoGoogle = (idToken: any) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ idToken }),
  };

  return fetch(`https://www.googleapis.com/identitytoolkit/v3/relyingparty/getAccountInfo?key=${API_KEY}`, options)
    .then(handleGoogleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

export const getCustomFirebaseToken = async () => {
  const options: any = {
    method: 'GET',
    headers: await authHeader(),
  };
  const res = await fetch(`${API}/v2/customtoken`, options);
  const json = await res.json();
  return json;
};

export const getCookies = (value: string) =>
  client.get(`https://cookie.connact.app?value=${value}`, { baseURL: '', withCredentials: true });

/// DELETE ACCOUNT
export const deleteOwnerProfile = async (feedback: string) => {
  const options: any = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify({ feedback }),
  };
  return fetch(`${API}/v2/profile`, options)
    .then((res) => res)
    .catch(handleConnectionError);
};
