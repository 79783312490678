import { API } from '../constants';
import { handleResponse, authHeader, handleConnectionError } from './core';

// GET POST LIKES
export const getPostLikes = (postId: string, page: number, limit: number = 10) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/likes/post/${postId}/${page}/${limit}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET COMMENTS LIKES
export const getCommentsLikes = (postId: string, page: number, limit: number = 10) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/likes/comment/${postId}/${page}/${limit}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// LIKE POST
export const likePost = (liked_user: string, post_id: string) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ liked_user, post_id }),
  };

  return fetch(`${API}/v2/post/like`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// UNLIKE POST
export const unlikePost = (unliked_user: string, post_id: string) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ unliked_user, post_id }),
  };

  return fetch(`${API}/v2/post/unlike`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// LIKE COMMENT
export const likeComment = (liked_user: string, comment_id: string) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ liked_user, comment_id }),
  };

  return fetch(`${API}/v2/post/comment/like`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// UNLIKE POST
export const unlikeComment = (unliked_user: string, comment_id: string) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ unliked_user, comment_id }),
  };

  return fetch(`${API}/v2/post/comment/unlike`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};
// GET EVENT LIKES
export const getEventLikes = (eventId: string, page: number, limit: number) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/events/${eventId}/liked-users/${page}/${limit}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// LIKE EVENT
export const likeEvent = (id: string, me: string) => {
  const options: any = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ id, me }),
  };

  return fetch(`${API}/events/${id}/add-like`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// UNLIKE EVENT
export const unlikeEvent = (id: string, me: string) => {
  const options: any = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ id, me }),
  };

  return fetch(`${API}/events/${id}/remove-like`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// LIKE EVENT COMMENT
export const likeEventComment = (id: string, me: string, comment_id: string) => {
  const options: any = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ id, me, comment_id }),
  };

  return fetch(`${API}/events/${id}/comment/${comment_id}/add-like`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// UNLIKE EVENT COMMENT
export const unlikeEventComment = (id: string, me: string, comment_id: string) => {
  const options: any = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ id, me, comment_id }),
  };

  return fetch(`${API}/events/${id}/comment/${comment_id}/remove-like`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET EVENT COMMENT LIKES
export const getEventCommentLikes = (commentId: string, page: number, limit: number) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/events/comment/${commentId}/liked-users/${page}/${limit}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};
