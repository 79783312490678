import React from 'react';

interface Props {
  onItemSelect: (name: string, input: string) => void;
  item: { id: string; name: string };
  inputName: string;
}

const SearchableInputItem = (props: Props) => {
  const onClickHandler = () => {
    props.onItemSelect(props.item.name, props.inputName);
  };

  return (
    <li
      style={{
        listStyleType: 'none',
        fontSize: 15,
        fontFamily: 'Roboto',
        lineHeight: '25px',
        cursor: 'pointer',
        paddingBottom: '0.5rem',
      }}
      key={props.item.id}
      onClick={onClickHandler}
    >
      {props.item.name}
    </li>
  );
};

export default SearchableInputItem;
