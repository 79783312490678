import React, { useState } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import { ButtonBase, IconButton } from '@material-ui/core';
import { withConfig } from '../../config';
import { InputProps } from '../model';
import AddIcon from '@material-ui/icons/Add';
import { getConfig } from 'config/config';
import { IonIcon } from './IonIcon';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    width: '100%',
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  labelWrapper: {
    padding: '0 25px 0 25px',
    width: '100%',
    marginBottom: 5,
    boxSizing: 'border-box',
  },
  label: {
    fontSize: 14,
    fontFamily: 'Roboto',
    color: theme.TEXT_SECONDARY,
    fontWeight: 'normal',
  },
  buttonWrapper: {
    position: 'absolute',
    right: 10,
    top: 9,
  },
  addButtonWrapper: {
    position: 'absolute',
    right: 23,
    top: 7,
  },
  addButton: {
    width: 30,
    height: 30,
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputWrapper: {
    width: '100%',
    position: 'relative',
  },
  input: {
    padding: '12px 25px 12px 25px',
    width: '100%',
    borderRadius: 50,
    borderWidth: 1,
    borderStyle: 'solid',
    outline: 'none',
    fontSize: 15,
    fontFamily: 'Roboto',
    color: '#353A54',
    boxSizing: 'border-box',
    '&::placeholder': {
      fontSize: 15,
      fontFamily: 'Roboto',
      color: theme.TEXT_SECONDARY,
    },
  },
  errorWrapper: {
    width: '100%',
    display: 'flex',
    margin: '10px 0 10px 0',
    padding: '0 25px 0 25px',
    boxSizing: 'border-box',
  },
  error: {
    fontSize: 15,
    fontFamily: 'Roboto',
  },
  hint: {
    padding: '0 20px',
    marginTop: 5,
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
  },
  hintText: {
    fontSize: 12,
    color: theme.TEXT_SECONDARY,
    marginLeft: 3,
  },
});

interface Props extends WithStyles<typeof styles>, InputProps {
  onSubmit: () => void;
  event: boolean;
  inputStyle: React.CSSProperties;
  containerStyle: React.CSSProperties;
}

const Input: React.FC<Props> = ({
  label,
  value,
  theme,
  placeholder,
  error,
  isFocused,
  security,
  classes,
  onSubmit,
  event,
  inputStyle,
  containerStyle,
  hint,
  ...props
}) => {
  const [showValue, setShowValue] = useState(security);

  return (
    <div className={classes.container} style={containerStyle}>
      {label || (value && value.length > 0 && value?.trim().length > 0) ? (
        <div className={classes.labelWrapper}>
          <span className={classes.label}>{label || placeholder}</span>
        </div>
      ) : null}
      <div className={classes.inputWrapper}>
        <input
          type={showValue ? 'password' : 'name'}
          className={classes.input}
          style={{
            borderColor: isFocused ? theme.ACTIVE_INPUT : theme.BACKGROUND_SECONDARY,
            background: theme.BACKGROUND_PRIMARY,
            ...inputStyle,
          }}
          placeholder={placeholder}
          value={value}
          {...props}
        />
        {hint && (
          <div className={classes.hint}>
            <IonIcon name="information-circle-outline" size={16} color={theme.TEXT_SECONDARY} />
            <span className={classes.hintText}>{hint}</span>
          </div>
        )}
        {security ? (
          <div className={classes.buttonWrapper}>
            <IconButton onClick={() => setShowValue(!showValue)} size="small" style={{ color: theme.BUTTON_SECONDARY }}>
              <EyeIcon />
            </IconButton>
          </div>
        ) : null}
        {event ? (
          <div className={classes.addButtonWrapper}>
            <ButtonBase onClick={() => onSubmit()}>
              <div
                className={classes.addButton}
                style={{
                  background: theme.BUTTON_PRIMARY,
                }}
              >
                <AddIcon style={{ color: '#fff' }} />
              </div>
            </ButtonBase>
          </div>
        ) : null}
      </div>

      {!isFocused && error ? (
        <div className={classes.errorWrapper}>
          <span className={classes.error} style={{ marginRight: 5, color: theme.ERROR_PRIMARY }}>
            •
          </span>
          <span className={classes.error} style={{ color: theme.ERROR_PRIMARY }}>
            {error}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(withConfig(Input));
