import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getServerAlive } from 'api/common';

export const checkServerConnectivity = createAsyncThunk(
  'server/checkServerConnectivity',
  async () => await getServerAlive(),
);

const server = createSlice({
  name: 'server',
  initialState: {
    isAlive: true,
    loading: false,
  },
  reducers: {
    setIsAlive: (state, action: PayloadAction<boolean>) => {
      state.isAlive = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkServerConnectivity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkServerConnectivity.fulfilled, (state) => {
      state.loading = false;
      state.isAlive = true;
    });
    builder.addCase(checkServerConnectivity.rejected, (state) => {
      state.loading = false;
      state.isAlive = false;
    });
  },
});

export const serverSlice = server.reducer;

export const serverActions = server.actions;
