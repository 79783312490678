import { makeStyles } from '@material-ui/core';
import { getConfig } from 'config/config';
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import './style.css'; // This only needs to be imported once in your app
import { fileType } from 'store/reducers/attachments';
import { DownloadButton } from '../DownloadButton';

const { theme } = getConfig();
interface Props {
  onClose: () => void;
  showGallery: boolean;
  images: fileType[] | { url: string }[];
  incomingIndex?: number;
}

const LightboxGallery: React.FC<Props> = ({ showGallery, onClose, images, incomingIndex = 0 }) => {
  const styles = useStyles({});
  const [photoIndex, setPhotoIndex] = useState(incomingIndex);

  useEffect(() => {
    setPhotoIndex(incomingIndex);
  }, [incomingIndex]);

  useEffect(() => {
    if (showGallery) {
      document.body.style.overflow = 'hidden';
    } else if (!showGallery) {
      document.body.style.overflow = 'unset';
    }
  }, [showGallery]);

  const handleNext = () => {
    setPhotoIndex((photoIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  };

  return (
    <div>
      {showGallery && (
        <>
          <Lightbox
            imagePadding={20}
            mainSrc={images[photoIndex].url}
            nextSrc={images.length > 1 ? images[(photoIndex + 1) % images.length].url : undefined}
            prevSrc={images.length > 1 ? images[(photoIndex + images.length - 1) % images.length].url : undefined}
            onCloseRequest={onClose}
            onMovePrevRequest={handlePrev}
            onMoveNextRequest={handleNext}
            toolbarButtons={[<DownloadButton {...images[photoIndex]} style={{ backgroundColor: 'transparent' }} />]}
          />
        </>
      )}
    </div>
  );
};

export default LightboxGallery;

const useStyles = makeStyles({
  downloadBtn: {
    position: 'relative',
    padding: 5,
    borderRadius: 30,
    backgroundColor: theme.ACTIVE_INPUT,
    top: 70,
    left: 130,
    zIndex: 1,
    '&:hover': {
      opacity: 0.9,
    },
  },
  closeButton: {
    position: 'absolute',
    color: theme.BACKGROUND,
    width: 30,
    height: 30,
    top: 13,
    right: 25,
    zIndex: 20,
    cursor: 'pointer',
  },
});
