export const GET_OWNER_PROFILE_REQUEST = 'GET_OWNER_PROFILE_REQUEST';
export const GET_OWNER_PROFILE_SUCCESS = 'GET_OWNER_PROFILE_SUCCESS';
export const GET_OWNER_PROFILE_FAILURE = 'GET_OWNER_PROFILE_FAILURE';

export const EDIT_OWNER_AVATAR_REQUEST = 'EDIT_OWNER_AVATAR_REQUEST';
export const EDIT_OWNER_AVATAR_SUCCESS = 'EDIT_OWNER_AVATAR_SUCCESS';
export const EDIT_OWNER_AVATAR_FAILURE = 'EDIT_OWNER_AVATAR_FAILURE';

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const GET_USER_BY_ID_REQUEST = 'GET_USER_BY_ID_REQUEST';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAILURE = 'GET_USER_BY_ID_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE';

export const UPDATE_USER_NOTIFICATIONS_REQUEST = 'UPDATE_USER_NOTIFICATIONS_REQUEST';
export const UPDATE_USER_NOTIFICATIONS_SUCCESS = 'UPDATE_USER_NOTIFICATIONS_SUCCESS';
export const UPDATE_USER_NOTIFICATIONS_FAILURE = 'UPDATE_USER_NOTIFICATIONS_FAILURE';

export const MUTE_USER_NOTIFICATIONS_REQUEST = 'MUTE_USER_NOTIFICATIONS_REQUEST';
export const MUTE_USER_NOTIFICATIONS_SUCCESS = 'MUTE_USER_NOTIFICATIONS_SUCCESS';
export const MUTE_USER_NOTIFICATIONS_FAILURE = 'MUTE_USER_NOTIFICATIONS_FAILURE';

export const UNMUTE_USER_NOTIFICATIONS_REQUEST = 'UNMUTE_USER_NOTIFICATIONS_REQUEST';
export const UNMUTE_USER_NOTIFICATIONS_SUCCESS = 'UNMUTE_USER_NOTIFICATIONS_SUCCESS';
export const UNMUTE_USER_NOTIFICATIONS_FAILURE = 'UNMUTE_USER_NOTIFICATIONS_FAILURE';

export const CLEAR_USER_REQUEST = 'CLEAR_USER_REQUEST';
export const CLEAR_USER_SUCCESS = 'CLEAR_USER_SUCCESS';
