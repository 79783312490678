import React, { useLayoutEffect, useState } from 'react';

export const LayoutHook = () => {
  const [size, setSize] = useState<Array<number>>([0, 0]);

  const updateSize = () => {
    setSize([window.innerWidth, window.innerHeight]);
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize, { passive: false });
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
