import React from 'react';

const PdfFileIcon = ({ fill }) => {
  return (
    <svg height="40" viewBox="0 0 24 24" width="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m5.5 20c-.276 0-.5-.224-.5-.5v-5c0-.276.224-.5.5-.5h1.5c1.103 0 2 .897 2 2s-.897 2-2 2h-1v1.5c0 .276-.224.5-.5.5zm.5-3h1c.551 0 1-.449 1-1s-.449-1-1-1h-1z"
        data-original="#000000"
        data-old_color="#000000"
        fill={fill}
      />
      <path
        d="m19.5 24h-15c-1.378 0-2.5-1.122-2.5-2.5v-19c0-1.378 1.122-2.5 2.5-2.5h15c1.378 0 2.5 1.122 2.5 2.5v19c0 1.378-1.122 2.5-2.5 2.5zm-15-23c-.827 0-1.5.673-1.5 1.5v19c0 .827.673 1.5 1.5 1.5h15c.827 0 1.5-.673 1.5-1.5v-19c0-.827-.673-1.5-1.5-1.5z"
        data-original="#000000"
        data-old_color="#000000"
        fill={fill}
      />
      <path
        d="m12.5 20h-2c-.276 0-.5-.224-.5-.5v-5c0-.276.224-.5.5-.5h2c.827 0 1.5.673 1.5 1.5v3c0 .827-.673 1.5-1.5 1.5zm-1.5-1h1.5c.276 0 .5-.224.5-.5v-3c0-.276-.224-.5-.5-.5h-1.5z"
        data-original="#000000"
        data-old_color="#000000"
        fill={fill}
      />
      <path
        d="m15.5 20c-.276 0-.5-.224-.5-.5v-4c0-.827.673-1.5 1.5-1.5h2c.276 0 .5.224.5.5s-.224.5-.5.5h-2c-.276 0-.5.224-.5.5v4c0 .276-.224.5-.5.5z"
        data-original="#000000"
        data-old_color="#000000"
        fill={fill}
      />
      <path
        d="m18.5 18h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.276 0 .5.224.5.5s-.224.5-.5.5z"
        data-original="#000000"
        data-old_color="#000000"
        fill={fill}
      />
    </svg>
  );
};

export default PdfFileIcon;
