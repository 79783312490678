import { makeStyles, Modal } from '@material-ui/core';
import { getConfig } from 'config/config';
import React, { FC, useEffect, useState } from 'react';
import { globalSearchResponseType } from 'store/reducers/globalSearch';
import PdfFileIcon from 'assets/icons/PdfFileIcon';
import LightboxGallery from 'components/Shared/ImageLightBox/ImageLightBox';
import Video from 'react-player';
import defaultVideoIcon from '../../../../../assets/defaultVideoIcon.png';
import defaultImageIcon from '../../../../../assets/defaultImageIcon.png';
import { findOccurrences } from 'utilities/findOccurences';

type Props = {
  document?: globalSearchResponseType['documents']['data'][0];
  query: string;
};

export const DocumentItem: FC<Props> = (props) => {
  const classes = useStyles({});
  const { document, query } = props;
  const [showModal, setShowModal] = useState(false);
  const [fileExtention, setFileExtention] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const [occurences, setOccurences] = useState([] as string[]);
  const fileUri = document.path.includes('http')
    ? { uri: document.path }
    : { uri: `${config.SERVER_PATH}/${document.path}` };

  useEffect(() => {
    if (query.length >= 3 && !!document?.content?.length) {
      const result = findOccurrences(query, document?.content);
      setOccurences(result);
    }
    if (document.mimetype) {
      if (document.mimetype.includes('pdf')) setFileExtention('pdf');
      if (document.mimetype.includes('image')) setFileExtention('image');
      if (document.mimetype.includes('video')) setFileExtention('video');

      return;
    }
    const extention = document.filename.split('.');
    const lastElement = extention.length;
    setFileExtention(extention[lastElement - 1]);
  }, []);

  const setFileIcon = (fileExtention: string) => {
    const imageProps: JSX.IntrinsicElements['img'] = {
      onError: ({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = fileExtention === 'video' ? defaultVideoIcon : defaultImageIcon;
      },
      style: { width: 60, height: 60, borderRadius: 60, display: 'flex' },
    };
    if (fileExtention === 'image') {
      return <img src={fileUri.uri} {...imageProps} alt="Image" />;
    }
    if (fileExtention === 'pdf') {
      if (document.thumb) return <img src={document.thumb} {...imageProps} alt="PDF" />;
      return <PdfFileIcon fill={theme.ICON_BORDER} />;
    }
    if (fileExtention === 'video') {
      const thumbnailUrl = document.thumb || defaultVideoIcon;
      return <img src={thumbnailUrl} {...imageProps} alt="Video" />;
    }

    return <div>{fileExtention}</div>;
  };

  const onCloseCurrent = () => {
    setShowModal(false);
    setCurrentFile(null);
  };

  const RenderFileView = () => {
    if (fileExtention === 'pdf') return <></>;
    if (fileExtention === 'image')
      return (
        <LightboxGallery
          images={[{ fileName: document.filename, url: document.path, size: null, mimetype: document.mimetype }]}
          showGallery={showModal}
          onClose={onCloseCurrent}
        />
      );
    if (fileExtention === 'video')
      return (
        <Modal
          style={{
            zIndex: 10,
            position: 'absolute',
            width: '50%',
            height: '50%',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto',
          }}
          open={showModal}
          onClose={onCloseCurrent}
        >
          <Video width={'100%'} height={'100%'} url={document.path} controls playing />
        </Modal>
      );
  };

  return (
    <React.Fragment>
      <div className={classes.container} style={{ borderColor: theme.BACKGROUND_SECONDARY, marginBottom: 10 }}>
        <div
          onClick={() => {
            if (fileExtention === 'pdf') return window.open(document.path);
            setCurrentFile(document);
            setShowModal(true);
          }}
          className={classes.fileWrapper}
          style={{ height: '100%' }}
        >
          <div className={classes.imageWrapper}>{setFileIcon(fileExtention)}</div>
          <div className={classes.infoWrapper}>
            <div className={classes.title}>
              <span className={classes.name}>{document.filename}</span>
              {occurences?.map((el) => (
                <span className={classes.occurences}>...{el}...</span>
              ))}
            </div>
          </div>
        </div>
      </div>
      {currentFile && <RenderFileView />}
    </React.Fragment>
  );
};

const { theme, config } = getConfig();
const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: 80,
    backgroundColor: theme.BACKGROUND_SECONDARY,
    padding: 10,
    margin: 7,
    alignItems: 'center',
    borderRadius: 20,
    flexDirection: 'row',
    width: `calc(100% - 20px)`,
    justifyContent: 'flex-start',
  },
  imageWrapper: {
    width: 60,
    height: 60,
    borderRadius: 60,
    background: theme.ICON_BACKGROUND,
    borderColor: theme.ICON_BORDER,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0 20px',
    alignItems: 'flex-start',
    flex: 1,
  },
  name: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.TEXT_PRIMARY,
    marginLeft: 20,
  },
  fileWrapper: {
    width: '100%',
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
  },
  occurences: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    marginLeft: 20,
  },
  fileName: {
    fontSize: 14,
    fontFamily: 'Roboto',
  },
});
