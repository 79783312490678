import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';
import { withConfig } from '../../config/';
import pdfPreview from '../../assets/pdf-file.png';
import { IonIcon } from 'components/UI/IonIcon';
import { downloadFile } from 'utilities/downloadFile';
import { getConfig } from 'config/config';
import { cn } from 'utilities/utils';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    position: 'relative',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginBottom: 20,
  },
  fileWrapper: {
    display: 'flex',
    margin: '0 20px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    borderColor: theme.BACKGROUND_SECONDARY,
    justifyContent: 'flex-start',
  },
  image: {
    width: 40,
    height: 40,
  },
  name: {
    fontSize: 14,
    fontFamily: 'Roboto',
  },
  desc: {
    display: 'block',
    fontSize: 12,
    fontFamily: 'Poppins',
  },
});

interface Props extends WithStyles<typeof styles> {
  files: Array<any>;
}

const PostDocuments: React.FC<Props> = ({ classes, files }) => {
  return (
    <React.Fragment>
      <div className={classes.container}>
        {files.map((file, i) => {
          const isPdf = file.mimetype === 'application/pdf';
          const url = file.url || file.uri || file.path;

          const onClickHandler = () => (isPdf ? window.open(url) : downloadFile(url, file.fileName));

          return (
            <div
              key={file.name + i}
              className={cn(
                classes.fileWrapper,
                'border rounded-md duration-150 hover:scale-[100.5%] hover:bg-slate-50',
                file.thumb ? 'flex-col items-start' : 'flex-row items-center p-3',
              )}
              onClick={onClickHandler}
            >
              {file.thumb ? (
                <img
                  alt="PDF"
                  src={file.thumb}
                  className="w-full h-36 object-cover object-top bg-red-50 rounded-md border-b"
                />
              ) : isPdf ? (
                <img alt="PDF" src={pdfPreview} className={cn(classes.image)} />
              ) : (
                <IonIcon name="document-outline" size={42} />
              )}
              <div style={{ marginLeft: 10 }}>
                <span className={classes.name} style={{ color: theme.TEXT_PRIMARY }}>
                  {file.fileName}
                </span>
                <span className={classes.desc} style={{ color: theme.TEXT_SECONDARY }}>
                  {isPdf ? 'Klicken zum Öffnen' : 'Klicken Sie zum Herunterladen'}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(withConfig(PostDocuments));
