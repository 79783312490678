type TLocalStorageItems = 'userId' | 'filesCookieDate' | 'token' | 'userLang' | string;

export const getLocalStorage = (query: TLocalStorageItems) => {
  try {
    return localStorage.getItem(query);
  } catch (error) {
    console.error('Cant get userId from localStorage', error);
    return '';
  }
};
