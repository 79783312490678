import React from 'react';
import Linkify from 'react-linkify';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';

import { withConfig, Theme } from 'config';
import linkDecorator from 'components/UI/linkDecorator';
import Content from 'components/UI/Content';

const styles = createStyles({
  container: {
    width: '100%',
    padding: '20px 40px',
    boxSizing: 'border-box',
  },
  contentWrapper: {
    width: '100%',
  },
  text: {
    fontFamily: 'Roboto',
    whiteSpace: 'pre-wrap',
  },
  '@media(max-width: 650px)': {
    container: {
      padding: '10px 20px 20px',
    },
    text: {
      maxWidth: '250px',
    },
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  post: any;
}

const CommentsContent: React.FC<Props> = ({ classes, theme, post }) => {
  return (
    <div className={classes.container}>
      <Linkify componentDecorator={linkDecorator}>
        <div className={classes.contentWrapper}>
          <Content content={post.content} className={classes.text} style={{ color: theme.TEXT_SECONDARY }} />
        </div>
      </Linkify>
    </div>
  );
};

export default withStyles(styles)(withConfig(CommentsContent));
