import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseConfig from './firebaseConfig';

export const firebaseInit = () => {
  //@ts-ignore
  firebase.initializeApp(firebaseConfig[process.env.REACT_APP_NAME]);
};

export const firebaseSignIn = async (email: string, password: string) => {
  await firebase.auth().signInWithEmailAndPassword(email, password);
  let idTokenResult;
  let currentUser = firebase.auth().currentUser;
  if (currentUser) {
    idTokenResult = await currentUser.getIdTokenResult(true);
    return idTokenResult;
  }
};

export const signInWithCustomToken = (token: string) => firebase.auth().signInWithCustomToken(token);
