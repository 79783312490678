import AlertModal from 'components/Shared/AlertModal';
import { useLanguage } from 'languages/languageContext';
import { FC } from 'react';
import { RECURRING_MODIFY_TYPE } from '../CreateEvent';
import { RadioGroup, RadioGroupItem } from 'components/shadcn/radioGroup';
import { Label } from 'components/shadcn/label';
import { Button } from 'components/shadcn/button';

type Props = {
  show: boolean;
  onClose: () => void;
  onValueChange: (v: string) => void;
  rRulesChanged: boolean;
  submitForm: () => void;
};

export const UpdateRRModal: FC<Props> = (props) => {
  const { rrEventLabels, btn } = useLanguage();
  const { show, onClose, onValueChange, rRulesChanged, submitForm } = props;

  return (
    <AlertModal
      showModal={show}
      modalHandler={onClose}
      onClose={onClose}
      message={rrEventLabels.editLabel}
      showCloseBtn={true}
    >
      <div>
        <RadioGroup
          defaultValue={RECURRING_MODIFY_TYPE.SINGLE}
          onValueChange={(v) => onValueChange(v)}
          className="mb-6"
        >
          {!rRulesChanged && (
            <div className="flex items-center space-x-2">
              <RadioGroupItem
                value={RECURRING_MODIFY_TYPE.SINGLE}
                id="update-rec-1"
                className="text-primary-btn border-primary-btn hover:scale-105"
              />
              <Label htmlFor="update-rec-1">{rrEventLabels.thisDate}</Label>
            </div>
          )}
          <div className="flex items-center space-x-2">
            <RadioGroupItem
              value={RECURRING_MODIFY_TYPE.FOLLOWING}
              id="update-rec-2"
              className="text-primary-btn border-primary-btn hover:scale-105"
            />
            <Label htmlFor="update-rec-2">{rrEventLabels.thisDateAndFollowing}</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem
              value={RECURRING_MODIFY_TYPE.ALL}
              id="update-rec-3"
              className="text-primary-btn border-primary-btn hover:scale-105"
            />
            <Label htmlFor="update-rec-3">{rrEventLabels.allDates}</Label>
          </div>
        </RadioGroup>
        <Button onClick={submitForm}>{btn.saveBtn}</Button>
      </div>
    </AlertModal>
  );
};
