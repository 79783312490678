import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CommentType } from 'store/types/commentsTypes';
import * as api from '../../api/comments';
import { RootState } from 'store';

type TVote = {
  commentId: string;
  optionId: string;
};

export const getComments = createAsyncThunk(
  'comments/getComments',
  async ({ postId, page, limit }: { postId: string; page: number; limit: number }) =>
    api.getComments(postId, page, limit),
);

export const addComment = createAsyncThunk('comments/addComment', async ({ comment }: { comment: any }) =>
  api.addComment(comment),
);

export const deleteComment = createAsyncThunk('comments/deleteComment', async ({ commentId }: { commentId: string }) =>
  api.deleteComment(commentId),
);

export const getEventComments = createAsyncThunk(
  'comments/getEventComments',
  async ({ eventId, page, limit }: { eventId: string; page: number; limit: number }) =>
    api.getEventComments(eventId, page, limit),
);

export const addEventComment = createAsyncThunk(
  'comments/addEventComment',
  async ({ comment, eventId }: { comment: any; eventId: string }) => api.addEventComment(eventId, comment),
);

export const deleteEventComment = createAsyncThunk(
  'comments/deleteEventComment',
  async ({ eventId, commentId }: { eventId: string; commentId: string }) => api.deleteEventComment(eventId, commentId),
);

export const surveyCommentsAddVote = createAsyncThunk(
  'comments/surveyCommentsAddVote',
  async ({ commentId, optionId }: TVote) => api.surveyCommentsAddVote(commentId, optionId),
);

export const surveyCommentsRemoveVote = createAsyncThunk(
  'comments/surveyCommentsRemoveVote',
  async ({ commentId, optionId }: TVote) => api.surveyCommentsRemoveVote(commentId, optionId),
);

export const surveyCommentsGetVotes = createAsyncThunk(
  'news/surveyCommentsGetVotes',
  async ({ commentId, optionId, page, limit }: TVote & { page: number; limit: number }) =>
    api.surveyCommentsGetVotes(commentId, optionId, page, limit),
);

interface State {
  comments: CommentType[];
  commentsIsLoading: boolean;
  commentsCount: number;
  eventComments: CommentType[];
  eventCommentsIsLoading: boolean;
  eventCommentsCount: number;
}

const initialState: State = {
  comments: [],
  commentsIsLoading: false,
  commentsCount: 0,
  eventComments: [],
  eventCommentsIsLoading: false,
  eventCommentsCount: 0,
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    clearComments: (state) => {
      state.comments = [];
      state.commentsCount = 0;
    },
    clearEventComments: (state) => {
      state.eventComments = [];
      state.eventCommentsCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getComments.pending, (state) => {
      state.commentsIsLoading = true;
    });
    builder.addCase(getComments.fulfilled, (state, action: any) => {
      state.commentsIsLoading = false;
      state.commentsCount = action.payload.total;
      state.comments = state.comments.concat(action.payload.data);
    });
    builder.addCase(getComments.rejected, (state) => {
      state.commentsIsLoading = false;
    });

    builder.addCase(addComment.pending, (state) => {
      state.commentsIsLoading = true;
    });
    builder.addCase(addComment.fulfilled, (state, action: any) => {
      state.commentsIsLoading = false;
      state.commentsCount = state.commentsCount + 1;
      state.comments = [action.payload, ...state.comments];
    });
    builder.addCase(addComment.rejected, (state) => {
      state.commentsIsLoading = false;
    });

    builder.addCase(deleteComment.pending, (state) => {
      state.commentsIsLoading = true;
    });
    builder.addCase(deleteComment.fulfilled, (state, action: any) => {
      const commentsArr: Array<any> = state.comments.filter(
        (comment: any) => comment._id !== action.meta.arg.commentId,
      );
      state.commentsIsLoading = false;
      state.commentsCount = state.commentsCount - 1;
      state.comments = commentsArr;
    });
    builder.addCase(deleteComment.rejected, (state) => {
      state.commentsIsLoading = false;
    });

    builder.addCase(getEventComments.pending, (state) => {
      state.eventCommentsIsLoading = true;
    });
    builder.addCase(getEventComments.fulfilled, (state, action: any) => {
      state.eventCommentsIsLoading = false;
      state.eventCommentsCount = action.payload.total;
      state.eventComments = state.eventComments.concat(action.payload.data);
    });
    builder.addCase(getEventComments.rejected, (state) => {
      state.eventCommentsIsLoading = false;
    });

    builder.addCase(addEventComment.pending, (state) => {
      state.eventCommentsIsLoading = true;
    });
    builder.addCase(addEventComment.fulfilled, (state, action: any) => {
      state.eventCommentsIsLoading = false;
      state.eventCommentsCount = state.eventCommentsCount + 1;
      state.eventComments = [action.payload, ...state.eventComments];
    });
    builder.addCase(addEventComment.rejected, (state) => {
      state.eventCommentsIsLoading = false;
    });

    builder.addCase(deleteEventComment.pending, (state) => {
      state.eventCommentsIsLoading = true;
    });
    builder.addCase(deleteEventComment.fulfilled, (state, action) => {
      const commentsArr: Array<any> = state.eventComments.filter(
        (comment: any) => comment._id !== action.meta.arg.commentId,
      );
      state.eventCommentsIsLoading = false;
      state.eventCommentsCount = state.eventCommentsCount - 1;
      state.eventComments = commentsArr;
    });
    builder.addCase(deleteEventComment.rejected, (state) => {
      state.eventCommentsIsLoading = false;
    });

    builder.addCase(surveyCommentsAddVote.pending, (state) => {
      state.commentsIsLoading = true;
    });
    builder.addCase(surveyCommentsAddVote.fulfilled, (state, action: any) => {
      state.commentsIsLoading = false;

      const idx = state.comments.findIndex((post) => post?._id === action.payload._id);
      // @ts-ignore
      if (idx !== -1) state.comments[idx].survey = action.payload.survey;

      // if (state.currentNews?._id === action.payload._id) {
      //   state.currentNews.survey = action.payload.survey;
      // }

      // const kreiseIdx = state.kreiseNews.findIndex((post) => post?._id === action.payload._id);
      // if (kreiseIdx !== -1) state.kreiseNews[kreiseIdx].survey = action.payload.survey;
    });
    builder.addCase(surveyCommentsAddVote.rejected, (state) => {
      state.commentsIsLoading = false;
    });

    builder.addCase(surveyCommentsRemoveVote.pending, (state) => {
      state.commentsIsLoading = true;
    });
    builder.addCase(surveyCommentsRemoveVote.fulfilled, (state, action: any) => {
      state.commentsIsLoading = false;
      const idx = state.comments.findIndex((post) => post?._id === action.payload._id);
      // @ts-ignore
      if (idx !== -1) state.comments[idx].survey = action.payload.survey;

      // if (state.currentNews?._id === action.payload._id) {
      //   state.currentNews.survey = action.payload.survey;
      // }

      // const kreiseIdx = state.kreiseNews.findIndex((post) => post?._id === action.payload._id);
      // if (kreiseIdx !== -1) state.kreiseNews[kreiseIdx].survey = action.payload.survey;
    });
    builder.addCase(surveyCommentsRemoveVote.rejected, (state) => {
      state.commentsIsLoading = false;
    });
  },
});

export const selectCommentState = (state: RootState) => state.comments;

export const commentsActions = commentsSlice.actions;

export const comments = commentsSlice.reducer;

export const { clearComments, clearEventComments } = commentsSlice.actions;
