import React, { Fragment } from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { useAppDispatch } from 'store';
import { withConfig } from 'config';
// import { clearSearchQuery } from 'store/actions';

import { ProfileInteressenProps } from '../model';
import { useLanguage } from 'languages/languageContext';

const styles = createStyles({
  contentItem: {
    padding: '10px 0',
  },
  contentTitle: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 15,
  },
  contentSubtitle: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: '.5px',
  },
  link: {
    // textDecoration: 'none',
  },
});

interface Props extends WithStyles<typeof styles>, ProfileInteressenProps, RouteComponentProps<any> {
  language: any;
}

const ProfileInteressen: React.FC<Props> = ({ history, navigationMetadata, classes, theme, interessenList }) => {
  const dispatch = useAppDispatch();
  const { profileDetails } = useLanguage();
  return (
    <div className={classes.contentItem}>
      <span className={classes.contentTitle} style={{ color: theme.TEXT_SECONDARY }}>
        {profileDetails.hobbies}:{' '}
      </span>
      {interessenList &&
        interessenList.map((item, idx) => {
          const lastIndex = interessenList.length - 1;
          const title = item.titel ? item.titel : item.title;
          return (
            <Fragment key={idx}>
              <Link
                key={item._id}
                className={classes.link}
                to={navigationMetadata.type + `?searchKey=${title}`}
              >
                <span className={classes.contentSubtitle} style={{ color: theme.TEXT_PRIMARY }}>
                  {title}
                </span>
              </Link>
            </Fragment>
          );
        })}
    </div>
  );
};

export default withStyles(styles)(withConfig(withRouter(ProfileInteressen)));
