import React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';

import { ProfileInfoProps } from '../model';
import { withConfig } from '../../../config';
import { getConfig } from '../../../config/config';

const styles = createStyles({
  headingWrapper: {
    width: '100%',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontFamily: 'Poppins',
    fontWeight: 600,
    padding: 0,
    margin: 0,
  },
  status: {
    margin: '0 0 0 5px',
    fontSize: 24,
    fontFamily: 'Roboto',
    fontWeight: 600,
    padding: 0,
  },
  subtitle: {
    display: 'block',
    fontSize: 15,
    fontFamily: 'Poppins',
    fontWeight: 600,
  },
});

interface Props extends WithStyles<typeof styles>, ProfileInfoProps {}
const { config } = getConfig();
const ProfileInfo: React.FC<Props> = ({ classes, theme, name, status, corps, branch, contacts, biername }) => (
  <div className={classes.headingWrapper}>
    <div className={classes.heading}>
      <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
        {name}
      </h3>
      {status && (
        <h3 className={classes.status} style={{ color: theme.TEXT_PRIMARY }}>
          {' '}
          ({status})
        </h3>
      )}
    </div>
    <span className={classes.subtitle} style={{ color: theme.TEXT_PRIMARY }}>{`${corps}`}</span>
    <span className={classes.subtitle} style={{ color: theme.TEXT_PRIMARY }}>
      {branch}
    </span>
    {!config.WITHOUT_CONTACTS && (
      <span className={classes.subtitle} style={{ color: theme.TEXT_PRIMARY }}>
        Anzahl Kontakte: {contacts}
      </span>
    )}
  </div>
);

export default withStyles(styles)(withConfig(ProfileInfo));
