import React from 'react';
// import logoImg from '../../assets/logo.png';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Config, withConfig } from '../../config';

const styles = createStyles({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 30,
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
});

interface Props extends WithStyles<typeof styles> {
  config: Config;
}

const logo: React.FC<Props> = ({ classes, config }) => {
  const logoImg = require(`../../assets/logo/logo_${config.APP_NAME}.png`);
  return (
    <div className={classes.container}>
      <img src={logoImg} className={classes.image} alt="App Logo" />
    </div>
  );
};

export default withStyles(styles)(withConfig(logo));
