import { Box } from '@material-ui/core'
import React from 'react'
import { ReactComponent as EmptyIcon } from '../../../assets/emptyListIcon.svg'
import { getConfig } from '../../../config/config'
import { useLanguage } from '../../../languages/languageContext'
import { useAppSelector } from '../../../store'

const { theme } = getConfig()
const styles = {
  container: {
    flex: 1,
    margin: '2px 0',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: theme.BACKGROUND,
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
}



export const EmptyProfile: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const user = useAppSelector(state => state.users.user)
  const ProfileFilled = user.mentoringPolicyAccepted &&
    !!user.mentoringRegions.length

  const language = useLanguage()

  return (
    ProfileFilled ? children :
      <>
        <Box style={{ ...styles.container, flexDirection: 'column' }}>
          <EmptyIcon />
          <div
            style={{
              color: theme.TEXT_SECONDARY,
              // fontFamily: theme.FONT_PRIMARY_REGULAR,
              marginTop: 30,
            }}
          >
            {language.mentoring.tabNames.tabDeactivated}
          </div>
        </Box>
      </>

  )
}


