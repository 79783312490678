export function findOccurrences(input, content) {
  // Create a regular expression to search for the searchStr along with 2 words before and 2 words after it
  const regex = new RegExp(`(\\S+\\s+){0,2}${input}(\\s+\\S+){0,2}`, 'gi');

  // Find all matches of the regex in the input string
  const matches = content.match(regex);

  // Return the array of matches
  return matches;
}
