import React from 'react';
import { WithStyles, createStyles, makeStyles } from '@material-ui/core';
import { ReactComponent as EmptyIcon } from '../../assets/emptyListIcon.svg';
import { useLanguage } from 'languages/languageContext';
import { getConfig } from 'config/config';
import { IonIcon } from 'components/UI/IonIcon';

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '20px 40px',
    boxSizing: 'border-box',
  },
  text: {
    marginTop: 20,
    fontSize: 20,
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
});

interface Props {
  title?: string;
  icon?: 'smile' | 'search';
}

const EmptyList: React.FC<Props> = ({ title, icon }) => {
  const { emptyList } = useLanguage();
  const classes = useStyles({});
  return (
    <div className={classes.container}>
      {icon === 'smile' && <EmptyIcon />}
      {icon === 'search' && <IonIcon name="search" size={90} color={theme.ICON_BORDER} />}
      <span className={classes.text} style={{ color: theme.TEXT_PRIMARY }}>
        {title || emptyList.title}
      </span>
    </div>
  );
};

export default EmptyList;
