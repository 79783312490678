export function removeUnchangedFields(initialValues, values) {
  // Function to check if two values are equal
  function isEqual(value1, value2) {
    if (typeof value1 !== typeof value2) return false;
    if (typeof value1 === 'object' && value1 !== null && value2 !== null) {
      return JSON.stringify(value1) === JSON.stringify(value2);
    }
    return value1 === value2;
  }

  for (let key in values) {
    if (values.hasOwnProperty(key)) {
      if (typeof values[key] === 'object' && !Array.isArray(values[key])) {
        // Deep compare for nested objects
        removeUnchangedFields(initialValues[key], values[key]);
        // Check if the nested object is empty
        if (Object.keys(values[key]).length === 0) {
          delete values[key];
        }
      } else if (isEqual(values?.[key], initialValues?.[key])) {
        delete values[key];
      }
    }
  }
  return values;
}

export function filterFields(initialValues, user) {
  let filteredUser = {};

  for (let key in initialValues) {
    if (initialValues.hasOwnProperty(key)) {
      if (typeof initialValues[key] === 'object' && !Array.isArray(initialValues[key])) {
        // Deep copy for nested objects
        filteredUser[key] = filterFields(initialValues[key], user[key] || {});
      } else {
        filteredUser[key] = user[key] || initialValues[key];
      }
    }
  }

  return filteredUser;
}
