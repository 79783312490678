import React, { useState } from 'react';
import { withStyles, WithStyles, createStyles, ButtonBase } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import Input from '../UI/Input';
import BackButton from './BackButton';
import { useLanguage } from 'languages/languageContext';
import { getConfig } from 'config/config';
import { useHistory } from 'react-router';
import { useCustomHistory } from 'hooks/useCustomHistory';

const { theme } = getConfig();

const styles = createStyles({
  inputWrapper: {
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
  },
  input: {
    width: '100%',
    border: '1px solid',
    borderRadius: 50,
    padding: '15px 20px',
    boxSizing: 'border-box',
    '&::placeholder': {
      fontSize: 15,
      fontFamily: 'Roboto',
      color: '#353A54',
    },
  },
  iconWrapper: {
    position: 'absolute',
    right: 20,
    top: 43,
    zIndex: 1,
  },
  inputContent: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  error: {
    color: theme.ERROR_PRIMARY,
    fontSize: 14,
    fontFamily: 'Roboto',
    position: 'absolute',
    left: 25,
  },
});

interface Props extends WithStyles<typeof styles> {
  query: string;
  clearSearch: () => void;
  toInitialScreen?: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  enableBackButton?: boolean;
  error?: string;
  placeholder?: string;
}

const SearchBar: React.FC<Props> = ({
  classes,
  query,
  clearSearch,
  onChange,
  enableBackButton,
  toInitialScreen,
  error,
  placeholder,
}) => {
  const history = useCustomHistory();
  const { searchInput } = useLanguage();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const focusHandler = () => {
    setIsFocused(!isFocused);
  };

  const handleBack = () => {
    if (history.length > 1) {
      return history.goBack();
    }
    history.replace('/suche/corpsid');
  };

  return (
    <div className={classes.inputWrapper}>
      <div className={classes.iconWrapper} style={{ top: !!query.length ? 40 : 14 }}>
        {query.length > 0 ? (
          <ButtonBase onClick={clearSearch}>
            <CloseIcon />
          </ButtonBase>
        ) : (
          <SearchIcon />
        )}
      </div>
      <div className={classes.inputContent} style={enableBackButton ? { marginLeft: 30 } : {}}>
        {enableBackButton && <BackButton isSearch onClick={handleBack} isDown={!!query.length} />}
        <Input
          placeholder={placeholder || searchInput.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
          isFocused={isFocused}
          onBlur={focusHandler}
          onFocus={focusHandler}
          value={query}
        />
        {!!error?.length && (
          <span className={classes.error} style={{ top: !!query?.length ? 75 : 50 }}>
            {error}
          </span>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(SearchBar);
