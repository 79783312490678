import React, { useState, useEffect, memo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress } from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';

import { useAppSelector, useAppDispatch } from 'store';
import { showResponse } from 'store/actions/response';
import { withConfig, withAuth, Config } from 'config';
import Layout from 'components/UI/Layout';
import PostsToolbar from 'components/Shared/PostsToolbar';
import ScreenTitle from 'components/Shared/ScreenTitle';

import Post from '../Post';
import AdBlock from './components/AdBlock';
import { PostProps } from './model';
import { useLanguage } from 'languages/languageContext';
import { getAllAds, getNewsList, getPinnedPosts, setNewsPageNumber } from 'store/reducers/news';
import { ScrollToTopButton } from 'components/UI/ScrollToTop/ScrollToTopButton';

const styles = createStyles({
  container: {
    width: '100%',
    overflow: 'hidden',
    marginBottom: 40,
    '& > div::-webkit-scrollbar': {
      display: 'none',
    },
  },
  progressWrapper: {
    width: '100%',
    padding: '100px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, PostProps {
  config: Config;
}

const Posts: React.FC<Props> = (props) => {
  const { screenTitles } = useLanguage();
  const dispatch = useAppDispatch();
  const { newsCount, news, newsIsLoading, adsList, newsPageNumber, pinnedNewsFetched } = useAppSelector(
    (state) => state.news,
  );
  const isGuest = useAppSelector((state) => state.users.user?.isGuest);
  const [adNumber, setAdNumber] = useState(0);
  // used to mark if guest already seen error modal, to stop paginagtion
  const [guestModalFired, setGuestModalFired] = useState(false);
  const { classes, theme } = props;

  const newsLength = news.filter((el) => !el?.clickCount).length;

  const getPostsHandler = async () => {
    if (!pinnedNewsFetched) {
      await dispatch(getPinnedPosts({ id: 'news', type: 'news' }));
      dispatch(getNewsList({ page: newsPageNumber, limit: 5, adNumber, addAds: true }));
      dispatch(setNewsPageNumber(newsPageNumber + 1));
      return;
    }

    const resetCounter = adNumber < adsList?.length;

    if (!newsIsLoading) {
      if (isGuest && (newsLength >= 15 || newsCount === newsLength)) {
        if (!guestModalFired) {
          setGuestModalFired(true);
          return dispatch(showResponse({ message: 'Ältere Beiträge sind nur für Mitglieder einsehbar.' }));
        }
        return;
      }
      dispatch(getNewsList({ page: newsPageNumber, limit: 5, adNumber, addAds: true }));
      dispatch(setNewsPageNumber(newsPageNumber + 1));
      setAdNumber(!resetCounter ? 0 : adNumber + 1);
    }
  };

  useEffect(() => {
    if (!adsList?.length) {
      dispatch(getAllAds());
    }

    return () => {};
  }, []);

  const hasMore = isGuest ? !guestModalFired && isGuest : newsLength <= newsCount;

  return (
    <React.Fragment>
      <Layout backgroundColor={news.length > 0 ? theme.BACKGROUND_SECONDARY : theme.BACKGROUND}>
        <ScreenTitle title={screenTitles.postsTitle} />
        <div className={classes.container}>
          {newsLength >= newsCount && newsIsLoading ? (
            <div className={classes.progressWrapper}>
              <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={35} />
            </div>
          ) : (
            <InfiniteScroll
              threshold={50}
              initialLoad={true}
              pageStart={1}
              loadMore={getPostsHandler}
              hasMore={hasMore}
              loader={
                <div style={{ textAlign: 'center' }} key={52}>
                  <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
                </div>
              }
              useWindow={true}
            >
              {news?.map((post: any, i: number) => {
                if (post && post.clickCount) {
                  return <AdBlock item={post} key={post._id + i} />;
                } else if (post && post.sender) {
                  return <Post isOwnerPosts={true} post={post} key={post._id + i} />;
                }
              })}
            </InfiniteScroll>
          )}
          <div>
            <PostsToolbar toolbarType="post" />
          </div>
        </div>
      </Layout>
      <ScrollToTopButton />
    </React.Fragment>
  );
};

export default withStyles(styles)(withAuth(withConfig(memo(Posts))));
