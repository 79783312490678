import { authHeader, handleConnectionError, handleResponse } from './core';
import { API } from '../constants/';

// GET USER REQUESTS
export const getUserRequests = () => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/contactrequests`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET REQUEST
export const getRequest = (type: string, requestId: string) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/contact/${type}/${requestId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// HANDLE REQUEST
export const handleRequest = (requestType: string, requestId: string) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/contact/${requestType}/${requestId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// SEND REQUEST
export const sendRequest = (requestId: string, message: string) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ message }),
  };

  return fetch(`${API}/v2/contact/request/${requestId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// SEND REPORT
export const sendReport = (report: object, reportId: string) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(report),
  };

  return fetch(`${API}/v2/report/${reportId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// DECLINE OUT REQUEST
export const declineRequest = async (requestId: string) => {
  const options: any = {
    method: 'POST',
    headers: await authHeader(),
  };

  return fetch(`${API}/v2/contact/cancelRequest/${requestId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};
