import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

interface Props { }

const OfflineModal: React.FC<Props> = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    window.addEventListener('online', () => {
      // 🦄🎊🔥 we're back online!
      setIsOnline(true);
      document.body.style.overflow = 'unset';

    });

    window.addEventListener('offline', () => {
      // 👨‍💻🙅‍😱 oh no!
      setIsOnline(false);
      document.body.style.overflow = 'hidden';
    });
  }, []);

  if (isOnline) return null;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: 100,
        backgroundColor: 'rgba(255, 255, 255, .7)',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        fontFamily: 'Poppins',
        fontSize: 20,
      }}
    >
      <div
        style={{
          paddingBottom: '30px',
        }}
      >
        Bitte Internetverbindung prüfen.
      </div>
      <CircularProgress style={{ color: '#608B9D' }} size={50} />
    </div>
  );
};

export default OfflineModal;
