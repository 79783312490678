import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithStyles, withStyles, createStyles, ButtonBase, Switch } from '@material-ui/core';

import { withConfig } from 'config';
import { ScreenTitleProps } from 'components/model';
import { ReactComponent as BackIcon } from 'assets/back_arrow.svg';
import { useLanguage } from 'languages/languageContext';
import { InfoSettingsIcon } from 'assets/icons/InfoSettingsIcon';
import { ReactComponent as KreiseIcon } from '../../Drawer/assets/kreise.svg';
import { getConfig } from 'config/config';
import { checkIfImageExists } from 'utilities/checkIfImageExists';
import { fileType } from 'store/reducers/attachments';

const { theme } = getConfig();

const styles = createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    top: 0,
    position: 'sticky',
    width: '100%',
    zIndex: 2,
    boxSizing: 'border-box',
    borderTop: '6px solid',
  },
  menuButtonWrapper: {
    cursor: 'pointer',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    padding: '13px',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: 16,
    textAlign: 'left',
    lineHeight: '15px',
  },
  label: {
    fontSize: 14,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    padding: '0 10px',
    cursor: 'pointer',
  },
  img: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    objectFit: 'cover',
  },
  iconWrapper: {
    width: 34,
    height: 34,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '50%',
    borderWidth: 1,
    backgroundColor: theme.ICON_BACKGROUND,
    border: 'solid',
    borderColor: theme.ICON_BORDER,
    color: theme.ICON_BORDER,
    scale: 1.5,
    marginRight: 10,
    cursor: 'pointer',
  },
  '@media (max-width: 1024px)': {
    container: {
      right: 0,
    },
    title: {
      textAlign: 'center',
      margin: '5px 0',
    },
  },
  '@media (max-width: 650px)': {
    container: {
      left: 0,
      right: 0,
      position: 'fixed',
      width: '100%',
    },
    title: {
      textAlign: 'center',
      margin: '5px 0',
      fontSize: 15,
    },
  },
  '@media (max-width: 350px)': {
    container: {
      left: 0,
      right: 0,
      position: 'fixed',
      width: '100%',
    },
    title: {
      textAlign: 'center',
      margin: '5px 0',
      fontSize: 13,
    },
    label: {
      fontSize: 12,
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, ScreenTitleProps {
  onClick?: () => void;
  onChange: () => VideoFacingModeEnum;
  onShowInfo: () => void;
  checked: boolean;
  title: string;
  isLoaded: boolean;
  canUnsubscribe?: boolean;
  photo?: fileType[] | fileType | string;
}

const KreiseHeader: React.FC<Props> = ({
  classes,
  theme,
  title,
  checked,
  onChange,
  onClick,
  isLoaded,
  onShowInfo,
  canUnsubscribe,
  photo,
}) => {
  const { circles } = useLanguage();

  // @ts-ignore
  const image = photo?.[0]?.thumb || photo?.thumb || photo?.url || photo;
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    checkIfImageExists(image, (exists) => setImageExists(exists));
  }, [image]);

  return (
    <div className={classes.container} style={{ background: theme.BACKGROUND_SECONDARY, borderColor: theme.NAV_BAR }}>
      <div className={classes.headerContainer}>
        <ButtonBase className={classes.menuButtonWrapper} onClick={onClick}>
          <BackIcon />
        </ButtonBase>
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 20 }}>
          <div className={classes.iconWrapper} onClick={onShowInfo}>
            {imageExists ? <img src={image} className={classes.img} /> : <KreiseIcon />}
          </div>
          <div>
            <div className={classes.header} onClick={onShowInfo}>
              <span className={classes.title} style={{ color: theme.TEXT_SECONDARY }}>
                {title}
              </span>
            </div>
            {/* allows to subscribe when user NOT subscribed and allow_subscribe = true, and dissalow unsubscribe in same conditions  */}
            {isLoaded && ((canUnsubscribe && !!checked) || !checked) && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Switch checked={!!checked} onChange={onChange} color="primary" style={{ color: '#fff' }} />
                <span className={classes.label} style={{ color: theme.TEXT_SECONDARY }}>
                  {circles.circlesSubscribe}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoaded && (
        <ButtonBase
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 10 }}
          onClick={onShowInfo}
        >
          <InfoSettingsIcon />
        </ButtonBase>
      )}
    </div>
  );
};

export default withStyles(styles)(withRouter(withConfig(KreiseHeader)));
